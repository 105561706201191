import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";

import {
  Grid
} from "@mui/material";

import { validate } from "rut.js";
import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { buildCompanyFormData, getWithdrawalAddressesArray } from "helpers/requestHelpers";

import CompanyForm from 'components/companies/CompanyForm';

const NewCompany = () => {
  const [company, setCompany] = useState({
    business_name: '',
    rut: '',
    region: '',
    commune: '',
    street: '',
    street_number: '',
    street_other: '',
    ecommerce: '',
    withdrawal_hour: '',
    withdrawal_hour_2: '',
    can_manage_mailing: false,
  });
  const [addresses, setAddresses] = useState(getWithdrawalAddressesArray());

  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const changeAttribute = (attribute, newValue) => {
    setCompany({...company, [attribute]: newValue});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitPressed(true);
    setLoading(true);
    if(validate(company.rut)){
      if (
        company.business_name !== '' &&
        company.rut !== '' &&
        company.region !== '' &&
        company.commune !== '' &&
        company.street !== '' &&
        company.street_number !== '' &&
        company.ecommerce !== '' &&
        addresses[0].region !== ''
      ) {

        const companyData = buildCompanyFormData(company, addresses);
        companyData.set("company[business_name]", company.business_name);
        companyData.set("company[rut]", company.rut);

        return axios
          .post("/api/v1/companies", companyData, { headers: headers })
          .then((response) => {
            setLoading(false);
            setRedirect(true);
            flash_alert(
              "Éxito",
              "La compañía a sido creada con éxito",
              "success"
            )
          })
          .catch(({ response }) => {
            setLoading(false);
            console.log(response.data);
            response.data.map(error => (
              flash_alert(
                "Hubo un error al crear el usuario",
                error,
                "danger"
              )
            ))
          });
      }
      else {
        setLoading(false);
        flash_alert(
          "Error",
          "Completa los campos obligatorios",
          "danger"
        );
      }
    }
    else {
      setLoading(false);
      flash_alert(
        "Error",
        "El RUT es inválido",
        "danger"
      );
    }
  }

  const changeAddressAttribute = (attribute, newValue, addressIndex) => {
    const editedAddress = {...addresses[addressIndex], [attribute]: newValue}
    switch(addressIndex) {
      case 0:
        setAddresses([editedAddress, addresses[1], addresses[2]]);
        break;
      case 1:
        setAddresses([addresses[0], editedAddress, addresses[2]]);
        break;
      case 2:
        setAddresses([addresses[0], addresses[1], editedAddress]);
        break;
    }
  }

  return (
    <Grid container xs={12} className="signup-container">
      {
        redirect && 
          <Redirect 
            key="redirect-to-active-users" 
            to="/users/active"
          />
      }
      
      <Grid item xs={12}>
        <h1>Crea una nueva compañía</h1>
      </Grid>
        
      <CompanyForm
        company={ company }
        addresses={ addresses }
        handleSubmit={ handleSubmit }
        changeAttribute={ changeAttribute }
        changeAddressAttribute={ changeAddressAttribute }
        submitPressed={ submitPressed }
        loading={ loading }
        isEditing={ false }
      />
    </Grid>
  );
}

export default NewCompany;