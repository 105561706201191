import React from "react";

import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material"

import InputMask from "react-input-mask";
import { format } from "rut.js";
import * as EmailValidator from "email-validator";
import { RegionesYComunas, ecommerceOptions } from "../../constants/data";

function UserForm(props) {
  const region = RegionesYComunas.filter(
    (region) => region.name == props.region
  );
  return (
    <React.Fragment>
      <form
        className=""
        onSubmit={props.handleSubmit}
        autoComplete="off"
      >
        <Grid container className="custom-form-container">
          <Grid item xs={12} sm={9} className="flex-left flex-wrap">
            <h3 className="fw-medium">Regístrate</h3>
            <p>
              Es rápido y fácil, solo debes llenar los datos a continuación.
            </p>
          </Grid>
        </Grid>

        <Grid container className="custom-form-container">
          <Grid item xs={12} className="">
            <h4 className="fw-medium">Datos de tu empresa</h4>
            <p className="red-text small-text">*Campos obligatorios</p>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.socialReason == ""}
              variant="outlined"
              label="Razón social*"
              name="socialReason"
              value={props.socialReason}
              onChange={(e) => props.setSocialReason(e.target.value)}
              helperText={
                props.submitPressed && props.socialReason == ""
                  ? "Campo obligatorio"
                  : ""
              }
              className="first-row-left"
            />
          </Grid>
          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.rut == ""}
              variant="outlined"
              label="RUT*"
              name="rut"
              value={props.rut}
              onChange={(e) => props.setRut(format(e.target.value))}
              inputProps={{ maxLength: 12 }}
              helperText={
                props.submitPressed && props.rut == ""
                  ? "Campo obligatorio"
                  : ""
              }
              className="first-row-right"
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Dirección de facturación</h6>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10 flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              error={props.submitPressed && props.region == ""}
            >
              <InputLabel htmlFor="select-region">
                Selecciona tu región*
              </InputLabel>
              <Select
                value={props.region}
                onChange={(e) => {
                  props.setCommune("");
                  props.setRegion(e.target.value);
                }}
                input={<OutlinedInput id="select-region" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {RegionesYComunas.map((region, index) => (
                  <MenuItem key={index} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              {props.submitPressed && props.region == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10 flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              disabled={props.region == ""}
              error={props.submitPressed && props.commune == ""}
            >
              <InputLabel htmlFor="select-commune">
                Selecciona tu comuna*
              </InputLabel>
              <Select
                value={props.commune}
                onChange={(e) => props.setCommune(e.target.value)}
                input={<OutlinedInput id="select-commune" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {region.length == 0 ? (
                  <MenuItem value={"Santiago"}>{"Santiago"}</MenuItem>
                ) : (
                  region[0].communes.map((commune, index) => (
                    <MenuItem key={index} value={commune}>
                      {commune}
                    </MenuItem>
                  ))
                )}
              </Select>
              {props.submitPressed && props.commune == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.street == ""}
              variant="outlined"
              label="Calle*"
              name="calle"
              value={props.street}
              onChange={(e) => props.setStreet(e.target.value)}
              helperText={
                props.submitPressed && props.street == ""
                  ? "Campo obligatorio"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-l-10 pd-r-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.streetNumber == ""}
              variant="outlined"
              type="number"
              label="Número*"
              name="streetNumber"
              value={props.streetNumber}
              onChange={(e) => props.setStreetNumber(e.target.value)}
              helperText={
                props.submitPressed && props.streetNumber == ""
                  ? "Campo obligatorio"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-l-10">
            <TextField
              fullWidth
              variant="outlined"
              label="Depto/Casa"
              name="streetOther"
              value={props.streetOther}
              onChange={(e) => props.setStreetOther(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Selecciona tu plataforma e-commerce</h6>
          </Grid>

          <Grid item xs={12} sm={6} className="flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              error={props.submitPressed && props.ecommerce == ""}
            >
              <InputLabel htmlFor="select-ecommerce">
                Selecciona tu plataforma*
              </InputLabel>
              <Select
                value={props.ecommerce}
                onChange={(e) => props.setEcommerce(e.target.value)}
                input={<OutlinedInput id="select-ecommerce" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {

                  Object.entries(ecommerceOptions).map(([ecommerce, label]) => (
                    <MenuItem key={ecommerce} value={ecommerce}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                props.submitPressed && 
                props.ecommerce == "" && 
                <FormHelperText>Campo obligatorio</FormHelperText>
              }
            </FormControl>
          </Grid>
        </Grid>
          
        <Grid container className="custom-form-container">
          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Datos del primer usuario</h6>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.fullname == ""}
              variant="outlined"
              label="Nombre completo*"
              name="fullname"
              value={props.fullname}
              id="input-fullname"
              onChange={(e) => props.setFullname(e.target.value)}
              helperText={
                props.submitPressed && props.fullname == ""
                  ? "Campo obligatorio"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              error={props.submitPressed && props.charge == ""}
              variant="outlined"
              label="Cargo*"
              name="charge"
              value={props.charge}
              onChange={(e) => props.setCharge(e.target.value)}
              helperText={
                props.submitPressed && props.charge == ""
                  ? "Campo obligatorio"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={
                (props.submitPressed && props.email == "") ||
                (props.email != "" && !EmailValidator.validate(props.email))
              }
              variant="outlined"
              label="Email*"
              name="email"
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              helperText={
                props.submitPressed && props.email == ""
                  ? "Campo obligatorio"
                  : props.email != "" && !EmailValidator.validate(props.email)
                  ? "Formato incorrecto"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <InputMask
              mask="(+56) 9 9999 9999"
              value={props.phoneNumber}
              onChange={(e) => props.setPhoneNumber(e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  error={props.submitPressed && props.phoneNumber == ""}
                  variant="outlined"
                  label="Teléfono*"
                  name="phoneNumber"
                  helperText={
                    props.submitPressed && props.phoneNumber == ""
                      ? "Campo obligatorio"
                      : ""
                  }
                />
              )}
            </InputMask>
          </Grid>

          <Divider />

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Crea tu contraseña</h6>
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <p>Debe contener por lo menos 8 caracteres</p>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={props.submitPressed && props.password == ""}
            >
              <InputLabel htmlFor="outlined-password">
                Ingresa tu contraseña*
              </InputLabel>
              <OutlinedInput
                id="outlined-password"
                type={"password"}
                value={props.password}
                onChange={(e) => props.setPassword(e.target.value)}
                name="password"
                className="custom-password"
              />
              {props.submitPressed && props.password == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={props.submitPressed && props.passwordConfirmation == ""}
            >
              <InputLabel htmlFor="password-confirmation">
                Repite tu contraseña*
              </InputLabel>
              <OutlinedInput
                id="password-confirmation"
                type={"password"}
                value={props.passwordConfirmation}
                onChange={(e) => props.setPasswordConfirmation(e.target.value)}
                name="passwordConfirmation"
                
              />
              {props.submitPressed && props.passwordConfirmation == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} className="flex-left">
            <Button
              disabled={props.loading}
              id="user-save"
              type="submit"
              variant="contained"
              onClick={() => props.handleSubmit}
            >
              {props.loading ? <CircularProgress className="loading-btn" /> : "Crear cuenta"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default UserForm;
