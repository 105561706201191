import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import PendingRequestRow from "./PendingRequestRow";
import MyRequestRow from "./MyRequestRow";
import ControlPanelRow from "./ControlPanelRow";
import IncidenceRow from "./IncidenceRow";
import CaseRow from "./CaseRow";
import DevolutionRow from "./DevolutionRow";
import WithdrawalRow from "./WithdrawalRow";
import BlogRow from "./BlogRow";
import MailingRow from "./MailingRow";
import ForRoutingRow from "./ForRoutingRow";
import NormalRow from "./NormalRow";
import DeliveryZoneRow from "./DeliveryZoneRow";
import ComunaDeliveryZoneRow from "./ComunaDeliveryZoneRow";

function CustomTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage !== undefined ? props.rowsPerPage : 20
  );

  useEffect(() => {
    if (
      (props.search !== undefined && props.search !== "") ||
      props.filterByDate
    ) {
      setPage(0);
    }
  }, [props.search, props.filterByDate]);

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const isDesc = orderBy === property && order === "desc";
    if (isDesc) {
      setOrder("asc");
      setOrderBy("");
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      props.setSelected(props.data);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, request) => {
    const selectedIndex = props.selected
                               .map((req) => req.id)
                               .indexOf(request.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, request);
    } 
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } 
    else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } 
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (request) => {
    if (props.selected === "none") {
      return null;
    }
    return props.selected.map((req) => req.id).indexOf(request.id) !== -1;
  };

  function TablePaginationActions() {
    let count = props.data.length;
    let buttonsCount = Math.ceil(count / rowsPerPage);

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <Pagination
          count={buttonsCount}
          defaultPage={1}
          onChange={(_event, value) => setPage(value - 1)}
          page={page + 1}
        />
      </Box>
    );
  }

  return props.loading ? (
    <CircularProgress className="loading-btn" />
  ) : props.data.length === 0 ? (
    <p className="empty-data-text">
      {props.emptyDataText === undefined ? "No hay datos" : props.emptyDataText}
    </p>
  ) : (
    <>
      {" "}
      <TableContainer>
        <Table 
          aria-label="collapsible table"
          className={props.collapseType === "for-routing-table" ? 'fixed-table' : ''}
        >
          <TableHead>
            <TableRow>
              {props.selected !== "none" && (
                <TableCell padding="checkbox" className="error-padding">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      props.selected.length > 0 &&
                      props.selected.length < props.data.length
                    }
                    checked={props.selected.length === props.data.length}
                    onChange={(event) => handleSelectAllClick(event)}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    className={
                      props.selected.length > 0 &&
                      props.selected.length < props.data.length
                        ? "indeterminate"
                        : ""
                    }
                  />
                </TableCell>
              )}

              {props.headerTable.map((header, index) => {
                if (header.enabled) {
                  return header.dbName == null ? (
                    <TableCell key={"header-actions"}>
                      {header.columnName}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={`header-${index}`}
                      sortDirection={orderBy === header.dbName ? order : false}
                      className={
                        header.columnName === "Nº Orden"
                          ? "min-120"
                          : header.columnName === "Ubicación"
                          ? "blog-ubication-icon-header"
                          : header.columnName === "Estado"
                          ? "min-120"
                          : ""
                      }
                    >
                      {header.columnName === "Ubicación" ? (
                        header.columnName
                      ) : (
                        <TableSortLabel
                          active={orderBy === header.dbName}
                          direction={orderBy === header.dbName ? order : "asc"}
                          onClick={() => handleRequestSort(header.dbName)}
                        >
                          {header.columnName}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                props.collapseType === "request-edit" ? (
                  <PendingRequestRow
                    key={`row-${index}`}
                    request={row}
                    columns={props.headerTable}
                    selected={props.selected}
                    fetchRequests={props.fetchRequests}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    deleteRequestTooltip={props.deleteRequestTooltip}
                    deleteRequestIcon={props.deleteRequestIcon}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                    comunas={props.comunas}
                  />
                ) : props.collapseType === "request-detail" ? (
                  <MyRequestRow
                    key={`row-${index}`}
                    request={row}
                    current_user={props.current_user}
                    columns={props.headerTable}
                    fetchRequests={props.fetchRequests}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "incidence-detail" ? (
                  <IncidenceRow
                    key={`row-${index}`}
                    incidence={row}
                    isOpen={props.incidencesOpen.includes(
                      row["request"]["order_number"]
                    )}
                    columns={props.headerTable}
                    fetchIncidences={props.fetchIncidences}
                    openCollapseButton={props.openCollapseButton}
                    editable={props.editable}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    completeButton={props.completeButton}
                    completeTooltip={props.completeTooltip}
                    completeIcon={props.completeIcon}
                    deleteButton={props.deleteButton}
                    deleteTooltip={props.deleteTooltip}
                    deleteIcon={props.deleteIcon}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "case-detail" ? (
                  <CaseRow
                    key={`case-${index}`}
                    caseItem={row}
                    columns={props.headerTable}
                    fetchCases={props.fetchCases}
                    current_user={props.current_user}
                    openCollapseButton={props.openCollapseButton}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    canUpdateState={props.canUpdateState}
                    deleteButton={props.deleteButton}
                    deleteTooltip={props.deleteTooltip}
                    deleteIcon={props.deleteIcon}
                    reOpenButton={props.reOpenButton}
                    reOpenTooltip={props.reOpenTooltip}
                    reOpenIcon={props.reOpenIcon}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "control-panel-detail" ? (
                  <ControlPanelRow
                    key={`control-panel-${index}`}
                    request={row}
                    columns={props.headerTable}
                    fetchRequests={props.fetchRequests}
                    current_user={props.current_user}
                    editable={props.editable}
                    openCollapseButton={props.openCollapseButton}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    deleteButton={props.deleteButton}
                    deleteTooltip={props.deleteTooltip}
                    deleteIcon={props.deleteIcon}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                    comunas={props.comunas}
                  />
                ) : props.collapseType === "devolutions-detail" ? (
                  <DevolutionRow
                    key={`devolutions-${index}`}
                    devolution={row}
                    columns={props.headerTable}
                    fetchDevolutions={props.fetchDevolutions}
                    current_user={props.current_user}
                    completeButton={props.completeButton}
                    completeTooltip={props.completeTooltip}
                    completeIcon={props.completeIcon}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "withdrawals-detail" ? (
                  <WithdrawalRow
                    key={`withdrawals-${index}`}
                    withdrawal={row}
                    columns={props.headerTable}
                    fetchWithdrawals={props.fetchWithdrawals}
                    current_user={props.current_user}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "blog-detail" ? (
                  <BlogRow
                    key={`blogs-${index}`}
                    blog={row}
                    columns={props.headerTable}
                    openCollapseButton={props.openCollapseButton}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    fetchBlogs={props.fetchBlogs}
                    current_user={props.current_user}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "mailing-detail" ? (
                  <MailingRow
                    key={`mailing-${index}`}
                    mailing={row}
                    columns={props.headerTable}
                    openCollapseButton={props.openCollapseButton}
                    openCollapseTooltip={props.openCollapseTooltip}
                    openCollapseIcon={props.openCollapseIcon}
                    fetchMailings={props.fetchMailings}
                    current_user={props.current_user}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "for-routing-table" ? (
                  <ForRoutingRow
                    key={`for-routing-${index}`}
                    request={row}
                    columns={props.headerTable}
                    selected={props.selected}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                    dateFilter={props.dateFilter}
                  />
                ) : props.collapseType === "delivery-zones-table" ? (
                  <DeliveryZoneRow
                    key={`delivery-zone-${index}`}
                    delivery_zone={row}
                    columns={props.headerTable}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                ) : props.collapseType === "comuna-delivery-zones-table" ? (
                  <ComunaDeliveryZoneRow
                    key={`comuna-delivery-zone-${row.id}`}
                    comuna={row}
                    columns={props.headerTable}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                    availableDeliveryZones={props.availableDeliveryZones}
                  />
                ) : (
                  <NormalRow
                    key={`row-${index}`}
                    request={row}
                    columns={props.headerTable}
                    color={index % 2 ? "#f5f2ff" : "#fff"}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>{" "}
      {props.hasPagination !== undefined && props.hasPagination && (
        <TablePagination
          rowsPerPageOptions={
            props.rowsPerPageOptions !== undefined
              ? props.rowsPerPageOptions
              : [20, 40, 60, 80, 100]
          }
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event) => handleChangePage(event)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
          labelDisplayedRows={({ from, to, count }) => {
            return `Mostrando ${from}-${to} de ${count}`;
          }}
          labelRowsPerPage={"Filas por página:"}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </>
  );
}

const structuredSelector = createStructuredSelector({
  incidencesOpen: (state) => state.incidencesOpen,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(CustomTable)
);
