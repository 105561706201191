import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import {
  Alert,
  Button,
  CircularProgress,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { es } from "date-fns/locale";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { strDateFormat } from "../../utils/functions";
import CustomTable from "../table/CustomTable";
import { saveAs } from "file-saver";
import { formattedDateForDB } from "../../utils/timeFormat";
import { mapStatus } from "../../utils/functions";

import TableLoader from '../site/TableLoader';

function MyRequests(props) {
  const [requests, setRequests] = useState();
  const [requestsFiltered, setRequestsFiltered] = useState();

  const [loadingManifiest, setLoadingManifiest] = useState(false);
  const [loadingLabels, setLoadingLabels] = useState(false);
  
  const [serviceFilter, setServiceFilter] = useState("");
  const [search, setSearch] = useState("");

  const [counters, setCounters] = useState({});
  const [counterLabels, setCounterLabels] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [servicesTypes, setServicesTypes] = useState([]);

  const [errorOnRetrieveData, setErrorOnRetrieveData] = useState();

  function correctFormatDate(date) {
    // Funcion que ajusta la fecha formato Date al formato "dd/mm/YY"
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      console.log(`${day}/0${month}/${year}`);
      return `${day}/0${month}/${year}`;
    } else {
      console.log(`${day}/${month}/${year}`);
      return `${day}/${month}/${year}`;
    }
  }

  const searchParams = () => {
    return {
      start_date: formattedDateForDB(startDate), 
      end_date: formattedDateForDB(endDate), 
      search_query: search,
      kind: serviceFilter
    }
  }

  const fetchRequests = async () => {
    setRequestsFiltered();
    await axios
      .get("/api/v1/requests/seller_requests", 
          {
            params: searchParams()
          }, 
          { headers: headers })
      .then(({ data }) => {
        setRequests(data.requests);
        setRequestsFiltered(data.requests);
        setServicesTypes(data.request_kinds);
        setCounters(data.counters);
        setCounterLabels(data.statuses_to_count);
        setErrorOnRetrieveData();
      })
      .catch(({ response }) => {
        console.log(response);
        flash_alert(
          "Error",
          "Hubo un error al obtener las solicitudes",
          "danger"
        );
        setRequestsFiltered([]);
        setErrorOnRetrieveData('Ocurrió un error al obtener las órdenes');
      });
  };

  const downloadManifiest = async () => {
    setLoadingManifiest(true);
    await axios
      .post(
        "/api/v1/requests/download_manifiest",
        searchParams(),
        {
          headers: headers,
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        setLoadingManifiest(false);
        let file = new File([response.data], "Manifiestos.zip", {
          type: "text/json;charset=utf-8",
        });
        let fileName = "manifiestos.zip";
        saveAs(file, fileName);
      })
      .catch((e) => {
        setLoadingManifiest(false);
        flash_alert("Error", "Problemas al descargar los manifiestos", "danger");
        console.log("error: ", e);
      });
  };

  const downloadLabels = () => {
    setLoadingLabels(true);
    return axios.post("/api/v1/requests/download_labels", 
                     searchParams(),
                     {
                       headers: headers,
                       responseType: "arraybuffer",
                     }
                    )
                .then((response) => {
                  setLoadingLabels(false);
                  let file = new File([response.data], "Etiquetas.zip", {
                    type: "text/json;charset=utf-8",
                  });
                  let fileName = "etiquetas.zip";
                  saveAs(file, fileName);
                })  
                .catch((e) => {
                  setLoadingLabels(false);
                  flash_alert("Error", "Problemas al descargar las etiquetas", "danger");
                  console.log("error: ", e);
                });
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [startDate, endDate, serviceFilter]);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      fetchRequests();
    }, 2000);

    return () => clearTimeout(delaySearch);
  }, [search]);

  let headerTable = [
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    { columnName: "Destinatario", dbName: "recipient", enabled: true },
    { columnName: "Dirección", dbName: "full_address", enabled: true },
    { columnName: "Tipo de solicitud", dbName: "translated_kind", enabled: true },
    { columnName: "Fecha de retiro", dbName: "withdrawal_date", enabled: true },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Mis solicitudes</h1>        
      </Grid>
      <Grid container>
        <Grid item xs={12} className="chip-btn-container">
          <Grid container className="flex-between">
            <Grid item>
              <Chip
                label={`${requestsFiltered ? requestsFiltered.length : 0} Solicitudes en total`}
                variant="contained"
              />
              {
                counterLabels && counterLabels.map((label) => (
                  <Chip
                    label={`${counters[label] || 0} ${mapStatus(label)}`}
                    variant="contained"
                    className={label}
                    key={label}
                  />
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="filters-container">
        <div className="row-container request-search-container">
          <Grid container>
            <Grid item xs={6} sm="auto" className="input-date-filter">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                {
                  window.innerWidth > 768 ? 
                  <DatePicker
                    label="Fecha de retiro"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      setSearch("");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={requestsFiltered === undefined}
                  />
                  : 
                  <MobileDatePicker
                    label="Fecha de retiro"
                    className="custom-mobile-datepicker"
                    okText="Aceptar"
                    cancelText="Cancelar"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      setSearch("");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={requestsFiltered === undefined}
                  />
                }
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                {
                  window.innerWidth > 768 ? 
                  <DatePicker
                    label="Fecha de retiro"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      setSearch("");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={requestsFiltered === undefined}
                  />
                  : 
                  <MobileDatePicker
                    label="Fecha de retiro"
                    className="custom-mobile-datepicker"
                    okText="Aceptar"
                    cancelText="Cancelar"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      setSearch("");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={requestsFiltered === undefined}
                  />
                }
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm="auto" className="input-service-filter">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-service-filte">
                  Tipo de servicio
                </InputLabel>
                <Select
                  value={serviceFilter}
                  onChange={(e) => {
                    setServiceFilter(e.target.value);
                    setSearch("");
                  }}
                  input={<OutlinedInput id="select-service-filter" />}
                  IconComponent={() => (
                    <i className="material-icons-outlined">expand_more</i>
                  )}
                  disabled={requestsFiltered === undefined}
                >
                  {
                    Object.entries(servicesTypes).map(([kind, label], index) => (
                      <MenuItem key={index} value={kind}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm="auto">
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar"
                name="search"
                value={search}
                onChange={(e) => {
                  setStartDate(null);
                  setEndDate(null);
                  setServiceFilter("");
                  setSearch(e.target.value);
                }}
                disabled={requestsFiltered === undefined}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} className="filters-container">
        <div>
          <Button
            className="mg-r-15"
            variant="outlined"
            onClick={() => downloadManifiest()}
            disabled={requestsFiltered === undefined}
          >
            {
              loadingManifiest ? 
              <CircularProgress className="loading-btn" />
              : 
              <>
                <i className="material-icons">download</i>
                Descargar manifiesto
              </>
            }
          </Button>
          <Button 
            variant="outlined" 
            onClick={() => downloadLabels()}
            disabled={requestsFiltered === undefined}
          >
            {
              loadingLabels ? 
              <CircularProgress className="loading-btn" />
              : 
              <>
                <i className="material-icons">download</i>
                Descargar etiquetas
              </>
            }
          </Button>
        </div>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          {
            requestsFiltered ? 
            <React.Fragment>
            {
              errorOnRetrieveData ? 
              <Alert severity="error">{errorOnRetrieveData}</Alert>
              :
              <CustomTable
                current_user={props.current_user}
                data={requestsFiltered}
                search={search}
                filterByDate={startDate !== null || endDate !== null || serviceFilter !== ""}
                headerTable={headerTable}
                fetchRequests={fetchRequests}
                selected={"none"}
                setSelected={"none"}
                openCollapseTooltip={"Ver más"}
                openCollapseIcon={
                  <i className="material-icons-outlined icon-actions-test">add_circle_outline</i>
                }
                collapseType={"request-detail"}
                hasPagination={true}
              />
            }
            </React.Fragment>
            :
            <TableLoader />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(MyRequests)
);
