import React, { useState, useEffect } from "react";
import axios from "axios";

import Home from "components/site/Home";

import { flash_alert } from 'components/App';
import { headers } from "../../constants/csrf";

import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

const IntegrationsForm = ({ current_user }) => {
  const [webhookURL, setWebhookURL] = useState();
  const [token, setToken] = useState();

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchWebhookURL = async () => {
      const { data } = await axios(`/api/v1/integrations/${current_user.id}/webhook_url`);
      setWebhookURL(data.webhook_url);
    }

    fetchWebhookURL();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);

    const body = new FormData();
    body.set('token', token);

    return axios.put(`/api/v1/users/${current_user.id}/update_integration_token`,
                     body,
                     {headers: headers})
                .then(response => {
                  flash_alert("Token actualizado", "El token se ha actualizado con éxito", "success");
                  setLoading(false);
                });
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Configuración Integración</h1>
      </Grid>

      {
        current_user.company.ecommerce != 'shopify' ?
        <p>
          La integración con tu CMS aún no está disponible, pronto lo tendremos
        </p>
        :
        <form className="flex-center ecommerce-integration-form" onSubmit={handleSubmit} autoComplete="off">
          <Grid container className="custom-form-container">
            {
              webhookURL &&
              <Grid className="link-container">
                <h6>
                  URL a ingresar en el e-commerce:
                </h6>
                <p>{ webhookURL }</p>
              </Grid>
            }

            <Grid item xs={12} sm={6} className="">
              <TextField
                fullWidth
                variant="outlined"
                label="Token"
                name="token"
                value={token}
                onChange = { e => setToken(e.target.value)}
              />

              <Button
                disabled={loading}
                id="user-save"
                type="submit"
                variant="contained"
                onClick={() => handleSubmit}
                className="flex-left"
              >
                {loading ? <CircularProgress className="loading-btn" /> : "Guardar"}
              </Button>
            </Grid>
          </Grid>
        </form>
      }
    </React.Fragment>
  );
}

export default IntegrationsForm;