import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { flash_alert } from "components/App";
import UsersActive from "components/users/UsersActive";
import UserForm from "components/users/UserForm";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
} from "@mui/material";

import InputMask from "react-input-mask";
import { isSuperAdmin } from "../../constants/user_functions";
import { validate, format } from "rut.js";
import * as EmailValidator from "email-validator";
import { RegionesYComunas, roles, ecommerces } from "../../constants/data";
import { headers } from "../../constants/csrf";

function EditUserGoex(props) {
  let userId = props.match.params.id;
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      await axios
        .get(`/api/v1/users/${userId}`, {}, { headers: headers })
        .then((response) => {
          setFullname(response.data.fullname);
          setEmail(response.data.email);
          setRole(response.data.roles_names);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          flash_alert("Error", "Hubo un error al obtener el usuario", "danger");
        });
    };
    fetchUser();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitPressed(true);
    if (EmailValidator.validate(email)) {
      if (fullname != "" && email != "" && role != "") {
        setLoading(true);
        var body = new FormData();
        body.set("fullname", fullname);
        body.set("email", email);
        body.set("role_name", role);
        return axios
          .put(`/api/v1/users/${userId}/edit`, body, {
            headers: headers,
          })
          .then((response) => {
            setLoading(false);
            flash_alert(
              "Actualizado!",
              "El usuario se ha actualizado correctamente",
              "success"
            );
            setRedirect(true);
          })
          .catch((e) => {
            setLoading(false);
            if (e.response.data) {
              for (var key in e.response.data) {
                flash_alert(
                  key.toUpperCase(),
                  e.response.data[key].join(","),
                  "danger"
                );
              }
            }
          });
      }
    }
  }

  let redirect_check = [];
  if (redirect) {
    redirect_check.push(
      <Redirect key="redirect-to-users" to="/users/active">
        <UsersActive />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <Grid item xs={12}>
        <h1>Editar Usuario</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/users/active" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>

      <form className="flex-center" onSubmit={handleSubmit} autoComplete="off">
        <Grid container className="custom-form-container">
          <Grid item xs={12} sm={12}>
            <p className="red-text small-text flex-right mg-0">
              *Campos obligatorios
            </p>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && fullname == ""}
              variant="outlined"
              label="Nombre completo*"
              name="fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              helperText={
                submitPressed && fullname == "" ? "Campo obligatorio" : ""
              }
              className="first-row-left"
            />
          </Grid>

          <Grid item xs={12} sm={6} className="flex-left pd-l-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && role == ""}
              className="first-row-right"
            >
              <InputLabel htmlFor="select-role">Selecciona tu rol*</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                input={<OutlinedInput id="select-role" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {isSuperAdmin(props.current_user)
                  ? roles.slice(0, 4).map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))
                  : roles.slice(1, 4).map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
              </Select>
              {submitPressed && role == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={
                (submitPressed && email == "") ||
                (email != "" && !EmailValidator.validate(email))
              }
              variant="outlined"
              label="Email*"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={
                submitPressed && email == ""
                  ? "Campo obligatorio"
                  : email != "" && !EmailValidator.validate(email)
                  ? "Formato incorrecto"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={loading}
              id="user-save"
              type="submit"
              variant="contained"
              onClick={() => handleSubmit}
              className="flex-left"
            >
              {loading ? (
                <CircularProgress className="loading-btn" />
              ) : (
                "Actualizar datos"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(EditUserGoex);
