import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { headers } from "../../constants/csrf";

import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";

import { flash_alert } from "components/App";
import { formattedDateDayAndMonth } from "../../utils/timeFormat";

const AssignRequestsToWithdrawal = ({ requests, currentUser, setOpenDialog, fetchRequests, setSelected }) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [selectedWithdrawalId, setSelectedWithdrawalId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fectchWithdrawals = async () => {
      const { data } = await axios(`/api/v1/companies/${currentUser.company_id}/created_withdrawals`);
      setWithdrawals(data.withdrawals)
    }

    fectchWithdrawals()
  }, []);

  const assingRequests = () => {
    setLoading(true);

    const body = new FormData();

    body.set("request_ids", requests.map(request => request.id));
    return axios.post(`/api/v1/withdrawals/${selectedWithdrawalId}/assign_requests`, 
                      body, 
                      {'headers': headers})
                .then(response => {
                  flash_alert("Solicitudes asignadas", "Las solicitudes ha sido asignadas con éxito", "success");
                  fetchRequests();
                  setOpenDialog(false);
                  setSelected([]);
                })
                .catch(e => {
                  if(e.response.data){
                    flash_alert('', 'Ocurrió un problema al ejecutar la acción', 'danger');
                  }
                });
  }


  return (
    <React.Fragment>
      <h5>Asigna tus solicitudes</h5>
      <Grid container>
        <Grid item xs={12}>
          {
            withdrawals && withdrawals.length > 0 ?
            <FormControl className="withdrawal-addresses-container">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedWithdrawalId}
                name="selected_withdrawal"
                onChange={(e) => setSelectedWithdrawalId(e.target.value)}
              >
                {withdrawals.map((withdrawal) => (
                  <FormControlLabel
                    value={withdrawal.id}
                    label={
                      <React.Fragment>
                        <h6>Retiro {formattedDateDayAndMonth(withdrawal.withdrawal_date)}</h6>
                        <p>{withdrawal.address}</p>
                      </React.Fragment>
                    }
                    control={<Radio />}
                    key={withdrawal.id}
                    className="withdrawal-option"
                  >
                    
                  </FormControlLabel>
                ))}
              </RadioGroup>
            </FormControl>
            :
            <p>No hay retiros creados</p>
          }
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={() => setOpenDialog(false)}
          className="mg-r-10"
        >
          Volver atrás
        </Button>

        <Button
          disabled={loading || withdrawals.length == 0}
          variant="contained"
          onClick={assingRequests}
          className="mg-l-10"
        >
          {loading ? 
            <CircularProgress className="loading-btn" />
            : 
            "Confirmar"
          }
        </Button>
      </DialogActions>
    </React.Fragment>
  );
} 

export default AssignRequestsToWithdrawal;