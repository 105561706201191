export const buildCompanyFormData = (company = null, withdrawalAddresses = null, user = null) => {
  const companyData = new FormData();

  if (company) {
    companyData.set("company[region]", company.region);
    companyData.set("company[commune]", company.commune);
    companyData.set("company[street]", company.street);
    companyData.set("company[street_number]", company.street_number);
    companyData.set("company[street_other]", company.street_other);
    companyData.set("company[withdrawal_hour]", company.withdrawal_hour);
    companyData.set("company[withdrawal_hour_2]", company.withdrawal_hour_2);
    companyData.set("company[can_manage_mailing]", company.can_manage_mailing);
    companyData.set("company[ecommerce]", company.ecommerce);
  }

  if (withdrawalAddresses) {
    withdrawalAddresses.forEach((address, index) => {
      if (address.id || address.region !== "") {
        if (address.id) {
          companyData.set(`company[withdrawal_addresses_attributes][${index}][id]`, address.id);
        }
        companyData.set(`company[withdrawal_addresses_attributes][${index}][region]`, address.region);
        companyData.set(`company[withdrawal_addresses_attributes][${index}][commune]`, address.commune);
        companyData.set(`company[withdrawal_addresses_attributes][${index}][street]`, address.street);
        companyData.set(`company[withdrawal_addresses_attributes][${index}][street_number]`, address.street_number);
        companyData.set(`company[withdrawal_addresses_attributes][${index}][street_other]`, address.street_other);
      }
    });
  }

  if (user) {
    companyData.set("company[users_attributes][0][id]", user.id);
    companyData.set("company[users_attributes][0][fullname]", user.fullname);
    companyData.set("company[users_attributes][0][charge]", user.charge);
    companyData.set("company[users_attributes][0][email]", user.email);
    companyData.set("company[users_attributes][0][phone_number]", user.phoneNumber);  
  }

  return companyData;
}

export const getWithdrawalAddressesArray = (withdrawalAddresses = []) => {
  const emptyAddress = {
    region: '',
    commune: '',
    street: '',
    street_number: '',
    street_other: '',
  }
  
  if (withdrawalAddresses.length == 3) {
    return withdrawalAddresses
  }
  else if (withdrawalAddresses.length == 2) {
    return [...withdrawalAddresses, emptyAddress]
  }
  else if (withdrawalAddresses.length == 1) {
    return [...withdrawalAddresses, emptyAddress, emptyAddress]
  }
  else if (withdrawalAddresses.length == 0) {
    return [emptyAddress, emptyAddress, emptyAddress]
  } 
}