import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, FormControl, Grid } from "@mui/material";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

function SuccessUpload() {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Carga de solicitudes</h1>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="flex-center">
          <Grid container className="request-success-main-container">
            <Grid item xs={12} className="flex-center">
              <span className="confeti-icon">🎉</span>
            </Grid>
            <Grid item xs={12} className="flex-center">
              <h4 className="fw-medium">¡Carga exitosa!</h4>
            </Grid>
            <Grid item xs={12} className="flex-center">
              <p>
                Cargaste tu archivo con éxito, ahora puedes tomarte un café,
                continuar gestionando tus envíos, o realizar una nueva carga.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} className="success-buttons-container">
              <Link to="/requests/pending" className="back-login-btn">
                <Button type="submit" variant="outlined">
                  {"Ver mis solicitudes"}
                </Button>
              </Link>
              <Link to="/requests/new/type" className="back-login-btn">
                <Button type="submit" variant="contained">
                  {"Nueva carga"}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(SuccessUpload)
);
