import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  DataGrid,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { LinearProgress, Pagination } from "@mui/material";

function CustomToolbar() {
  return (
    <GridToolbarContainer className="custom-toolbar-container">
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function CustomDataGrid(props) {
  return (
    <div className="data-grid-container">
      <DataGrid
        autoHeight
        loading={props.loading}
        pagination
        pageSize={10}
        columns={props.columns}
        rows={props.rows}
        disableSelectionOnClick={true}
        getRowClassName={(params) =>
          params.row.id % 2 == 0 ? "row-color-purple" : "row-color-white"
        }
        sx={{
          border: 0,
        }}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        localeText={{
          noRowsLabel: 'No hay usuarios por gestionar',
          noResultsOverlayLabel: 'No se encontraron resultados',
          errorOverlayDefaultLabel: 'Ha ocurrido un error',
          toolbarColumns: 'Columnas',
          toolbarColumnsLabel: 'Selecciona columnas',
          columnsPanelTextFieldLabel: 'Encontrar columna',
          columnsPanelTextFieldPlaceholder: 'Título columna',
          columnsPanelShowAllButton: 'Mostrar todas',
          columnsPanelHideAllButton: 'Esconder todas',
          toolbarExport: 'Exportar',
          toolbarExportLabel: 'Exportar',
          toolbarExportCSV: 'Descargar como CSV',
          toolbarExportPrint: 'Imprimir',
          columnMenuUnsort: 'Sin orden',
          columnMenuSortAsc: 'Ordenar ASC',
          columnMenuSortDesc: 'Ordenar DESC',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Ocultar',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </div>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(CustomDataGrid)
);
