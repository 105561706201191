import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { IconButton, FormControl, Tooltip } from "@mui/material";
import { Grid } from "@mui/material";
import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";
import CustomDataGrid from "../table/CustomDataGrid";

function UsersPending(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    await axios
      .get("/api/v1/users/pending", {}, { headers: headers })
      .then((response) => {
        setLoading(false);
        setUsers(response.data);
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al obtener los usuarios", "danger");
      });
  };

  const handleApproveUser = async (id) => {
    setLoading(true);
    return axios
      .put(`/api/v1/users/${id}/approve_user`, {}, { headers: headers })
      .then((response) => {
        flash_alert(
          "Exito",
          "El usuario ha sido aprobado exitosamente",
          "success"
        );
        fetchUsers();
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al aceptar un usuario", "danger");
      });
  };

  const handleRejectUser = async (id) => {
    setLoading(true);
    return axios
      .put(`/api/v1/users/${id}/reject_user`, {}, { headers: headers })
      .then((response) => {
        flash_alert(
          "Exito",
          "El usuario ha sido rechazado exitosamente",
          "success"
        );
        fetchUsers();
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al rechazar un usuario", "danger");
      });
  };

  const columns = [
    {
      field: "social_reason",
      headerName: "Razón social",
      flex: 1,
      minWidth: 80,
    },
    { field: "fullname", headerName: "Nombre", flex: 1, minWidth: 80 },
    { field: "rut", headerName: "Rut Empresa", flex: 1, minWidth: 80 },
    { field: "ecommerce", headerName: "E-commerce", flex: 1, minWidth: 80 },
    { field: "phone_number", headerName: "Teléfono", flex: 1, minWidth: 80 },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let url = `/users/edit/${params.row.id}/`;
        if (
          ["Super administrador", "Administrador", "Ruteador"].includes(
            params.row.roles_names
          )
        ) {
          url = url + "goex";
        } else {
          url = url + "seller";
        }
        return (
          <>
            <Tooltip title="Rechazar" placement="top">
              <IconButton
                color="primary"
                aria-label="edit user"
                component="span"
                id="reject-user-button"
                onClick={() => handleRejectUser(params.row.id)}
              >
                <i className="material-icons-outlined icon-primary-red">
                  cancel
                </i>
              </IconButton>
            </Tooltip>
            <Tooltip title="Aprobar" placement="top">
              <IconButton
                color="primary"
                aria-label="edit user"
                component="span"
                id="approve-user-button"
                onClick={() => handleApproveUser(params.row.id)}
              >
                <i className="material-icons-outlined icon-primary-green">
                  check_circle
                </i>
              </IconButton>
            </Tooltip>

            <Tooltip title="Ver más" placement="top">
              <Link to={url}>
                <IconButton
                  color="primary"
                  aria-label="edit user"
                  component="span"
                  id="edit-user-button"
                >
                  <i className="material-icons-outlined icon-primary-purple">
                    article
                  </i>
                </IconButton>
              </Link>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Clientes por aprobar</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/home" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <Grid container direction="row" className="mg-t-40">
        <Grid item xs={12} sm={12}>
          <CustomDataGrid columns={columns} rows={users} loading={loading} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(UsersPending)
);
