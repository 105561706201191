import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { headers, headersFiles } from "../../constants/csrf";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import CustomDropzone from "../site/CustomDropzone";
import { flash_alert } from "components/App";
import Cases from "./Cases";
import { reasons } from "../../constants/data";
import { fetchFileNames } from "../../utils/functions";

function NewCase(props) {
  const [loading, setLoading] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [requests, setRequests] = useState([]);
  const [referenceId, setReferenceId] = useState("");
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [files, setFiles] = useState([]);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const fetchRequests = async () => {
    setLoadingRequests(true);
    await axios
      .get("/api/v1/customer_supports/valid_requests", {}, { headers: headers })
      .then((response) => {
        setLoadingRequests(false);
        setRequests(response.data);
      })
      .catch((e) => {
        setLoadingRequests(false);
        flash_alert("Error", e, "error");
      });
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const handleDelete = (path) => {
    setFiles(files.filter((file) => file.path !== path));
  };

  const handleSubmit = async () => {
    setSubmitPressed(true);
    if (referenceId !== "" && referenceId !== null && reason !== "") {
      setLoading(true);
      const body = new FormData();
      body.set(
        "reference_id",
        referenceId.split("Id referencia: ")[1].split(" - ")[0]
      );
      body.set("reason", reason);
      body.set("comments", comments);
      await axios
        .post("/api/v1/customer_supports", body, { headers: headers })
        .then((response) => {
          let requests = [];
          files.map((file) => {
            let body = new FormData();
            body.append("file", file);
            requests.push(
              axios.post(
                `/api/v1/customer_supports/${response.data.id}/upload_file`,
                body,
                {
                  headers: headersFiles,
                }
              )
            );
          });
          axios
            .all(requests)
            .then(() => {
              setLoading(false);
              setRedirect(true);
              flash_alert("Éxito", "Caso creado con éxito", "success");
            })
            .catch((e) => {
              setLoading(false);
              flash_alert(
                "Error",
                "Error al crear el caso, intenta denuevo",
                "danger"
              );
            });
        })
        .catch((e) => {
          setLoading(false);
          flash_alert("Error", e, "error");
        });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setFiles((files) =>
        !fetchFileNames(files).includes(file.name) ? [...files, file] : files
      );
    });
  }, []);

  let redirect_check = [];
  if (redirect) {
    redirect_check.push(
      <Redirect key="redirect-to-cases" to="/customer_supports">
        <Cases />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <Grid item xs={12}>
        <h1>Nuevo caso</h1>
      </Grid>
      {loadingRequests ? (
        <CircularProgress className="loading-btn" />
      ) : (
        <Grid container>
          <Grid item xs={12} className="flex-center">
            <Grid container className="customer-support-container">
              <Grid item xs={12} sm={12} className="flex-center">
                <p className="subtitle">
                  Llena los campos a continuación para que nos pongamos en
                  contacto
                </p>
              </Grid>
              <Grid item xs={12} sm={6} className="pd-r-10">
                <Autocomplete
                  disablePortal
                  id="request-list"
                  onChange={(event, newValue) => {
                    setReferenceId(newValue);
                  }}
                  //inputValue={referenceId === null ? "" : referenceId}
                  options={requests.map(
                    (request) =>
                      `Id referencia: ${request["reference_id"]} - Número orden: ${request["order_number"]}`
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Busca y selecciona una solicitud*"
                      error={
                        submitPressed &&
                        (referenceId === "" || referenceId === null)
                      }
                      helperText={
                        submitPressed &&
                        (referenceId === "" || referenceId === null)
                          ? "Campo obligatorio"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} className="pd-l-10 flex-left">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={submitPressed && reason == ""}
                >
                  <InputLabel htmlFor="select-reason">
                    Selecciona un motivo de atención*
                  </InputLabel>
                  <Select
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    input={<OutlinedInput id="select-reason" />}
                    IconComponent={() => (
                      <i className="material-icons-outlined">expand_more</i>
                    )}
                  >
                    {reasons.map((reason, index) => (
                      <MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                  {submitPressed && reason == "" && (
                    <FormHelperText>Campo obligatorio</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className="pd-r-10">
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  label="Comentarios adicionales"
                  name="comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  className="multiline-textfield"
                />
              </Grid>

              <Grid item xs={12} sm={6} className="pd-l-10 mg-t-20">
                <p className="mg-0 flex-left super-small-text">
                  Puedes cargar archivos jpg, png o pdf
                </p>
                <CustomDropzone
                  onDrop={onDrop}
                  accept={"image/png, image/jpeg, application/pdf"}
                />
                <Grid item className="custom-stack-container">
                  <Stack direction="row" spacing={1} className="custom-stack">
                    {files.map((file, index) => {
                      return (
                        <Chip
                          key={index}
                          label={file.path}
                          onDelete={() => handleDelete(file.path)}
                        />
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} className="support-actions-container">
                <Link
                  to={"/customer_supports"}
                  className="flex-left"
                  id={"link-to-home"}
                >
                  <Button disabled={loading} variant="outlined">
                    Volver atrás
                  </Button>
                </Link>
                <Button
                  disabled={loading}
                  variant="contained"
                  onClick={() => handleSubmit()}
                  className="flex-left"
                >
                  {loading ? (
                    <CircularProgress className="loading-btn" />
                  ) : (
                    "Crear caso"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(NewCase)
);
