import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
} from "@mui/material";

import { RegionesYComunas } from "../../constants/data";

const WithdrawalAddressForm = ({ address, changeAttribute, submitPressed, formTitle, addressIndex, isMandatory }) => {
  const [selectedRegion, setSelectedRegion] = useState([]);

  useEffect(() => {
    if (address.region && address.region !== '') {
      setSelectedRegion(
        RegionesYComunas.filter((region) => region.name == address.region)
      );
    }
  }, [address.region]);

  return (
    <React.Fragment>
      {
        formTitle && 
        <Grid item xs={12} sm={12} className="flex-left">
          <h6 className="fw-medium">{ formTitle } { !isMandatory && "(opcional)" }</h6>
        </Grid>
      }
      <Grid item xs={12} sm={6} className="flex-left pd-r-10">
        <FormControl
          fullWidth
          variant="outlined"
          error={isMandatory && submitPressed && address.region == ""}
        >
          <InputLabel htmlFor="select-region1">
            Selecciona tu región{isMandatory && "*"}
          </InputLabel>
          <Select
            value={address.region}
            onChange={(e) => {
              changeAttribute('commune', '', addressIndex);
              changeAttribute('region', e.target.value, addressIndex);
            }}
            id='input-region1'
            input={<OutlinedInput id="select-region1" />}
            IconComponent={() => (
              <i className="material-icons-outlined">expand_more</i>
            )}
          >
            {
              RegionesYComunas.map((region, index) => (
                <MenuItem key={index} value={region.name}>
                  {region.name}
                </MenuItem>
              ))
            }
          </Select>
          {
            isMandatory && submitPressed && address.region == "" && 
            <FormHelperText>Campo obligatorio</FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className="flex-left pd-l-10">
        <FormControl
          fullWidth
          variant="outlined"
          disabled={address.region == ""}
          error={isMandatory && submitPressed && address.commune == ""}
        >
          <InputLabel htmlFor="select-commune1">
            Selecciona tu comuna{isMandatory && "*"}
          </InputLabel>
          <Select
            value={address.commune}
            onChange={(e) => changeAttribute('commune', e.target.value, addressIndex)}
            id='input-commune1'
            input={<OutlinedInput id="select-commune1" />}
            IconComponent={() => (
              <i className="material-icons-outlined">expand_more</i>
            )}
          >
            {
              selectedRegion.length == 0 ? 
              <MenuItem value={"Santiago"}>{"Santiago"}</MenuItem>
              : 
              (
                selectedRegion[0].communes.map((commune, index) => (
                  <MenuItem key={index} value={commune}>
                    {commune}
                  </MenuItem>
                ))
              )
          }
          </Select>
          {
            isMandatory && submitPressed && address.commune == "" && 
            <FormHelperText>Campo obligatorio</FormHelperText>
          }
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} className="pd-r-10">
        <TextField
          fullWidth
          error={isMandatory && submitPressed && address.street == ""}
          variant="outlined"
          label={`Calle${isMandatory && "*"}`}
          name="calle"
          value={address.street}
          id='input-street1'
          onChange={(e) => changeAttribute('street', e.target.value, addressIndex)}
          helperText={
            isMandatory && submitPressed && address.street == "" ? "Campo obligatorio" : ""
          }
        />
      </Grid>

      <Grid item xs={6} sm={3} className="pd-l-10 pd-r-10">
        <TextField
          fullWidth
          error={isMandatory && submitPressed && address.street_number == ""}
          variant="outlined"
          type="number"
          label={`Número${isMandatory && "*"}`}
          name="street_number"
          value={address.street_number}
          id='input-street-number1'
          onChange={(e) => changeAttribute('street_number', e.target.value, addressIndex)}
          helperText={
            isMandatory && submitPressed && address.street_number == ""
              ? "Campo obligatorio"
              : ""
          }
        />
      </Grid>

      <Grid item xs={6} sm={3} className="pd-l-10">
        <TextField
          fullWidth
          variant="outlined"
          label="Depto/Casa"
          name="street_other"
          value={address.street_other}
          id='input-street-other1'
          onChange={(e) => changeAttribute('street_other', e.target.value, addressIndex)}
        />
      </Grid>
    </React.Fragment>
  );
}

export default WithdrawalAddressForm;