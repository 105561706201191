import moment from 'moment-timezone';

export function validRequest(request) {
  return  !(
    (request.reference_id === null || request.reference_id === "") ||
    (request.recipient === null || request.recipient === "") ||
    (request.address === null || request.address === "") ||
    (request.comuna_name === null || request.comuna_name === "")
  )
}

export function plannedDateToDate(planned_date) {
  let array = planned_date.split("/");
  let date_str = `${array[2]}-${array[1]}-${array[0]}`;
  return new Date(date_str);
}

export function strDateFormat(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
}

export function strDateFormatWithTime(dateStr) {
  let date;
  // Caso sin tiempo
  if(dateStr.split(" ").length === 1){
    let dateArray = dateStr.split("/");
    date = new Date(dateArray[2], +dateArray[1] - 1, dateArray[0]);
  } else {// Caso con tiempo
    // Caso datos desde la API
    if(dateStr.split(" ")[0].split("/").length > 1){
      return dateStr;
    } else {
      // Caso datos desde SR
      date = new Date(dateStr);
    }
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year} ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
}

export function getWidrawalHour(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
}

export function disableDateFunction(date, withdrawalTime) {
  if (withdrawalTime === undefined || withdrawalTime === null) {
    return false;
  }

  const today = new Date();

  let withdrawalHour = parseInt(withdrawalTime.split(":")[0], 10);
  let withdrawalMin = parseInt(withdrawalTime.split(":")[1], 10);

  return isToday(date) &&
    (today.getHours() > withdrawalHour ||
      (today.getHours() === withdrawalHour &&
        today.getMinutes() >= withdrawalMin));

}

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

const isOlderDate = (someDate) => {
  const today = new Date();
  return (someDate < today);
};

export function mapStatus(status) {
  switch (status) {
    case "in_warehouse":
      return "En Bodega";
      break;
    case "to_be_withdrawn":
      return "Por retirar";
      break;
    case "withdrawn":
      return "Retirado";
      break;
    case "on_the_way":
      return "En camino";
      break;
    case "finished":
      return "Completado";
      break;
    case "failed":
      return "Fallido";
      break;
    case "paused_for_plan":
      return "Pausado por plan";
      break;
    case "in_distribution_center":
      return "En CD";
      break;
    case "back_to_warehouse":
      return "Devuelto a Bodega";
      break;
    default:
      break;
  }
}

export function mapStatusIncidence(status) {
  switch (status) {
    case "failed":
      return "Fallido";
    case "pending":
      return "Pendiente día anterior";
    case "completed":
      return "Completado";
    default:
      break;
  }
}

export function mapStatusCase(status) {
  switch (status) {
    case "created":
      return "Creado";
    case "in_process":
      return "Caso en proceso";
    case "completed":
      return "Caso completado";
    case "disabled":
      return "Caso eliminado";
    default:
      return "Estado desconocido";
  }
}

export function mapStatusWithdrawal(status) {
  switch (status) {
    case "created":
      return "Creado";
    case "pending":
      return "Retiro pendiente";
    case "withdrawn":
      return "Retirado";
    default:
      return "Estado desconocido";
  }
}

export const checkImageDimensions = async (file, width, height) => {
  const promise = new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      resolve(image.width === width && image.height === height);
    };
    let url = URL.createObjectURL(file);
    image.src = url;
  });
  return await promise;
};

export const fetchFileNames = (files) => {
  let arrNames = [];
  files.map((file) => {
    arrNames.push(file.name);
  });
  return arrNames;
};


export const requestIndication = (request, filterDate = new Date()) => {
  if (request.are_exceeded_visits) {
    return 'devolver a bodega'
  }
  else {
    if (filterDate === null) {
      filterDate = new Date();
    }
    filterDate = strDateFormat(filterDate);

    if (request.last_scheduled_delivery_date === filterDate) {
      return 'pendiente de ruta';
    }
    else if (request.last_scheduled_delivery_date < filterDate) {
      return 'atrasada';
    }
    else if (request.last_scheduled_delivery_date > filterDate) {
      return 'almacenar';
    }
  }

  return '';
}