import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import configureStore from "../../stores/configureStore";
import axios from "axios";
import { flash_alert } from "components/App";

import {
  TextField,
  Grid,
  FormControl,
  Button,
  Link,
  CircularProgress,
} from "@mui/material";

import * as EmailValidator from "email-validator";
import logo from "../../../assets/images/logo-goex.svg";
import { headers } from "../../constants/csrf";

function ForgottenPassword(props) {
  const store_r = configureStore(props.current_user);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);

  function handleSubmit() {
    var body = new FormData();
    body.set("email", email);
    setSubmitPressed(true);
    if (EmailValidator.validate(email)) {
      setLoading(true);
      return axios
        .post("/api/v1/users/forgotten_password", body, { headers: headers })
        .then((response) => {
          setLoading(false);
          flash_alert("Información", "Correo enviado con éxito, revisa tu bandeja de entrada ✉️", "notice");
        })
        .catch((e) => {
          setLoading(false);
          flash_alert("Error", "Hubo un error al enviar el correo", "danger");
        });
    }
  }

  return (
    <Provider store={store_r}>
      <React.Fragment>
        <Grid container item xs={12} className="succes-logo-container">
          <div className="success-logo">
            <img src={logo} alt="Logo Go-EX" />
          </div>
        </Grid>
        <div className="flex-center">
          <Grid container className="password-container">
            <Grid item xs={12} sm={12}>
              <h4 className="fw-medium">¿Olvidaste tu contraseña? 😵‍💫</h4>
            </Grid>
            <Grid item xs={12} sm={12}>
              <p>
                No te preocupes, déjanos tu email y te enviaremos un correo de
                recuperación.
              </p>
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={10} sm={10} className="flex-center">
              <TextField
                fullWidth
                error={
                  submitPressed &&
                  (!EmailValidator.validate(email) || email === "")
                }
                variant="outlined"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={
                  submitPressed && email == "" ? "Ingresa un correo" : ""
                }
              />
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={12} className="flex-center">
              <Button variant="contained" onClick={() => handleSubmit()} id="recover-password-button">
                {loading ? (
                  <CircularProgress className="loading-btn" />
                ) : (
                  "Recuperar contraseña"
                )}
              </Button>
            </Grid>
            <Grid item xs={12} className="flex-center">
              <FormControl className="">
                <Link href="/login" underline="none" className="back-login-btn">
                  <i className="material-icons-outlined forgotten-back-arrow">
                    arrow_back
                  </i>
                  <p>Volver a Iniciar Sesión</p>
                </Link>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    </Provider>
  );
}

export default ForgottenPassword;
