import React from 'react';

import {
  TableCell,
  TableRow,
} from "@mui/material";

const DeliveryZoneRow = ({delivery_zone, columns, color}) => {

  return (
    <TableRow
      sx={{ "& > *": { borderBottom: "unset" } }}
      style={{ backgroundColor: color }}
    >
      {
        columns.map((column, index) => {
          return (
            <TableCell
              component="th"
              scope="row"
              key={`${column.columnName}-${index}`}
            >
              { 
                column.dbName === 'colour' ?
                <i className={`material-icons ${delivery_zone.colour}`}>circle</i>
                :
                column.dbName === 'price' ?
                `$ ${new Intl.NumberFormat('en-DE').format(delivery_zone.price)}`
                :
                delivery_zone[column.dbName] 
              }
            </TableCell>
          );
        })
      }
    </TableRow>
  );
}

export default DeliveryZoneRow;