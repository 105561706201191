import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import { flash_alert } from "components/App";
const DELETE_USER = "DELETE_USER";
const DELETED_USER = "DELETED_USER";

function deleteUser(user_id, headers) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER });
    return axios
      .delete(`/api/v1/users/${user_id}`, { headers: headers })
      .then((response) => {
        flash_alert(
          "Eliminado!",
          "El usuario se ha eliminado correctamente",
          "success"
        );
        dispatch({ type: DELETED_USER, response });
      })
      .catch((e) => {
        flash_alert("Error!", "No se ha podido eliminar el usuario", "danger");
      });
  };
}

function DeleteUserDialog(props) {
  const [open, setOpen] = useState(false);

  function handleClickOpen(e) {
    e.preventDefault();
    setOpen(true);
  }

  function handleClose(e) {
    e.preventDefault();
    setOpen(false);
  }

  async function handleDelete(e) {
    e.preventDefault();
    setOpen(false);
    props.deleteUser(props.user_id, props.headers);
  }
  return (
    <React.Fragment>
      <Link
        href="#"
        id={"user_" + props.user_id}
        className="mdl-navigation__link brand-primary-link user-delete-link"
        onClick={handleClickOpen}
      >
        <i className="material-icons-outlined material-icons-20">delete</i>{" "}
        Borrar
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Esta seguro que desea eliminar el usuario?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que desea eliminar el usuario {props.name}{" "}
            {props.lastname}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            id="user-delete"
            onClick={handleDelete}
            color="primary"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  users: (state) => state.users,
});
const mapDispatchToProps = { deleteUser };

export default connect(
  structuredSelector,
  mapDispatchToProps
)(DeleteUserDialog);
