import { createStore, applyMiddleware } from "redux";
import { initCanCan } from "redux-cancan";
import thunk from "redux-thunk";
const initialState = {
  users: [],
  user: {},
  page: 1,
  per_page: 10,
  current_user: {},
  total: 1,
  requestType: "",
  ecommerce: "",
  incidencesLength: 0,
  incidencesOpen: [],
  casesLength: 0,
  mailingEdit: {},
};

function rootReducer(state, action) {
  switch (action.type) {
    case "GET_CURRENT_USER":
      return {
        ...state,
        current_user: action.json,
      };
    case "GET_USERS_SUCCESS":
      return {
        users: action.json.data,
        page: action.json.page,
        per_page: action.json.per_page,
        total: action.json.total,
      };
    case "UPDATE_REQUEST_TYPE":
      return {
        ...state,
        requestType: action.payload,
      };
    case "UPDATE_ECOMMERCE":
      return {
        ...state,
        ecommerce: action.payload,
      };
    case "UPDATE_PENDING_INCIDENCES":
      return {
        ...state,
        incidencesLength: action.payload,
      };
    case "UPDATE_PENDING_CASES":
      return {
        ...state,
        casesLength: action.payload,
      };
    case "ADD_OPEN_INCIDENCE":
      return {
        ...state,
        incidencesOpen: [...state.incidencesOpen, action.payload],
      };
    case "REMOVE_OPEN_INCIDENCE":
      return {
        ...state,
        incidencesOpen: state.incidencesOpen.filter(
          (orderNumber) => orderNumber !== action.payload
        ),
      };
    case "UPDATE_MAILING_EDIT":
      return {
        ...state,
        mailingEdit: action.payload,
      };
    case "REDIRECT":
      return { redirectTo: action.payload };
    case "DELETED_USER":
      const user_id = action.response.data.user_id;
      let users = state.users.filter((user) => user.id !== user_id);
      return {
        ...state,
        users,
      };
    default:
      return state;
  }
}

export default function configureStore(current_user) {
  initialState.current_user = current_user;
  const store = createStore(rootReducer, initialState, applyMiddleware(thunk));
  initCanCan(store, require("./ability"));
  return store;
}
