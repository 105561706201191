export default store => {
  console.log("store: ", store.getState())
  const { current_user} = store.getState();

  if (current_user) {
    // protected routes
    switch (current_user.get_roles_names) {
      case 'Administrador':
        return ["/", '/home', '/users', "/users/new", "/users/:id/edit", '/companies/new'];
      case 'Seller':
        return ["/", '/home', '/users/profile', '/requests/new/type', '/requests', '/requests/pending', '/integrations']
      case 'Seller operador':
        return ["/", '/home', '/users/profile']
      default:
        return ['/home'];
    }
  }

  return ['/home'];
};