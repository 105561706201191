import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

import {
  Button,
  Grid,
} from "@mui/material";

import CustomTable from "../table/CustomTable";

const ComunasDeliveryZones = () => {
  const [comunas, setComunas] = useState([]);
  const [availableDeliveryZones, setAvailableDeliveryZones] = useState([]);

  useEffect(() => {
    const fetchComunas = async () => {
      await axios.get("/api/v1/comunas")
                 .then(({ data }) => {
                   setComunas(data);
                 })
    }

    const fetchDeliveryZones = () => {
      return axios.get("/api/v1/delivery_zones")
                .then(({ data }) => {
                  setAvailableDeliveryZones(data);
                })
                .catch((e) => {
                  console.log("error: ", e);
                });
    }

    fetchComunas();
    fetchDeliveryZones();
  }, []);

  const headerTable = [
    { columnName: "Comuna", dbName: "name", enabled: true },
    { columnName: "Zona", dbName: "delivery_zone", enabled: true },
  ];

  return (
    <Grid container >
      <Grid item xs={12}>
        <h1>Configuración de comunas</h1>
      </Grid>

      <Grid item xs={12}>
        <Link to={"/delivery_zones"} id={"link-to-delivery-zones"}>
          <Button variant="outlined">
            <i className="material-icons-outlined">chevron_left</i>
            Zonas de Envío
          </Button>
        </Link>

        <CustomTable
          data={comunas}
          headerTable={headerTable}
          selected={"none"}
          setSelected={"none"}
          openCollapseButton={false}
          openCollapseTooltip={"Ver más"}
          openCollapseIcon={
            <i className="material-icons-outlined">add_circle_outline</i>
          }
          hasPagination={true}
          collapseType={"comuna-delivery-zones-table"}
          availableDeliveryZones={availableDeliveryZones}
        />
      </Grid>
    </Grid>
  );
}

export default ComunasDeliveryZones;