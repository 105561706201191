import React from "react";

import { requestIndication } from "../../utils/functions";

const Indication = ({ request, dateFilter }) => {

  const indicationText = requestIndication(request, dateFilter);

  return (
    <React.Fragment>
    {
      ['withdrawn', 'in_distribution_center'].includes(request.aasm_state) &&
      request.last_scheduled_delivery_date &&
      <span
        className={`request-indication ${indicationText.replaceAll(' ', '-')}`}
      >
        { indicationText }
      </span>
    }
    </React.Fragment>
  )
}

export default Indication;