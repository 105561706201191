import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useDropzone } from "react-dropzone";
import { flash_alert } from "components/App";

function CustomDropzone(props) {
  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: props.width,
    height: "173px",
    border: "1px dashed #c3b3ff",
    borderRadius: "20px",
    marginTop: props.marginTop,
  };

  const focusedStyle = {
    border: "1px dashed #00e1be",
  };

  const rejectStyle = {
    border: "1px dashed #ff6666",
  };

  const onDrop = props.onDrop;
  const hasRestriction =
    props.hasRestriction === undefined ? false : props.hasRestriction;
  const restrictionType = props.restrictionType;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: props.accept
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <React.Fragment>
      <div {...getRootProps({ style })} className="dropzone-container">
        <i className="material-icons">cloud_upload</i>
        <input {...getInputProps({ name: 'file' })} />
        {<p>Arrastra o selecciona tu archivo</p>}
      </div>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(CustomDropzone)
);
