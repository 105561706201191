import React, { useState, useEffect } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";

const NewDeliveryZone = ({ closeModalForm, fetchDeliveryZones }) => {
  const [open, setOpen] = useState(true);
  const [submitPressed, setSubmitPressed] = useState(false);

  const [newDeliveryZone, setNewDeliveryZone] = useState({
    number: 0,
    name: '',
    colour: '',
    price: 0,
  });

  const changeAttribute = (attribute, newValue) => {
    setNewDeliveryZone({...newDeliveryZone, [attribute]: newValue});
  }

  const submitNewDeliveryZone = () => {
    setSubmitPressed(true);
    if (newDeliveryZone.name !== "" && newDeliveryZone.colour !== "") {

      const deliveryZoneData = new FormData();
      deliveryZoneData.set("delivery_zone[number]", newDeliveryZone.number);
      deliveryZoneData.set("delivery_zone[name]", newDeliveryZone.name);
      deliveryZoneData.set("delivery_zone[colour]", newDeliveryZone.colour);
      deliveryZoneData.set("delivery_zone[price]", newDeliveryZone.price);

      return axios.post("/api/v1/delivery_zones", deliveryZoneData, { headers: headers })
                  .then((response) => {
                    setOpen(false);
                    fetchDeliveryZones();
                    flash_alert(
                      "Éxito",
                      "La zona de envío a sido creada con éxito",
                      "success"
                    );
                  })
    }
  }

  return (
    <Dialog onClose={closeModalForm} open={open}>
      <DialogTitle>Nueva Zona de Envío</DialogTitle>
      <DialogContent>
        <TextField
          label="Zona"
          type="number"
          fullWidth
          variant="outlined"
          value={newDeliveryZone.number}
          onChange={(e) => changeAttribute('number', e.target.value)}
          required={true}
          error={submitPressed && newDeliveryZone.number == ""}
          helperText={submitPressed && newDeliveryZone.number == "" && "Campo obligatorio"}
        />

        <TextField
          label="Nombre"
          fullWidth
          variant="outlined"
          value={newDeliveryZone.name}
          onChange={(e) => changeAttribute('name', e.target.value)}
          required={true}
          error={submitPressed && newDeliveryZone.name == ""}
          helperText={submitPressed && newDeliveryZone.name == "" && "Campo obligatorio"}
        />

        <FormControl fullWidth>
          <InputLabel id="colour-select-label">Color</InputLabel>
          <Select
            labelId="colour-select-label"
            label="Color"
            value={newDeliveryZone.colour}
            onChange={(e) => changeAttribute('colour', e.target.value)}
          >
            <MenuItem value={"green"}>
              <i className="material-icons" style={{color: "#00FF00"}}>circle</i>
              Verde
            </MenuItem>
            <MenuItem value={"pink"}>
              <i className="material-icons" style={{color: "#EA899A"}}>circle</i>
              Rosado
            </MenuItem>
            <MenuItem value={"blue"}>
              <i className="material-icons" style={{color: "#0000FF"}}>circle</i>
              Azul
            </MenuItem>
            <MenuItem value={"orange"}>
              <i className="material-icons" style={{color: "#FF8000"}}>circle</i>
              Naranjo
            </MenuItem>
            <MenuItem value={"red"}>
              <i className="material-icons" style={{color: "#FF0000"}}>circle</i>
              Rojo
            </MenuItem>
            <MenuItem value={"yellow"}>
              <i className="material-icons" style={{color: "#E5BE01"}}>circle</i>
              Amarillo
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Precio"
          type="number"
          fullWidth
          variant="outlined"
          value={newDeliveryZone.price}
          onChange={(e) => changeAttribute('price', e.target.value)}
          required={true}
          error={submitPressed && newDeliveryZone.price == ""}
          helperText={submitPressed && newDeliveryZone.price == "" && "Campo obligatorio"}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined"
          onClick={closeModalForm}
        >
          Cancelar
        </Button>

        <Button 
          variant="contained"
          onClick={submitNewDeliveryZone}
        >
          Crear Zona de Envío
        </Button>
      </DialogActions>      
    </Dialog>
  );
}

export default NewDeliveryZone;