import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { csrf, headers } from "constants/csrf";

// App components
import { flash_alert } from "components/App";

//Site
import SiteBreadCrumbs from "components/site/SiteBreadCrumbs";
import Counter from "components/site/Counter";
import NoMatch from "components/site/NoMatch";

// Company
import NewCompany from "components/companies/NewCompany";

// User
import Home from "components/site/Home";
import Profile from "components/users/Profile";
import AdminProfile from "components/users/AdminProfile";
import UsersActive from "components/users/UsersActive";
import UsersPending from "components/users/UsersPending";
import EditUserSeller from "components/users/EditUserSeller";
import EditUserGoex from "components/users/EditUserGoex";
import AdminNewUser from "components/users/AdminNewUser";
import Blogs from "components/configuration/Blogs";
import Mailings from "components/configuration/Mailings";
import MailingConfig from "components/configuration/MailingConfig";
import IntegrationsForm from "components/configuration/IntegrationsForm";
import DeliveryZones from "components/configuration/DeliveryZones";
import ComunasDeliveryZones from "components/configuration/ComunasDeliveryZones";
import LoadKindPrices from "components/companies/LoadKindPrices";

// Requests
import SelectRequestType from "components/requests/SelectRequestType";
import SelectRequestEcommerce from "components/requests/SelectRequestEcommerce";
import SuccessUpload from "components/requests/SuccessUpload";
import MyRequests from "components/requests/MyRequests";
import PendingRequests from "components/requests/PendingRequests";
import ForRoutingRequests from "components/requests/ForRoutingRequests";

// Incidences
import IncidenceManagement from "components/incidences/IncidenceManagement";

// Customer support
import Cases from "components/support/Cases";
import NewCase from "components/support/NewCase";

// Control Panel
import Requests from "components/controlPanel/Requests";
import Devolutions from "components/controlPanel/Devolutions";
import Withdrawals from "components/controlPanel/Withdrawals";

// Mui components
import { CircularProgress } from "@mui/material";

const GET_CURRENT_USER = "GET_CURRENT_USER";
function getCurrentUser(user) {
  return (dispatch) => {
    dispatch(getCurrentUserSuccess(user));
  };
}

export function getCurrentUserSuccess(json) {
  return {
    type: GET_CURRENT_USER,
    json,
  };
}

function Routes(props) {
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    props.getCurrentUser(props.current_user);
    setLoading(true);
    check_permissions(location.pathname);
    setLoading(false);
    history.listen((location, action) => {
      setLoading(true);
      check_permissions(location.pathname);
      setLoading(false);
    });
  }, [history.location.pathname]);

  useEffect(() => {
    props.getCurrentUser(props.current_user);
  }, []);

  function check_permissions(pathname) {
    if (location.pathname == "/") {
      return;
    }
    return fetch(`/api/v1/abilities/check?location=${pathname}`)
      .then((response) => response.json())
      .then((json) => {
        setRedirect(!json.response);
        if (!json.response) {
          flash_alert(
            "No autorizado",
            "Este usuario no tiene permisos para realizar esta acción 😔",
            "danger"
          );
        }
      })
      .catch((error) => console.log(error));
  }

  let redirect_check = [];
  if (redirect) {
    redirect_check.push(
      <Redirect key="redirect-to-home" to="/home">
        <Home />
      </Redirect>
    );
  }

  let loading_check = [];
  if (loading) {
    loading_check.push(
      <div key="loading-container" className="loading-container">
        <CircularProgress key="loading" />
      </div>
    );
  }
  return (
    <div>
      {redirect_check}
      <div className="header-main-container">
        <SiteBreadCrumbs />
        {
          props.current_user.company && <Counter current_user={props.current_user}/>
        }
      </div>
      {loading_check}
      <div className="main-routes">
        <Switch>
          <Route
            path="/home"
            render={({ match }) => (
              <Home current_user={props.current_user} match={match} />
            )}
          />
          <Route
            path="/companies/new"
            render={({ match }) => (
              <NewCompany current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/profile"
            render={({ match }) => (
              <Profile current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/admin_profile"
            render={({ match }) => (
              <AdminProfile current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/active"
            render={({ match }) => (
              <UsersActive current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/pending"
            render={({ match }) => (
              <UsersPending current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/admin_new"
            render={({ match }) => (
              <AdminNewUser current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/edit/:id/seller"
            render={({ match }) => (
              <EditUserSeller current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/edit/:id/seller/load_kind_prices"
            render={({ match }) => (
              <LoadKindPrices current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/users/edit/:id/goex"
            render={({ match }) => (
              <EditUserGoex current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/requests/new/type"
            render={({ match }) => (
              <SelectRequestType
                current_user={props.current_user}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/requests/new/file_kind"
            render={({ match }) => (
              <SelectRequestEcommerce
                current_user={props.current_user}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/requests/new/success"
            render={({ match }) => (
              <SuccessUpload current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/requests"
            render={({ match }) => (
              <MyRequests current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/requests/pending"
            render={({ match }) => (
              <PendingRequests
                current_user={props.current_user}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/requests/for_routing"
            render={({ match }) => (
              <ForRoutingRequests
                current_user={props.current_user}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/incidences"
            render={({ match }) => (
              <IncidenceManagement
                current_user={props.current_user}
                match={match}
              />
            )}
          />

          <Route
            exact
            path="/customer_supports"
            render={({ match }) => (
              <Cases current_user={props.current_user} match={match} />
            )}
          />

          <Route
            exact
            path="/customer_supports/new_case"
            render={({ match }) => (
              <NewCase current_user={props.current_user} match={match} />
            )}
          />

          <Route
            exact
            path="/control_panel"
            render={({ match }) => (
              <Requests current_user={props.current_user} match={match} />
            )}
          />

          <Route
            exact
            path="/control_panel/devolutions"
            render={({ match }) => (
              <Devolutions current_user={props.current_user} match={match} />
            )}
          />

          <Route
            exact
            path="/control_panel/withdrawals"
            render={({ match }) => (
              <Withdrawals current_user={props.current_user} match={match} />
            )}
          />

          <Route
            exact
            path="/blogs"
            render={({ match }) => (
              <Blogs current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/mailings"
            render={({ match }) => (
              <Mailings current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/mailings/edit"
            render={({ match }) => (
              <MailingConfig current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/integrations"
            render={({ match }) => (
              <IntegrationsForm current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/delivery_zones"
            render={({ match }) => (
              <DeliveryZones current_user={props.current_user} match={match} />
            )}
          />
          <Route
            exact
            path="/delivery_zones/comunas"
            render={({ match }) => (
              <ComunasDeliveryZones current_user={props.current_user} match={match} />
            )}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    </div>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = { getCurrentUser };
export default connect(structuredSelector, mapDispatchToProps)(Routes);
