import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import {
  mapStatus,
  strDateFormatWithTime,
} from "../../utils/functions";

import logo from "../../../assets/images/logo-goex.svg";
import { saveAs } from "file-saver";
import { VolunteerActivismOutlined } from "@mui/icons-material";
import { isSeller, isSellerOperator } from "../../constants/user_functions";

import { formattedDateDDMMYYYY } from '../../utils/timeFormat';

function MyRequestRow(props) {
  const {
    request,
    columns,
    color,
    openCollapseTooltip,
    openCollapseIcon,
    current_user,
  } = props;
  // Edit pending request
  const [statusDetail, setStatusDetail] = useState("");
  const [comments, setComments] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const lastSrResponse =
    request["sr_responses"] && request["sr_responses"].length > 0
      ? JSON.parse(request["sr_responses"][request["sr_responses"].length - 1])
      : null;

  const downloadPhotos = async () => {
    setLoading(true);
    await axios
      .post(
        `/api/v1/requests/${request["order_number"]}/download_sr_photos`,
        {},
        { headers: headers, responseType: "arraybuffer" }
      )
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: "octet/stream" });
        let fileName = "imagenes.zip";
        saveAs(blob, fileName);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error: ", e);
      });
  };

  const renderStatusDetail = (status_detail, index) => {
    // Caso sin conductor
    if (status_detail.split(" - ").length < 3) {
      return (
        <p id="status-detail-test" key={`status_detail-${index}`}>
          {
            status_detail.split(" - ")[1] 
            ?
            strDateFormatWithTime(status_detail.split(" - ")[0]) +
            " - " +
            status_detail.split(" - ")[1]
            :
            strDateFormatWithTime(status_detail.split(" - ")[0])
          }
        </p>
      );
    } else {
      // Caso con conductor
      return (
        <p id="status-detail-test" key={`status_detail-${index}`}>
          {strDateFormatWithTime(status_detail.split(" - ")[0]) +
            (!isSeller(props.current_user) &&
            !isSellerOperator(props.current_user)
              ? " - " + status_detail.split(" - ")[1]
              : "") +
            " - " +
            status_detail.split(" - ")[2]}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.columnName == "Acciones") {
            return (
              <TableCell key={`action-${index}`} className="actions-cell">
                <div className="actions-container">
                  <Tooltip title={openCollapseTooltip} placement="top">
                    <IconButton
                      color="primary"
                      aria-label="open request"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      {openCollapseIcon}
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                component="th"
                scope="row"
                key={`${column.columnName}-${index}`}
                className={
                  column.columnName === "Estado"
                    ? `status-${request[column.dbName]}`
                    : ""
                }
              >
                {
                  column.dbName.split("/").length === 2 ? 
                  request[column.dbName.split("/")[0]][column.dbName.split("/")[1]]
                  : 
                  request[column.dbName] == null ? 
                  ""
                  : 
                  column.columnName === "Estado" ? 
                  <p>{mapStatus(request[column.dbName])}</p>
                  : 
                  column.columnName === "Fecha de retiro" ? 
                  formattedDateDDMMYYYY(request[column.dbName])
                  :
                  request[column.dbName]
                }
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="my-request-collapse"
          >
            <Box>
              <Grid container className="my-request-container">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={8} className="sr-detail-container">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-r-10"
                        >
                          <p className="fw-medium super-small-text">
                            Detalle del estado
                          </p>
                          {
                            request["sr_status_detail"].map((status_detail, index) => {
                              return renderStatusDetail(status_detail, index);
                            })
                          }
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-l-10"
                        >
                          <p className="fw-medium super-small-text">
                            Comentarios
                          </p>
                          <p>{request["sr_comments"]}</p>
                          {
                            lastSrResponse && request['aasm_state'] === "finished" && <p>
                              { `${lastSrResponse.completed_receiver_name} - ${lastSrResponse.completed_receiver_rut}` }
                            </p>
                          }
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-r-10"
                        >
                          <p className="fw-medium super-small-text">
                            Teléfono de contacto
                          </p>
                          <p>{request["sr_contact_phone"]}</p>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-l-10"
                        >
                          <p className="fw-medium super-small-text">
                            Id de referencia
                          </p>
                          <p>{request["reference_id"]}</p>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-r-10"
                        >
                          <p className="fw-medium super-small-text">
                            Correo de contacto
                          </p>
                          <p>{request["sr_contact_email"]}</p>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="sr-detail-element pd-r-10"
                        >
                          <p className="fw-medium super-small-text">
                            Bultos
                          </p>
                          <p>{request["packages"]}</p>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} className="sr-photos-container">
                      <Grid container>
                        <Grid item xs={12} sm={12} className="sr-photos-header">
                          <p className="fw-medium super-small-text">
                            Prueba de foto
                          </p>
                          {
                            loading ? 
                            <CircularProgress className="loading-btn" />
                            : 
                            request['sr_photos'] && 
                            request['sr_photos'].length > 0 ? (
                              <a
                                onClick={() => downloadPhotos()}
                                className="super-small-text"
                                download
                              >
                                <i className="material-icons">download</i>{" "}
                                Descargar fotos
                              </a>
                            ) 
                            : 
                            null
                          }
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className="sr-photos-content"
                        >
                          <Grid container>
                            {
                              request['sr_photos'] &&
                              request['sr_photos'].map((file, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm
                                    key={`sr-file-${request["order_number"]}-${index}`}
                                  >
                                    <img src={file} alt="Logo Go-EX" />
                                  </Grid>
                                );
                              })
                            }
                            
                            {
                              request["files_urls"] !== null &&
                              request["files_urls"].map((photo, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm
                                    key={`sr_image-${request["order_number"]}-${index}`}
                                  >
                                    <img src={photo} alt="Logo Go-EX" />
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default MyRequestRow;
