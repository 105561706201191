import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import CustomDropzone from "../site/CustomDropzone";

import { flash_alert } from "components/App";
import { headersFiles } from "../../constants/csrf";
import { fetchFileNames } from "../../utils/functions";

const LoadOptionalSkillsDialog = ({ open, setOpen }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setFiles((files) =>
        !fetchFileNames(files).includes(file.name) ? [...files, file] : files
      );
    });
  }, []);

  const handleSubmit = () => {
    files.map((file, index) => {
      const body = new FormData();
      body.append("file", file);
      axios.post(`/api/v1/requests/load_optional_skills`, body, {
          headers: headersFiles,
        })
        .then((response) => {
          setLoading(false);
          setFiles([]);
          setOpen(false);
          console.log(response);
          flash_alert(
            "Éxito",
            response.data.message,
            "success"
          );
        })
        .catch((e) => {
          console.log(e.response);
          flash_alert(
            "Error",
            "Error al cargar las habilidades",
            "danger"
          )
        });
    });
  }

  const handleDelete = (path) => {
    setFiles(files.filter((file) => file.path !== path));
  }

  return (
    <Dialog open={open}>
      <Grid container>
        <DialogTitle>Cargar habilidades</DialogTitle>
        <Grid item xs={12} className="flex-center">
          <CustomDropzone
            accept={
              "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            width={"550px"}
            marginTop={"41px"}
            onDrop={onDrop}
            name="file"
          />
        </Grid>
        <Grid item xs={12} className="flex-center">
          <Grid item className="custom-stack-container">
            <Stack direction="row" spacing={1} className="custom-stack">
              {files.map((file, index) => {
                return (
                  <Chip
                    key={index}
                    label={file.path}
                    onDelete={() => handleDelete(file.path)}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <DialogActions>
          <Grid item xs={12} sm={12} className="flex-center">
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setOpen(false)}
              className="mg-r-10"
            >
              Volver atrás
            </Button>

            <Button
              type="submit"
              variant="contained"
              onClick={ handleSubmit }
              disabled={ files.length < 1 }
            >
              {
                loading ? 
                <CircularProgress className="loading-btn" />
                : 
                "Cargar archivo"
              }
            </Button>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

export default LoadOptionalSkillsDialog;