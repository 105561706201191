import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { flash_alert } from "components/App";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
} from "@mui/material";

import InputMask from "react-input-mask";
import { isSuperAdmin, isRouter } from "../../constants/user_functions";
import { validate, format } from "rut.js";
import * as EmailValidator from "email-validator";
import { roles } from "../../constants/data";
import { headers } from "../../constants/csrf";

function AdminProfile(props) {
  let current_user = props.current_user;
  const [fullname, setFullname] = useState(current_user.fullname);
  const [socialReason, setSocialReason] = useState(current_user.social_reason);
  const [rut, setRut] = useState(current_user.rut);
  const [role, setRole] = useState(current_user.roles_names);
  const [email, setEmail] = useState(current_user.email);
  const [password, setPassword] = useState("");
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitPressed(true);
    if (
      fullname != "" &&
      socialReason != "" &&
      rut != "" &&
      validate(rut) &&
      role != "" &&
      email != "" &&
      EmailValidator.validate(email) &&
      password != "" &&
      password.length >= 8
    ) {
      setLoading(true);

      var body = new FormData();
      body.set("fullname", fullname);
      body.set("social_reason", socialReason?.trim());
      body.set("rut", rut);
      body.set("role_name", role);
      body.set("email", email);
      body.set("password", password);
      await axios
        .put(`/api/v1/users/${props.current_user.id}/edit`, body, {
          headers: headers,
        })
        .then(() => {
          setLoading(false);
          window.location.reload();
          flash_alert(
            "Actualizado!",
            "El usuario se ha actualizado correctamente",
            "success"
          );
        })
        .catch((e) => {
          setLoading(false);
          flash_alert("Error", "Error al actualizar el usuario", "danger");
        });
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Mi perfil</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/home" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <form className="flex-center" onSubmit={handleSubmit} autoComplete="off">
        <Grid container className="custom-form-container">
          <Grid item xs={12} sm={12}>
            <p className="red-text small-text flex-right mg-0">
              *Campos obligatorios
            </p>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && fullname === ""}
              variant="outlined"
              label="Nombre completo*"
              name="fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              helperText={
                submitPressed && fullname == "" ? "Campo obligatorio" : ""
              }
              className="first-row-left"
            />
          </Grid>
          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              error={submitPressed && socialReason === ""}
              variant="outlined"
              label="Razón Social*"
              name="socialReason"
              value={socialReason}
              onChange={(e) => setSocialReason(e.target.value)}
              helperText={
                submitPressed && socialReason == "" ? "Campo obligatorio" : ""
              }
              className="first-row-right"
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && (!validate(rut) || rut === "")}
              variant="outlined"
              label="RUT*"
              name="rut"
              value={rut}
              onChange={(e) => setRut(format(e.target.value))}
              helperText={submitPressed && rut == "" ? "Campo obligatorio" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6} className="flex-left pd-l-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && role == ""}
            >
              <InputLabel htmlFor="select-role">Selecciona tu rol*</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                input={<OutlinedInput id="select-role" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {isSuperAdmin(props.current_user)
                  ? roles.slice(0, 4).map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))
                  : isRouter(props.current_user)
                  ? roles.slice(2, 4).map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))
                  : roles.slice(1, 4).map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
              </Select>
              {submitPressed && role == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={
                (submitPressed &&
                  (!EmailValidator.validate(email) || email === "")) ||
                (email !== "" && !EmailValidator.validate(email))
              }
              variant="outlined"
              label="Email*"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={
                submitPressed && email == ""
                  ? "Campo obligatorio"
                  : email != "" && !EmailValidator.validate(email)
                  ? "Formato incorrecto"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && (password == "" || password.length < 8)}
            >
              <InputLabel htmlFor="outlined-password">
                Ingresa tu contraseña*
              </InputLabel>
              <OutlinedInput
                id="outlined-password"
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
              />
              {submitPressed && password == "" ? (
                <FormHelperText>Campo obligatorio</FormHelperText>
              ) : (
                submitPressed &&
                password.length < 8 && (
                  <FormHelperText>
                    Contraseña debe tener mínimo 8 carácteres
                  </FormHelperText>
                )
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={loading}
              id="user-save"
              type="submit"
              variant="contained"
              onClick={() => handleSubmit}
              className="flex-left"
            >
              {loading ? (
                <CircularProgress className="loading-btn" />
              ) : (
                "Actualizar datos"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(AdminProfile);
