import React from 'react';

import Skeleton from '@mui/material/Skeleton';

const TableLoader = () => {
  return (
    <React.Fragment>
      <Skeleton 
        variant="text" 
        width="100%"
        height={35}
      />
      <Skeleton 
        variant="text" 
        width="100%"
        height={35}
      />
      <Skeleton 
        variant="text" 
        width="100%"
        height={35}
      />
    </React.Fragment>
  )
}

export default TableLoader;