import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import UsersActive from "components/users/UsersActive";

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
  Switch,
} from "@mui/material";

import CustomDataGrid from "../table/CustomDataGrid";
import CompanyForm from 'components/companies/CompanyForm';

import { flash_alert } from "components/App";
import { validate } from "rut.js";
import { headers } from "../../constants/csrf";
import { buildCompanyFormData, getWithdrawalAddressesArray } from "helpers/requestHelpers";

const EditUserSeller = (props) => {
  const sellerId = props.match.params.id;

  const [company, setCompany] = useState({
    business_name: '',
    rut: '',
    region: '',
    commune: '',
    street: '',
    street_number: '',
    street_other: '',
    ecommerce: '',
  });
  const [addresses, setAddresses] = useState([]);

  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const changeAttribute = (attribute, newValue) => {
    setCompany({...company, [attribute]: newValue});
  }

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      await axios
        .get(`/api/v1/companies/from_seller/${sellerId}`)
        .then(({ data }) => {

          const companyData = {
            business_name: data.business_name || '',
            rut: data.rut || '',
            region: data.region || '',
            commune: data.commune || '',
            street: data.street || '',
            street_number: data.street_number || '',
            street_other: data.street_other || '',
            ecommerce: data.ecommerce || '',
            withdrawal_hour: data.withdrawal_hour,
            withdrawal_hour_2: data.withdrawal_hour_2,
            can_manage_mailing: false,
            id: data.id
          }
          setCompany(data);

          setAddresses(getWithdrawalAddressesArray(data.withdrawal_addresses));

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          flash_alert("Error", "Hubo un error al obtener el usuario", "danger");
        });
    };

    fetchCompany();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitPressed(true);
    setLoading(true);
    if (
      company.region !== '' &&
      company.commune !== '' &&
      company.street !== '' &&
      company.street_number !== '' &&
      company.ecommerce !== '' 
    ) {

      const companyData = buildCompanyFormData(company, addresses);

      return axios
        .put(`/api/v1/companies/${company.id}`, companyData, { headers: headers })
        .then((response) => {
          setLoading(false);
          setRedirect(true);
          flash_alert(
            "Éxito",
            "La compañía a sido editada con éxito",
            "success"
          )
        })
        .catch(({ response }) => {
          setLoading(false);
          console.log(response.data);
          response.data.map(error => (
            flash_alert(
              "Hubo un error al editar la compañía",
              error,
              "danger"
            )
          ))
        });
    }
    else {
      setLoading(false);
      flash_alert(
        "Error",
        "Completa los campos obligatorios",
        "danger"
      );
    }
  }

  const changeAddressAttribute = (attribute, newValue, addressIndex) => {
    const editedAddress = {...addresses[addressIndex], [attribute]: newValue}
    switch(addressIndex) {
      case 0:
        setAddresses([editedAddress, addresses[1], addresses[2]]);
        break;
      case 1:
        setAddresses([addresses[0], editedAddress, addresses[2]]);
        break;
      case 2:
        setAddresses([addresses[0], addresses[1], editedAddress]);
        break;
    }
  }

  return (
    <Grid container xs={12} className="signup-container">
      {
        redirect && 
          <Redirect 
            key="redirect-to-active-users" 
            to="/users/active"
          />
      }
      
      <Grid item xs={12}>
        <h1>Editar Compañía</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/users/active" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Link to={`/users/edit/${company.id}/seller/load_kind_prices`}>
          <Button variant="contained">
            Cargar Precios según Zona de Envío y Tipo de Orden
          </Button>
        </Link>
      </Grid>
        
      <CompanyForm
        company={ company }
        addresses={ addresses }
        handleSubmit={ handleSubmit }
        changeAttribute={ changeAttribute }
        changeAddressAttribute={ changeAddressAttribute }
        submitPressed={ submitPressed }
        loading={ loading }
        isEditing={ true }
      />
    </Grid>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(EditUserSeller);
