import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { flash_alert } from "components/App";

import {
  AppBar,
  Box,
  Button,
  Collapse,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import logo from "../../../assets/images/logo-goex.svg";

import {
  updatePendingIncidences,
  updatePendingCases,
} from "../../stores/functions";
import { headers } from "../../constants/csrf";
import {
  isSuperAdmin,
  isAdmin,
  isRouter,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";
import RequestWithdrawalDialog from "../withdrawalAddresses/RequestWithdrawalDialog";

function CustomLayout(props) {
  const dispatch = useDispatch();
  const { children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const [controlPanelOpen, setControlPanelOpen] = useState(false);
  const [requestsOpen, setRequestsOpen] = useState(false);
  const [configurationsOpen, setConfigurationsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(false);
  const [withdrawalOpen, setWithdrawalOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickUsers = () => {
    setUsersOpen(!usersOpen);
  };

  const handleClickRequests = () => {
    setRequestsOpen(!requestsOpen);
  };

  const handleListItemClick = (_event, index) => {
    setSelectedIndex(index);
  };

  const fetchPendingUsers = async () => {
    await axios
      .get("/api/v1/users/pending", {}, { headers: headers })
      .then((response) => {
        setPendingUsers(response.data);
      })
      .catch((e) => {
        flash_alert("Error", "Hubo un error al obtener los usuarios", "danger");
      });
  };

  const fetchPendingIncidences = async () => {
    await axios
      .get("/api/v1/incidences", {}, { headers: headers })
      .then((response) => {
        dispatch(
          updatePendingIncidences(
            response.data.filter(function (incidence) {
              return (
                incidence.aasm_state === "failed" ||
                incidence.aasm_state === "pending"
              );
            })
          )
        );
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const fetchPendingCases = async () => {
    await axios
      .get("/api/v1/customer_supports", {}, { headers: headers })
      .then((response) => {
        dispatch(
          updatePendingCases(
            response.data.filter(function (item) {
              return item.aasm_state !== "completed";
            })
          )
        );
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  useEffect(() => {
    fetchPendingUsers();
    fetchPendingIncidences();
    fetchPendingCases();
  }, []);

  function handleCloseSession() {
    return fetch("/users/sign_out", { headers: headers, method: "DELETE" })
      .then((response) => {
        window.location = "";
      })
      .catch((e) => {});
  }

  const drawer = (
    <div className="container-drawer">
      <div className="drawer-logo">
        <img src={logo} alt="Logo Go-EX" />
      </div>
      <div className="container-user-info">
        <p className="flex-left fw-medium">{props.current_user.fullname}</p>
        <p className="flex-left fw-light">{props.current_user.roles_names}</p>
      </div>

      {(isSeller(props.current_user) ||
        isSellerOperator(props.current_user)) && (
        <div className="drawer-buttons-container">
          <Link
            to={"/requests/new/type"}
            className="upload-requests"
            id={"link-to-new-request"}
          >
            <Button variant="contained">
              <i className="material-icons-outlined">file_upload</i>
              <p>Carga tus solicitudes</p>
            </Button>
          </Link>
          <div className="request-withdrawal">
            <Button
              disabled={loadingAddresses}
              variant="contained"
              onClick={() => setWithdrawalOpen(true)}
            >
              {loadingAddresses ? (
                <>
                  <CircularProgress className="loading-white" />
                  <p className="loading">Cargando direcciones</p>
                </>
              ) : (
                <>
                  <i className="material-icons">local_shipping</i>{" "}
                  <p>Solicita un retiro</p>
                </>
              )}
            </Button>
          </div>
        </div>
      )}

      <div className="drawer-body-container">
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="custom-list"
        >
          <Link to={"/home"} id={"link-to-home"}>
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <i className="material-icons-outlined">home</i>
              </ListItemIcon>
              <ListItemText primary={"Inicio"} />
            </ListItem>
          </Link>

          {(isSuperAdmin(props.current_user) ||
            isAdmin(props.current_user)) && (
            <>
              <ListItem
                button
                onClick={(event) => {
                  handleListItemClick(event, 1);
                  handleClickUsers();
                }}
                id="users-collapse-button"
                selected={selectedIndex === 1}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">people_alt</i>
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
                {usersOpen ? (
                  <i className="material-icons-outlined">expand_less</i>
                ) : (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              </ListItem>

              <Collapse in={usersOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <Link to={"/users/active"} id={"link-to-users-active"}>
                    <ListItem
                      button
                      selected={selectedIndex === 2}
                      onClick={(event) => {
                        handleListItemClick(event, 2);
                      }}
                    >
                      <ListItemText primary="Usuarios Activos" />
                    </ListItem>
                  </Link>
                  <Link to={"/users/pending"} id={"link-to-users-pending"}>
                    <ListItem
                      button
                      selected={selectedIndex === 3}
                      onClick={(event) => {
                        handleListItemClick(event, 3);
                      }}
                    >
                      <ListItemText>
                        <div className="badge-container">
                          <p className="mg-0">Clientes por aprobar</p>
                          {pendingUsers.length > 0 && (
                            <p className="mg-0 small-text custom-badge">
                              {pendingUsers.length}
                            </p>
                          )}
                        </div>
                      </ListItemText>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </>
          )}

          {(isRouter(props.current_user) ||
            isSuperAdmin(props.current_user) ||
            isAdmin(props.current_user)) && (
            <>
              <Link to={"/control_panel"} id={"link-to-control-panel"}>
                <ListItem
                  button
                  onClick={(event) => {
                    handleListItemClick(event, 9);
                    setControlPanelOpen(!controlPanelOpen);
                  }}
                  id="control-panel-collapse-button"
                  selected={selectedIndex === 9}
                >
                  <ListItemIcon>
                    <i className="material-icons-outlined">assignment</i>
                  </ListItemIcon>
                  <ListItemText primary="Panel de control" />
                  {controlPanelOpen ? (
                    <i className="material-icons-outlined">expand_less</i>
                  ) : (
                    <i className="material-icons-outlined">expand_more</i>
                  )}
                </ListItem>
              </Link>
              <Collapse in={controlPanelOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <Link
                    to={"/control_panel/devolutions"}
                    id={"link-to-devolutions"}
                  >
                    <ListItem
                      button
                      selected={selectedIndex === 10}
                      onClick={(event) => {
                        handleListItemClick(event, 10);
                      }}
                    >
                      <ListItemText primary="Devoluciones" />
                    </ListItem>
                  </Link>
                  <Link
                    to={"/control_panel/withdrawals"}
                    id={"link-to-withdrawal"}
                  >
                    <ListItem
                      button
                      selected={selectedIndex === 11}
                      onClick={(event) => {
                        handleListItemClick(event, 11);
                      }}
                    >
                      <ListItemText primary="Retiros" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </>
          )}

          {(isSeller(props.current_user) ||
            isSellerOperator(props.current_user)) && (
            <>
              <Link to={"/requests"} id={"link-to-requests"}>
                <ListItem
                  button
                  onClick={(event) => {
                    handleListItemClick(event, 4);
                    handleClickRequests();
                  }}
                  selected={selectedIndex === 4}
                >
                  <ListItemIcon>
                    <i className="material-icons-outlined">email</i>
                  </ListItemIcon>
                  <ListItemText primary="Mis solicitudes" />
                  {requestsOpen ? (
                    <i className="material-icons-outlined">expand_less</i>
                  ) : (
                    <i className="material-icons-outlined">expand_more</i>
                  )}
                </ListItem>
              </Link>
              <Collapse in={requestsOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <Link
                    to={"/requests/pending"}
                    id={"link-to-requests-pending"}
                  >
                    <ListItem
                      button
                      selected={selectedIndex === 5}
                      onClick={(event) => {
                        handleListItemClick(event, 5);
                      }}
                    >
                      <ListItemText primary="Solicitudes pendientes" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </>
          )}

          {(isSuperAdmin(props.current_user) ||
            isAdmin(props.current_user) ||
            isRouter(props.current_user)) && (
            <>
              <ListItem
                button
                onClick={(event) => {
                  handleListItemClick(event, 4);
                  handleClickRequests();
                }}
                id="requests-collapse-button"
                selected={selectedIndex === 4}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">email</i>
                </ListItemIcon>
                <ListItemText primary="Solicitudes" />
                {requestsOpen ? (
                  <i className="material-icons-outlined">expand_less</i>
                ) : (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              </ListItem>
              <Collapse in={requestsOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <Link
                    to={"/requests/for_routing"}
                    id={"link-to-request-for-routing"}
                  >
                    <ListItem
                      button
                      selected={selectedIndex === 5}
                      onClick={(event) => {
                        handleListItemClick(event, 5);
                      }}
                    >
                      <ListItemText primary="Solicitudes por rutear" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </>
          )}

          {(isSeller(props.current_user) ||
            isSellerOperator(props.current_user)) && (
            <Link to={"/incidences"} id={"link-to-incidences"}>
              <ListItem
                button
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">inventory</i>
                </ListItemIcon>
                <ListItemText>
                  <div className="badge-container">
                    <p className="mg-0">Gestión de incidencias</p>
                    {props.incidencesLength > 0 && (
                      <p className="mg-0 small-text custom-badge">
                        {props.incidencesLength}
                      </p>
                    )}
                  </div>
                </ListItemText>
              </ListItem>
            </Link>
          )}

          {(isSeller(props.current_user) ||
            isSellerOperator(props.current_user) ||
            isSuperAdmin(props.current_user) ||
            isAdmin(props.current_user)) && (
            <Link to={"/customer_supports"} id={"link-to-customer-support"}>
              <ListItem
                button
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">forum</i>
                </ListItemIcon>
                <ListItemText>
                  <div className="badge-container">
                    <p className="mg-0">Atención al cliente</p>
                    {(isSuperAdmin(props.current_user) ||
                      isAdmin(props.current_user)) &&
                      props.casesLength > 0 && (
                        <p className="mg-0 small-text custom-badge">
                          {props.casesLength}
                        </p>
                      )}
                  </div>
                </ListItemText>
              </ListItem>
            </Link>
          )}

          <Link
            to={
              isSuperAdmin(props.current_user) ||
              isAdmin(props.current_user) ||
              isRouter(props.current_user)
                ? "/users/admin_profile"
                : "/users/profile"
            }
            id={"link-to-profile"}
          >
            <ListItem
              button
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
            >
              <ListItemIcon>
                <i className="material-icons-outlined">person</i>
              </ListItemIcon>
              <ListItemText primary={"Mi perfil"} />
            </ListItem>
          </Link>

          {(isSuperAdmin(props.current_user) ||
            isAdmin(props.current_user) ||
            isSeller(props.current_user)) && (
            <>
              <ListItem
                button
                onClick={(event) => {
                  handleListItemClick(event, 12);
                  setConfigurationsOpen(!configurationsOpen);
                }}
                id="configurations-collapse-button"
                selected={selectedIndex === 12}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">settings</i>
                </ListItemIcon>
                <ListItemText primary="Configuraciones" />
                {configurationsOpen ? (
                  <i className="material-icons-outlined">expand_less</i>
                ) : (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              </ListItem>
              <Collapse in={configurationsOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  {(isSuperAdmin(props.current_user) ||
                    isAdmin(props.current_user)) && (
                    <Link to={"/blogs"} id={"link-to-blog-configuration"}>
                      <ListItem
                        button
                        selected={selectedIndex === 13}
                        onClick={(event) => {
                          handleListItemClick(event, 13);
                        }}
                      >
                        <ListItemText primary="Blog" />
                      </ListItem>
                    </Link>
                  )}

                  {
                    (isSuperAdmin(props.current_user) ||
                    isAdmin(props.current_user) || props.current_user.company.can_manage_mailing)  &&
                    <Link to={"/mailings"} id={"link-to-mailing"}>
                      <ListItem
                        button
                        selected={selectedIndex === 14}
                        onClick={(event) => {
                          handleListItemClick(event, 14);
                        }}
                      >
                        <ListItemText primary="Mailing" />
                      </ListItem>
                    </Link>
                  }

                  <Link to={"/integrations"} id={"link-to-integrations"}>
                    <ListItem
                      button
                      selected={selectedIndex === 15}
                      onClick={(event) => {
                        handleListItemClick(event, 15);
                      }}
                    >
                      <ListItemText primary="Integraciones" />
                    </ListItem>
                  </Link>

                  {
                    (isSuperAdmin(props.current_user) || isAdmin(props.current_user)) &&
                    <Link to={"/delivery_zones"} id={"link-to-delivery-zones"}>
                      <ListItem
                        button
                        selected={selectedIndex === 16}
                        onClick={(event) => {
                          handleListItemClick(event, 16);
                        }}
                      >
                        <ListItemText primary="Zonas de envío" />
                      </ListItem>
                    </Link>
                  }
                </List>
              </Collapse>
            </>
          )}
          {(isAdmin(props.current_user) ||
            isSuperAdmin(props.current_user)) && (
            <Link
              target={"_blank"}
              rel="noopener noreferrer"
              to={{
                pathname:
                  "https://oferus.atlassian.net/servicedesk/customer/portal/53",
              }}
              id={"link-to-servicedesk"}
            >
              <ListItem
                button
                selected={selectedIndex === 15}
                onClick={(event) => handleListItemClick(event, 15)}
              >
                <ListItemIcon>
                  <i className="material-icons-outlined">help</i>
                </ListItemIcon>
                <ListItemText primary={"Mesa de Ayuda"} />
              </ListItem>
            </Link>
          )}
        </List>
        <MuiLink
          onClick={() => handleCloseSession()}
          className="exit-container"
          id={"button-close-session"}
        >
          <i className="material-icons-outlined">exit_to_app</i>{" "}
          <p className="exit-btn">Cerrar sesión</p>
        </MuiLink>
      </div>
    </div>
  );

  const drawerWidth = 235;

  return (
    <>
      {
        loadingAddresses ? 
        null 
        : 
        <React.Fragment>
          {
            props.current_user.company &&
            <RequestWithdrawalDialog
              withdrawalOpen={withdrawalOpen}
              setWithdrawalOpen={setWithdrawalOpen}
              current_user={props.current_user}
            />
          }
        </React.Fragment>
      }
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className="mobile-drawer"
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            className="desktop-drawer"
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <AppBar position="fixed" className="custom-appbar">
          <Grid container>
            <Grid item xs={3} className="flex-center">
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                id={"button-open-menu"}
              >
                <i className="material-icons-outlined">menu</i>
              </IconButton>
            </Grid>
            <Grid item xs={6} className="flex-center">
              <div className="appbar-logo">
                <img src={logo} alt="Logo Go-EX" />
              </div>
            </Grid>
            <Grid item xs={3} className="flex-center"></Grid>
          </Grid>
        </AppBar>
        <Container className="app-container" maxWidth={false}>
          <main className="app-main">{children}</main>
        </Container>
      </Box>
    </>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
  incidencesLength: (state) => state.incidencesLength,
  casesLength: (state) => state.casesLength,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(CustomLayout)
);
