import React, { useState, useEffect } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import UsersActive from "../../components/users/UsersActive";
import { flash_alert } from "components/App";

import axios from "axios";
import * as EmailValidator from "email-validator";
import { format, validate } from "rut.js";
import InputMask from "react-input-mask";
import { headers } from "../../constants/csrf";
import { isSuperAdmin } from "../../constants/user_functions";

const AdminNewUser = (props) => {
  const goExCompany = {id: 0, business_name: 'Go-ex Spa'}

  const [fullname, setFullName] = useState("");
  const [socialReason, setSocialReason] = useState("");
  const [rut, setRut] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");

  const [companies, setCompanies] = useState([goExCompany]);
  const [selectedCompany, setSelectedCompany] = useState(goExCompany.id);
  const [rolesByCompany, setRolesByCompany] = useState([]);

  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [sellerCheck, setSellerCheck] = useState({ exists: false, id: null });
  const [checked, setChecked] = useState(false);

  const [user, setUser] = useState({
    fullname: '',
    email: '',
    phone_number: '',
    role_name:  '',
    company: goExCompany.id
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      const { data } = await axios.get('/api/v1/companies');
      setCompanies([goExCompany, ...data]);
    }

    fetchCompanies();
  }, []);

  useEffect(() => {
    const defineRolesToDisplay = () => {
      const internalRoles = [
        "Super administrador",
        "Administrador",
        "Ruteador",
      ]

      const externalRoles = [
        "Seller",
        "Seller operador"
      ]

      if (user.company === goExCompany.id) {
        return internalRoles;
      }
      else {
        return externalRoles;

      }
    }

    setRolesByCompany(defineRolesToDisplay());
  }, [user.company]);

  const handleSubmit = () => {
    setSubmitPressed(true);
    if (EmailValidator.validate(user.email)) {
      if (user.fullname !== "" &&
        user.role !== "" &&
        user.email !== "" &&
        user.phone_number !== ""
      ) {

        const body = new FormData();

        body.set("fullname", user.fullname);
        body.set("role_name", user.role);
        body.set("email", user.email);
        body.set("phone_number", user.phone_number);
        body.set("company_id", user.company);

        body.set("password", "goex1234");
        
        setLoading(true);

        return axios
          .post("/api/v1/users/admin_creation", body, { headers: headers })
          .then((response) => {
            setLoading(false);
            setRedirect(true);
            flash_alert(
              "Usuario creado",
              "El usuario ha sido creado con éxito",
              "success"
            );
          })
          .catch(({response}) => {
            console.log(response);
            setLoading(false);
            response.data.map(error => (
              flash_alert(
                "Hubo un error al crear el usuario",
                error,
                "danger"
              )
            ))
          });
      }
    }
    else {
      flash_alert("Error", "El email no tiene el formato correcto", "danger");
    }
  };

  const changeAttribute = (attribute, newValue) => {
    setUser({...user, [attribute]: newValue});
  }

  let redirect_check = [];
  if (redirect) {
    redirect_check.push(
      <Redirect key="redirect-to-users-active" to="/users/active">
        <UsersActive />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <Grid item xs={12}>
        <h1>Nuevo Usuario</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/users/active" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} className="flex-center new-user-subtitle">
        <h5>Ingresa los datos para crear un nuevo usuario</h5>
      </Grid>
      <Grid container className="custom-form-container">
        <Grid item xs={12} sm={12}>
          <p className="red-text small-text flex-right">*Campos obligatorios</p>
        </Grid>

        <Grid item xs={12} sm={6} className="flex-left">
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="select-role">Compañía*</InputLabel>
            <Select
              value={user.company}
              disabled={false}
              onChange={(e) => changeAttribute('company', e.target.value)}
              id="input-role"
              input={<OutlinedInput id="select-role" />}
              IconComponent={() => (
                <i className="material-icons-outlined">expand_more</i>
              )}
            >
              {
                companies.map((company, index) => (
                  <MenuItem key={index} value={company.id}>
                    {company.business_name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        <Divider />

        <Grid item xs={12} sm={6} className="pd-r-10">
          <TextField
            fullWidth
            error={submitPressed && user.fullname === ""}
            variant="outlined"
            label="Nombre completo*"
            name="fullname"
            value={user.fullname}
            id="input-fullname"
            onChange={(e) => changeAttribute('fullname', e.target.value)}
            helperText={
              submitPressed && fullname == "" ? "Campo obligatorio" : ""
            }
            className="first-row-left"
          />
        </Grid>

        <Grid item xs={12} sm={6} className="flex-left pd-l-10">
          <FormControl
            fullWidth
            variant="outlined"
            error={submitPressed && user.role == ""}
          >
            <InputLabel htmlFor="select-role">Selecciona tu rol*</InputLabel>
            <Select
              value={user.role}
              disabled={false}
              onChange={(e) => changeAttribute('role', e.target.value)}
              id="input-role"
              input={<OutlinedInput id="select-role" />}
              IconComponent={() => (
                <i className="material-icons-outlined">expand_more</i>
              )}
            >
              {
                rolesByCompany.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {role}
                  </MenuItem>
                ))
              }
            </Select>
            {
              submitPressed && 
              user.role == "" && 
              <FormHelperText>Campo obligatorio</FormHelperText>
            }
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} className="pd-r-10">
          <TextField
            fullWidth
            error={ submitPressed && user.email !== "" && !EmailValidator.validate(user.email)}
            variant="outlined"
            label="Email*"
            name="email"
            value={user.email}
            id="input-email"
            onChange={(e) => changeAttribute('email', e.target.value)}
            helperText={
              submitPressed && email == "" ? 
              "Campo obligatorio"
              : 
              email != "" && !EmailValidator.validate(email) ? 
              "Formato incorrecto"
              : 
              ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} className="pd-l-10">
          <InputMask
            mask="(+56) 9 9999 9999"
            value={user.phone_number}
            onChange={(e) => changeAttribute('phone_number', e.target.value)}
            maskChar=" "
          >
            {() => (
              <TextField
                fullWidth
                error={submitPressed && user.phone_number == ""}
                variant="outlined"
                label="Teléfono*"
                name="phoneNumber"
                helperText={
                  submitPressed && user.phone_number == "" ? 
                  "Campo obligatorio"
                  : 
                  ""
                }
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={12} sm={6} className="">
          <TextField
            fullWidth
            variant="outlined"
            label="Contraseña"
            disabled={true}
            value={"goex1234"}
            className="admn-disabled-password"
          />
        </Grid>
        
        <Grid container>
          <Grid item xs={6} sm={3} className="flex-left ">
            <Link to="/users/active">
              <Button variant="outlined">Volver atrás</Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={9} className="flex-left ">
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              id="create-new-user-btn"
            >
              {
                loading ? 
                <CircularProgress className="loading-btn" />
                : 
                "Crear nuevo usuario"
              }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(AdminNewUser)
);
