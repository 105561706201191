import React, { useState } from 'react';
import axios from "axios";

import {
  Button,
  CircularProgress,
  TextField
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { es } from "date-fns/locale";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { formattedDateForDB } from "../../utils/timeFormat";

const ExportToRouter = ({ disabled, selectedRequests, routerName }) => {
  const [showForm, setShowForm] = useState(false);
  const [plannedDate, setPlannedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const handleExportToRouter = async () => {
    if (selectedRequests.length > 0) {
      setLoading(true);
      const body = new FormData();
      body.set("request_ids", selectedRequests.map((req) => req.id));
      body.set("planned_date", formattedDateForDB(plannedDate));
      body.set("router_name", routerName);

      await axios
        .post("/api/v1/requests/create_visits_on_router", body, { headers: headers })
        .then((response) => {
          setLoading(false);
          flash_alert("Éxito", "Solicitudes ruteadas con éxito!", "success");
          location.reload();
        })
        .catch(({response}) => {
          setLoading(false);
          response.data.map(error => (
            flash_alert(
              `Error con la orden ${error.order_number}`,
              error.message,
              "danger"
            )
          ))
        });
    } 
    else {
      setLoading(false);
      flash_alert("Error", "No hay solicitudes para exportar", "danger");
    }
  }

  const handleExport = () => {
    switch(routerName) {
      case "Simpli Route":
        setShowForm(true);
        break;
      case "Recíbelo":
        setLoading(true);
        handleExportToRouter();
        break;
      default:
        flash_alert(
          `Error al exportar al ruteador`,
          `No se reconoce el ruteador ${routerName}`,
          "danger"
        );
        break;

    }
  }

  return (
    <React.Fragment>
      {
        showForm ?
        <div className="update-service-type-form">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DatePicker
              label="Fecha planificada de entrega"
              value={plannedDate}
              onChange={(newValue) => setPlannedDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={handleExportToRouter}
          >
            {
              loading ? 
              <CircularProgress className="loading-btn" />
              : 
              'Exportar'
            }
          </Button>
        </div>
        :
        <Button
          disabled={disabled}
          variant="contained"
          className={ `mg-t-40 ${disabled && "button-disabled"}` }
          onClick={handleExport}
        >
          {
            loading ? 
            <CircularProgress className="loading-btn" />
            : 
            `Exportar a ${routerName}`
          }
        </Button>
      }
    </React.Fragment>
  );
}

export default ExportToRouter;