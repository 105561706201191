import React, { useEffect, useState } from 'react';
import axios from "axios";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";

const ComunaDeliveryZoneRow = ({comuna, columns, color, availableDeliveryZones}) => {
  const [comunaUpdate, setComunaUpdate] = useState({
    name: comuna.name,
    delivery_zone_id: comuna.delivery_zone_id ? comuna.delivery_zone_id : '' 
  });

  const handleDeliveryZoneChange = (e) => {
    const newDeliveryZoneId = e.target.value;
    const comunaData = new FormData();
    comunaData.set("comuna[delivery_zone_id]", newDeliveryZoneId);

    return axios.put(`/api/v1/comunas/${comuna.id}`, comunaData, { headers: headers })
                .then((response) => {
                  setComunaUpdate({...comunaUpdate, delivery_zone_id: newDeliveryZoneId})
                  flash_alert(
                    "Éxito",
                    "La zona de envío se asignó con éxito",
                    "success"
                  );
                })
  }

  return (
    <TableRow
      sx={{ "& > *": { borderBottom: "unset" } }}
      style={{ backgroundColor: color }}
      key={comuna.id}
    >
      {
        columns.map((column, index) => {
          return (
            <TableCell
              component="th"
              scope="row"
              key={`${column.columnName}-${index}`}
            >
              { 
                column.dbName === 'delivery_zone' ?
                <FormControl fullWidth>
                  <InputLabel id={`delivery-zone-select-label-${comuna.id}`}>Zona de Envío</InputLabel>
                  <Select
                    labelId={`delivery-zone-select-label-${comuna.id}`}
                    value={comunaUpdate.delivery_zone_id}
                    label="Zona de Envío"
                    onChange={handleDeliveryZoneChange}
                  >
                    {
                      availableDeliveryZones.map((deliveryZone) => (
                        <MenuItem 
                          key={deliveryZone.id}
                          value={deliveryZone.id}
                        >
                          <i className="material-icons" style={{color: deliveryZone.colour}}>circle</i>
                          { deliveryZone.name }
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                :
                comuna[column.dbName] 
              }
            </TableCell>
          );
        })
      }
    </TableRow>
  );
}

export default ComunaDeliveryZoneRow;