import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import usePlacesAutocomplete from "use-places-autocomplete";
import axios from "axios";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { mapStatus } from "../../utils/functions";
import { removeOpenIncidence, addOpenIncidence } from "../../stores/functions";
import { OpenStreetMapProvider } from "leaflet-geosearch";

import logo from "../../../assets/images/logo-goex.svg";
import { saveAs } from "file-saver";

function IncidenceRow(props) {
  const {
    incidence,
    isOpen,
    columns,
    color,
    openCollapseButton,
    editable,
    openCollapseTooltip,
    openCollapseIcon,
    completeButton,
    completeTooltip,
    completeIcon,
    deleteButton,
    deleteTooltip,
    deleteIcon,
    fetchIncidences,
  } = props;
  // Edit incidence
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [reEnterPressed, setReEnterPressed] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);

  // Edit request
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const {
    ready,
    suggestions: { loading, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ["CL"],
      },
    },
    debounce: 300,
  });

  let typingTimer;
  let doneTypingInterval = 500;

  const osmProvider = new OpenStreetMapProvider({
    params: {
      "accept-language": "cl",
      countrycodes: "cl",
    },
  });

  const searchAddress = async () => {
    setLoadingResults(true);
    if (
      process.env.NODE_ENV !== undefined &&
      process.env.NODE_ENV === "production" &&
      window.location.hostname === "app.go-ex.io"
    ) {
      setValue(address);
    } else {
      let results = await osmProvider.search({ query: address });
      setSearchResults(
        results.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.label === value.label)
        )
      );
      setLoadingResults(false);
    }
  };

  const handleCompleteIncidence = async () => {
    setLoadingApi(true);
    await axios
      .put(
        `/api/v1/incidences/${incidence["id"]}/complete`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        setIsEdit(false);
        fetchIncidences();
        flash_alert(
          "Éxito",
          "Se ha completado correctamente la incidencia",
          "success"
        );
      })
      .catch((e) => {
        setLoadingApi(false);
        setIsEdit(false);
        flash_alert(
          "Error",
          "Hubo un error al completar la incidencia",
          "danger"
        );
      });
  };

  const handleDeleteIncidence = async () => {
    setLoadingApi(true);
    await axios
      .put(
        `/api/v1/incidences/${incidence["request"]["id"]}/disable`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        setIsEdit(false);
        fetchIncidences();
        flash_alert(
          "Éxito",
          "Se ha eliminado correctamente la incidencia",
          "success"
        );
      })
      .catch((e) => {
        setLoadingApi(false);
        setIsEdit(false);
        flash_alert(
          "Error",
          "Hubo un error al eliminar la incidencia",
          "danger"
        );
      });
  };

  const handleUpdateIncidence = async () => {
    setLoadingApi(true);
    const body = new FormData();
    body.set("address", address);
    body.set("contact_name", contactName);
    body.set("contact_phone", contactPhone);
    body.set("contact_email", contactEmail);
    body.set("notes", notes);
    body.set("re_enter", reEnterPressed ? "true" : "false");

    let axiosRequests = [];
    axiosRequests.push(
      axios.put(`/api/v1/requests/${incidence["request"]["id"]}`, body, {
        headers: headers,
      })
    );

    if (comment !== "") {
      const body2 = new FormData();
      body2.set("comment", comment);
      axiosRequests.push(
        axios.put(
          `/api/v1/incidences/${incidence["id"]}/add_comment`,
          body2,
          {
            headers: headers,
          }
        )
      );
    }

    if (reEnterPressed) {
      axiosRequests.push(
        axios.put(
          `/api/v1/incidences/${incidence["id"]}/return_to_cd`,
          {},
          { headers: headers }
        )
      );
    }

    await axios
      .all(axiosRequests)
      .then(() => {
        fetchIncidences();
        flash_alert(
          "Éxito",
          "Se ha actualizado correctamente la incidencia",
          "success"
        );
      })
      .catch((e) => {
        setLoadingApi(false);
        setIsEdit(false);
        flash_alert(
          "Error",
          "Hubo un error al actualizar la incidencia",
          "danger"
        );
      });
  };

  const handlePressEdit = () => {
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setReEnterPressed(false);
    setIsEdit(false);
  };

  const handleSendToCD = async () => {
    setLoadingApi(true);
    await axios
      .put(
        `/api/v1/incidences/${incidence["id"]}/return_to_cd`,
        {},
        { headers: headers }
      )
      .then(() => {
        fetchIncidences();
        flash_alert(
          "Éxito",
          "Se ha actualizado correctamente la incidencia",
          "success"
        );
      })
      .catch((e) => {
        setLoadingApi(false);
        flash_alert(
          "Error",
          "Hubo un error al actualizar la incidencia",
          "danger"
        );
      });
  };

  const handlePressReEnterRequest = () => {
    setReEnterPressed(true);
    setIsEdit(true);
  };

  useEffect(() => {
    setAddress(incidence["request"]["address"]);
    setContactName(incidence["request"]["contact_name"]);
    setContactPhone(incidence["request"]["contact_phone"]);
    setContactEmail(incidence["request"]["contact_email"]);
    setNotes(incidence["request"]["notes"]);
  }, [incidence]);

  useEffect(() => {
    setOpen(isOpen);
  }, []);

  useEffect(() => {
    setSearchResults(data);
    setLoadingResults(false);
  }, [data]);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.columnName == "Acciones") {
            return (
              <TableCell key={`action-${index}`} className="actions-cell">
                <div className="actions-container">
                  {openCollapseButton && (
                    <Tooltip title={openCollapseTooltip} placement="top">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          if (open) {
                            dispatch(
                              removeOpenIncidence(
                                incidence["request"]["order_number"]
                              )
                            );
                          } else {
                            dispatch(
                              addOpenIncidence(
                                incidence["request"]["order_number"]
                              )
                            );
                          }
                          setOpen(!open);
                        }}
                      >
                        {openCollapseIcon}
                      </IconButton>
                    </Tooltip>
                  )}

                  {completeButton && (
                    <Tooltip title={completeTooltip} placement="top">
                      <IconButton
                        onClick={() => handleCompleteIncidence()}
                        className="complete-incidence"
                      >
                        {completeIcon}
                      </IconButton>
                    </Tooltip>
                  )}

                  {deleteButton && (
                    <Tooltip title={deleteTooltip} placement="top">
                      <IconButton
                        onClick={() => handleDeleteIncidence()}
                        className="delete-incidence"
                      >
                        {deleteIcon}
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                component="th"
                scope="row"
                key={`${column.columnName}-${index}`}
              >
                {column.dbName.split("/").length === 2
                  ? incidence["request"][column.dbName.split("/")[0]][
                      column.dbName.split("/")[1]
                    ]
                  : incidence["request"][column.dbName] == null
                  ? ""
                  : column.columnName === "Estado"
                  ? mapStatus(incidence["request"]["aasm_state"])
                  : incidence["request"][column.dbName]}
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="my-request-collapse"
          >
            <Box>
              <Grid container className="my-request-container">
                <Grid item xs={12}>
                  {incidence["aasm_state"] === "failed" &&
                  incidence["request"]["aasm_state"] === "paused_for_plan" &&
                  !isEdit ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <p className="mg-0 paused-for-plan-red-text">
                          <i className="material-icons-outlined pd-r-10 small-text">
                            error
                          </i>
                          Esta solicitud cumplió su número máximo de viajes de
                          tu plan
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p>
                          Puedes reingresar la solicitud con un cargo según tu
                          plan, o puedes devolver tu envío al centro de
                          distribución.
                        </p>
                      </Grid>
                      <Grid item xs={12} className="flex-left">
                        <Button
                          disabled={loadingApi}
                          variant="outlined"
                          onClick={() => handleSendToCD()}
                        >
                          {"Devolver a CD"}
                        </Button>
                        <Button
                          disabled={loadingApi}
                          variant="contained"
                          onClick={() => handlePressReEnterRequest()}
                        >
                          {loadingApi ? (
                            <CircularProgress className="loading-btn" />
                          ) : (
                            "Reingresar solicitud"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <>
                        {editable && !isEdit && (
                          <Grid
                            item
                            xs={12}
                            className="flex-right incidence-edit-button"
                          >
                            <i className="material-icons">edit</i>
                            <a onClick={() => handlePressEdit()}>
                              Editar solicitud
                            </a>
                          </Grid>
                        )}

                        {!isEdit && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="sr-detail-element"
                          >
                            <p className="fw-medium super-small-text">
                              Historial
                            </p>
                            {incidence["request"]["sr_status_detail"].map(
                              (status_detail, index) => {
                                return (
                                  <p key={`status_detail-${index}`}>
                                    {status_detail}
                                  </p>
                                );
                              }
                            )}
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className="sr-detail-element pd-r-10"
                        >
                          {
                            isEdit ? 
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Dirección*"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              error={submitPressed && address === ""}
                              helperText={submitPressed && address == "" && "Campo obligatorio"}
                            />
                            : 
                            <>
                              <p className="fw-medium super-small-text">
                                Dirección
                              </p>
                              <p>{incidence["request"]["address"]}</p>
                            </>
                          }
                        </Grid>
                        {!isEdit && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="sr-detail-element pd-l-10 pd-r-10"
                          >
                            <p className="fw-medium super-small-text">
                              Id referencia
                            </p>
                            <p>{incidence["request"]["reference_id"]}</p>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={`sr-detail-element ${isEdit ? "pd-l-10" : ""} pd-r-10`}
                        >
                          {isEdit ? (
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Nombre de contacto"
                              value={contactName}
                              onChange={(e) => setContactName(e.target.value)}
                            />
                          ) : (
                            <>
                              <p className="fw-medium super-small-text">
                                Nombre de contacto
                              </p>
                              <p>{incidence["request"]["contact_name"]}</p>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={`sr-detail-element ${isEdit ? "pd-l-10" : ""} pd-r-10`}
                        >
                          {isEdit ? (
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Teléfono de contacto"
                              value={contactPhone}
                              onChange={(e) => setContactPhone(e.target.value)}
                            />
                          ) : (
                            <>
                              <p className="fw-medium super-small-text">
                                Teléfono de contacto
                              </p>
                              <p>{incidence["request"]["contact_phone"]}</p>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={`sr-detail-element ${isEdit ? "" : "pd-l-10"} pd-r-10`}
                        >
                          {isEdit ? (
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Email de contacto"
                              value={contactEmail}
                              onChange={(e) => setContactEmail(e.target.value)}
                            />
                          ) : (
                            <>
                              <p className="fw-medium super-small-text">
                                Email de contacto
                              </p>
                              <p>{incidence["request"]["contact_email"]}</p>
                            </>
                          )}
                        </Grid>
                        {!isEdit && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="sr-detail-element pd-l-10"
                          >
                            <p className="fw-medium super-small-text">Bultos</p>
                            <p>{incidence["request"]["packages"]}</p>
                          </Grid>
                        )}

                        {!isEdit && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="sr-detail-element pd-r-10"
                          >
                            <p className="fw-medium super-small-text">Peso</p>
                            <p>{incidence["request"]["weight"]}</p>
                          </Grid>
                        )}

                        {!isEdit && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="sr-detail-element pd-l-10 pd-r-10"
                          >
                            <p className="fw-medium super-small-text">
                              Medidas
                            </p>
                            <p>{incidence["request"]["measures"]}</p>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={`sr-detail-element ${isEdit ? "pd-r-10" : ""} pd-l-10`}
                        >
                          {isEdit ? (
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Notas"
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                            />
                          ) : (
                            <>
                              <p className="fw-medium super-small-text">
                                Notas
                              </p>
                              <p>{incidence["request"]["notes"]}</p>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12} className="sr-detail-element">
                          {isEdit ? (
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Comentario"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          ) : (
                            <>
                              <p className="fw-medium super-small-text">
                                Comentarios
                              </p>

                              {incidence["comments"].map((comment, index) => {
                                return (
                                  <p key={`comment-${index}`}>{comment}</p>
                                );
                              })}
                            </>
                          )}
                        </Grid>

                        {isEdit && (
                          <Grid item xs={12} className="flex-left">
                            <Button
                              disabled={loadingApi}
                              variant="outlined"
                              onClick={() => handleCancelEdit()}
                            >
                              {"Cancelar edición"}
                            </Button>
                            <Button
                              disabled={loadingApi}
                              variant="contained"
                              onClick={() => handleUpdateIncidence()}
                            >
                              {loadingApi ? (
                                <CircularProgress className="loading-btn" />
                              ) : (
                                "Actualizar datos"
                              )}
                            </Button>
                          </Grid>
                        )}
                      </>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default IncidenceRow;
