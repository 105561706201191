import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import CustomCircularProgress from "../graphs/CustomCircularProgress";
import { Box, Grid, OutlinedInput, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  isSuperAdmin,
  isAdmin,
  isRouter,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";

import CustomTable from "../table/CustomTable";
import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";

function Blogs(props) {
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    await axios
      .get("/api/v1/blogs", {}, { headers: headers })
      .then((response) => {
        setBlogs(response.data);
      })
      .catch((e) => {
        flash_alert(
          "Error",
          "Hubo un error al obtener los blogs",
          "danger"
        );
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  let headerTable = [
    { columnName: "Imagen", dbName: "image", enabled: true },
    { columnName: "Título", dbName: "title", enabled: true },
    { columnName: "URL asociada", dbName: "url", enabled: true },
    { columnName: "Ubicación", dbName: "ubication", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid container className="blog-container">
        <Grid item xs={6} sm={6} className="flex-left">
          <h1>Configuración blog</h1>
        </Grid>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={blogs}
            headerTable={headerTable}
            fetchBlogs={fetchBlogs}
            selected={"none"}
            setSelected={"none"}
            openCollapseButton={true}
            openCollapseTooltip={"Editar entrada"}
            openCollapseIcon={
              <i className="material-icons-outlined">edit</i>
            }
            collapseType={"blog-detail"}
            hasPagination={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Blogs)
);
