import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Chip,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
  Switch,
} from "@mui/material";

import MailingConfig from "components/configuration/MailingConfig";
import { flash_alert } from "components/App";
import { headers, headersFiles } from "../../constants/csrf";
import { updateMailingEdit } from "../../stores/functions"

function MailingRow(props) {
  const {
    current_user,
    mailing,
    columns,
    color,
    openCollapseButton,
    openCollapseTooltip,
    openCollapseIcon,
    fetchMailings,
  } = props;

  const dispatch = useDispatch();
  const [redirectEdit, setRedirectEdit] = useState(false);

  const handleUpdateEnabled = async () => {
    await axios
      .put(
        `/api/v1/mailings/${mailing["id"]}/switch_enabled`,
        {},
        { headers: headers }
      )
      .then((response) => {
        fetchMailings();
      })
      .catch((e) => {
        flash_alert("Error", "Hubo un error al obtener los mailings", "danger");
      });
  };

  let redirect_check = [];
  if (redirectEdit) {
    redirect_check.push(
      <Redirect key="redirect-to-mailing-edit" to="/mailings/edit">
        <MailingConfig />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    <Tooltip title={openCollapseTooltip} placement="top">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          dispatch(updateMailingEdit(mailing));
                          setRedirectEdit(true);
                        }}
                      >
                        {openCollapseIcon}
                        <p className="small-text mg-0 pd-l-10 purple-text">
                          Configurar mail
                        </p>
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                >
                  {column.dbName === "enabled" ? (
                    <Switch
                      checked={mailing[column.dbName] === "true"}
                      onChange={() => handleUpdateEnabled()}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  ) : mailing[column.dbName] == null ? (
                    ""
                  ) : (
                    mailing[column.dbName]
                  )}
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
    </React.Fragment>
  );
}

export default MailingRow;
