import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { IconButton, Switch, FormControl, Tooltip } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";
import CustomDataGrid from "../table/CustomDataGrid";

function UsersActive(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    await axios
      .get("/api/v1/users", {}, { headers: headers })
      .then((response) => {
        setLoading(false);
        setUsers(response.data);
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al obtener los usuarios", "danger");
      });
  };

  const handleAccessUser = async (permitted, email) => {
    let url = "";
    if (permitted == "enabled") {
      url = "/api/v1/users/disable_user";
    } else if (permitted == "disabled") {
      url = "/api/v1/users/enable_user";
    } else {
      return;
    }
    var body = new FormData();
    body.set("email", email);
    setLoading(true);
    return axios
      .put(url, body, { headers: headers })
      .then((response) => {
        fetchUsers();
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al obtener los usuarios", "danger");
      });
  };

  const columns = [
    { 
      field: "business_name",
      headerName: "Razón social",
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => `${params.row.company ? params.row.company.business_name : ''}`,
    },
    { field: "fullname", headerName: "Nombre", flex: 1, minWidth: 80 },
    { 
      field: "rut", 
      headerName: "Rut Empresa", 
      flex: 1, 
      minWidth: 80,
      valueGetter: (params) => `${params.row.company ? params.row.company.rut : ''}`,
    },
    { field: "roles_names", headerName: "Rol", flex: 1, minWidth: 80 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 80 },
    { 
      field: "company.ecommerce", 
      headerName: "E-commerce", 
      flex: 1, 
      minWidth: 80,
      valueGetter: (params) => `${params.row.company ? params.row.company.ecommerce : ''}`,
    },
    {
      field: "permitted",
      headerName: "Acceso",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.permitted == "enabled"}
            disabled={loading}
            onChange={() =>
              handleAccessUser(params.row.permitted, params.row.email)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let url = `/users/edit/${params.row.id}/`;
        if (
          ["Super administrador", "Administrador", "Ruteador"].includes(
            params.row.roles_names
          )
        ) {
          url = url + "goex";
        } 
        else {
          url = url + "seller";
        }
        return (
          <Link to={url}>
            <Tooltip title="Ver más" placement="top">
              <IconButton component="span">
                <i className="material-icons-outlined icon-primary-purple">
                  article
                </i>
              </IconButton>
            </Tooltip>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <React.Fragment>
      <Grid container className="users-active-container">
        <Grid item xs={6} sm={6} className="flex-left">
          <h1>Usuarios activos</h1>
        </Grid>
        <Grid item xs={6} sm={6} className="flex-right buttons-container-right">
          <Link to="/companies/new">
            <Button id="create-new-user" variant="outlined">Crear Compañía</Button>
          </Link>

          <Link to="/users/admin_new">
            <Button id="create-new-user" variant="outlined">Crear Usuario</Button>
          </Link>
        </Grid>
        <Grid item xs={12} className="flex-left">
          <FormControl>
            <Link to="/home" className="back-login-btn">
              <i className="material-icons-outlined forgotten-back-arrow">
                arrow_back
              </i>
              <p>Volver atrás</p>
            </Link>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={12} className="mg-t-40">
          <CustomDataGrid columns={columns} rows={users} loading={loading} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(UsersActive)
);
