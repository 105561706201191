import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { mapStatusCase } from "../../utils/functions";
import { casesStates } from "../../constants/data";
import {
  isSuperAdmin,
  isAdmin,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";
import { strDateFormat } from "../../utils/functions";
import { saveAs } from "file-saver";

function CaseRow(props) {
  const {
    current_user,
    caseItem,
    columns,
    color,
    openCollapseButton,
    openCollapseTooltip,
    openCollapseIcon,
    deleteButton,
    deleteTooltip,
    deleteIcon,
    reOpenButton,
    reOpenTooltip,
    reOpenIcon,
    fetchCases,
    canUpdateState,
  } = props;
  // Edit case
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteCase = async () => {
    setLoading(true);
    await axios
      .put(
        `/api/v1/customer_supports/${caseItem["id"]}/disable`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchCases();
        setLoading(false);
        setDialogOpen(false);
        flash_alert(
          "Éxito",
          "Se ha eliminado correctamente el caso",
          "success"
        );
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al eliminar el caso", "danger");
      });
  };

  const handleUpdateState = async (state) => {
    setLoading(true);
    setSelectedStatus("");
    await axios
      .put(
        `/api/v1/customer_supports/${caseItem["id"]}/${state}`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchCases();
        setLoading(false);
        setDialogOpen(false);
        flash_alert(
          "Éxito",
          "Se ha actualizado correctamente el estado del caso",
          "success"
        );
      })
      .catch((e) => {
        setLoading(false);
        flash_alert(
          "Error",
          "Hubo un error al actualizar el estado del caso",
          "danger"
        );
      });
  };

  const handleReOpenCase = async () => {
    setLoading(true);
    await axios
      .put(
        `/api/v1/customer_supports/${caseItem["id"]}/re_open`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchCases();
        setLoading(false);
        flash_alert(
          "Éxito",
          "Se ha reabierto correctamente el caso",
          "success"
        );
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al reabrir el caso", "danger");
      });
  };

  const downloadFiles = async () => {
    setLoading(true);
    await axios
      .post(
        `/api/v1/customer_supports/${caseItem["id"]}/download_files`,
        {},
        { headers: headers, responseType: "arraybuffer" }
      )
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: "octet/stream" });
        let fileName = "files.zip";
        saveAs(blob, fileName);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error: ", e);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        className="delete-dialog-container"
      >
        <p className="first-text">
          Estas a punto de eliminar este caso, si lo eliminas no podrás re
          abrirlo
        </p>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDialogOpen(false)}>
            Atrás
          </Button>
          <Button variant="contained" onClick={() => handleDeleteCase()}>
            {loading ? <CircularProgress /> : "Eliminar"}
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    {openCollapseButton && (
                      <Tooltip title={openCollapseTooltip} placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          {openCollapseIcon}
                        </IconButton>
                      </Tooltip>
                    )}

                    {reOpenButton && (
                      <Tooltip title={reOpenTooltip} placement="top">
                        <IconButton
                          className="re-open-case"
                          onClick={() => {
                            handleReOpenCase();
                          }}
                        >
                          {reOpenIcon}
                        </IconButton>
                      </Tooltip>
                    )}

                    {(isSeller(current_user) ||
                      isSellerOperator(current_user)) &&
                      deleteButton && (
                        <Tooltip title={deleteTooltip} placement="top">
                          <IconButton
                            onClick={() => setDialogOpen(true)}
                            className="delete-incidence"
                          >
                            {deleteIcon}
                          </IconButton>
                        </Tooltip>
                      )}

                    {(isSuperAdmin(current_user) || isAdmin(current_user)) &&
                      canUpdateState && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="dropdown-update-status"
                        >
                          <InputLabel id="dropdown-update-status-label">
                            Actualiza el estado
                          </InputLabel>
                          <Select
                            label="Actualiza el estado"
                            labelId="dropdown-update-status-label"
                            value={selectedStatus}
                            onChange={(e) => {
                              setSelectedStatus(e.target.value);
                              handleUpdateState(
                                e.target.value === "Caso en proceso"
                                  ? "ready"
                                  : "complete"
                              );
                            }}
                            IconComponent={() => (
                              <i className="material-icons-outlined">
                                expand_more
                              </i>
                            )}
                          >
                            {caseItem["aasm_state"] === "in_process" ? (
                              <MenuItem
                                className="dropdown-update-status-item"
                                key={index}
                                value={casesStates[1]}
                              >
                                {casesStates[1]}
                              </MenuItem>
                            ) : (
                              casesStates.map((state, index) => (
                                <MenuItem
                                  className="dropdown-update-status-item"
                                  key={index}
                                  value={state}
                                >
                                  {state}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                      )}
                  </div>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                >
                  {column.dbName.split("/").length === 2
                    ? caseItem[column.dbName.split("/")[0]][
                        column.dbName.split("/")[1]
                      ]
                    : column.columnName === "Motivo"
                    ? caseItem["reason"]
                    : column.columnName === "Destinatario" &&
                      (isSuperAdmin(current_user) || isAdmin(current_user))
                    ? caseItem["user"]["fullname"]
                    : caseItem["request"][column.dbName] == null
                    ? ""
                    : column.columnName === "Estado"
                    ? mapStatusCase(caseItem["aasm_state"])
                    : column.columnName === "Fecha de creación"
                    ? strDateFormat(new Date(caseItem["created_at"]))
                    : caseItem["request"][column.dbName]}
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="my-request-collapse"
          >
            <Box>
              <Grid container className="my-request-container">
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="sr-detail-element pd-r-10"
                    >
                      <p className="fw-medium super-small-text">Historial</p>
                      {caseItem["history"].map((item, index) => {
                        return <p key={`item-${index}`}>{item}</p>;
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className="sr-photos-container">
                  <Grid container>
                    <Grid item xs={12} sm={12} className="sr-photos-header">
                      <p className="fw-medium super-small-text">
                        Archivos cargados
                      </p>
                      {loading ? (
                        <CircularProgress className="loading-btn" />
                      ) : caseItem["files_urls"] !== null ? (
                        <a
                          onClick={() => downloadFiles()}
                          className="super-small-text"
                          download
                        >
                          <i className="material-icons">download</i> Descargar
                          archivos
                        </a>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} className="sr-photos-content">
                      <Grid container>
                        {caseItem["files_urls"] !== null &&
                          caseItem["files_urls"].map((file, index) => {
                            return (
                              <Grid
                                item
                                xs={6}
                                sm
                                key={`file-${caseItem["order_number"]}-${index}`}
                              >
                                {file.includes(".pdf") ? (
                                  <i className="material-icons">
                                    picture_as_pdf
                                  </i>
                                ) : (
                                  <img src={file} alt="Logo Go-EX" />
                                )}
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="sr-detail-element pd-r-10"
                    >
                      <p className="fw-medium super-small-text">
                        Comentarios adicionales
                      </p>
                      <p>{caseItem["comments"]}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default CaseRow;
