import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import {
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";

import { headers } from "../../constants/csrf";
import CustomTable from "../table/CustomTable";
import { saveAs } from "file-saver";

function Devolutions(props) {
  const [loading, setLoading] = useState(false);
  const [devolutions, setDevolutions] = useState([]);

  const fetchDevolutions = async () => {
    await axios
      .get("/api/v1/devolutions", {}, { headers: headers })
      .then((response) => {
        setDevolutions(response.data);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const downloadDevolutions = async () => {
    setLoading(true);
    await axios
      .post(
        "/api/v1/devolutions/download_devolutions",
        {},
        { headers: headers, responseType: "arraybuffer" }
      )
      .then((response) => {
        setLoading(false);
        let file = new File([response.data], "devolutions.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let fileName = "devoluciones.xlsx";
        saveAs(file, fileName);
      })
      .catch((e) => {
        setLoading(false);
        flash_alert(
          "Error",
          "Hubo un error al descargar el archivo",
          "danger"
        );
      });
  };


  useEffect(() => {
    fetchDevolutions();
  }, []);


  let headerTable = [
    { columnName: "Nº Orden", dbName: "request/order_number", enabled: true },
    { columnName: "Seller", dbName: "request/user/social_reason", enabled: true },
    { columnName: "Destinatario", dbName: "request/recipient", enabled: true },
    { columnName: "Dirección seller", dbName: "request/user/address", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Devoluciones</h1>
      </Grid>
      <Grid item xs={12} sm className="flex-right mg-t-30">
        <Button
          variant="outlined"
          onClick={() => {
            downloadDevolutions();
          }}
        >
          {loading ? (
            <CircularProgress className="loading-btn" />
          ) : (
            <>
              <i className="material-icons">download</i>
              Exportar devoluciones
            </>
          )}
        </Button>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={devolutions}
            headerTable={headerTable}
            fetchDevolutions={fetchDevolutions}
            selected={"none"}
            setSelected={"none"}
            completeButton={true}
            completeTooltip={"Solicitud devuelta"}
            completeIcon={
              <i className="material-icons-outlined">check_circle</i>
            }
            hasPagination={true}
            collapseType={"devolutions-detail"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Devolutions)
);
