import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Provider } from "react-redux";
import configureStore from "../../stores/configureStore";

import { flash_alert } from "components/App";
import UserForm from "components/users/UserForm";

import { Grid } from "@mui/material";

import logo from '../../../assets/images/logo-goex.svg';
import {  validate } from 'rut.js'
import * as EmailValidator from 'email-validator';
import { headers } from "../../constants/csrf";

function NewUser(props) {
  const store_r = configureStore(props.current_user);
  const [socialReason, setSocialReason] = useState("");
  const [rut, setRut] = useState("");
  const [region, setRegion] = useState("");
  const [commune, setCommune] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetOther, setStreetOther] = useState("");
  const [fullname, setFullname] = useState("");
  const [charge, setCharge] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ecommerce, setEcommerce] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowpassword] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  function handleClickShowPassword() {
    setShowpassword(!showPassword);
  }

  function handleMouseDownPassword(e) {
    e.preventDefault();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitPressed(true);
    setLoading(true);
    if(validate(rut) && EmailValidator.validate(email)){
      if (
        socialReason != "" &&
        rut != "" &&
        region != "" &&
        commune != "" &&
        street != "" &&
        streetNumber != "" &&
        fullname != "" &&
        charge != "" &&
        email != "" &&
        phoneNumber != "" &&
        ecommerce != "" &&
        password != ""
      ) {
        var body = new FormData();
        body.set("company[business_name]", socialReason?.trim());
        body.set("company[rut]", rut);
        body.set("company[ecommerce]", ecommerce);
        body.set("company[region]", region);
        body.set("company[commune]", commune);
        body.set("company[street]", street);
        body.set("company[street_number]", streetNumber);
        body.set("company[street_other]", streetOther);

        body.set("company[users_attributes][0][fullname]", fullname);
        body.set("company[users_attributes][0][charge]", charge);
        body.set("company[users_attributes][0][email]", email);
        body.set("company[users_attributes][0][phone_number]", phoneNumber);
        body.set("company[users_attributes][0][password]", password);
        return axios
          .post("/api/v1/companies", body, { headers: headers })
          .then((response) => {
            setLoading(false);
            window.location = "/signup/success";
          })
          .catch(({ response }) => {
            setLoading(false);
            console.log(response.data);
            response.data.map(error => (
              flash_alert(
                "Hubo un error al crear el usuario",
                error,
                "danger"
              )
            ))
          });
      }
    }
    else {
      setLoading(false);
      flash_alert(
        "Error",
        "El RUT o el Email no tienen nos válidos",
        "danger"
      );
    }
  }

  return (
    <Provider store={store_r}>
        <React.Fragment>
          <Grid
            container
            className="signup-main-container"
          >
            <Grid container item xs={12} className="signup-logo-container">
              <div className="signup-logo">
                <img src={logo} alt="Logo Go-EX" />
              </div>
            </Grid>
            <Grid container item xs={12} className="signup-container">
              <UserForm
                submitPressed={submitPressed}
                handleSubmit={handleSubmit}
                setSocialReason={setSocialReason}
                setRut={setRut}
                setRegion={setRegion}
                setCommune={setCommune}
                setStreet={setStreet}
                setStreetNumber={setStreetNumber}
                setStreetOther={setStreetOther}
                setFullname={setFullname}
                setCharge={setCharge}
                setEmail={setEmail}
                setPhoneNumber={setPhoneNumber}
                setEcommerce={setEcommerce}
                setPassword={setPassword}
                setPasswordConfirmation={setPasswordConfirmation}
                setShowpassword={setShowpassword}
                socialReason={socialReason}
                rut={rut}
                region={region}
                commune={commune}
                street={street}
                streetNumber={streetNumber}
                streetOther={streetOther}
                fullname={fullname}
                charge={charge}
                email={email}
                phoneNumber={phoneNumber}
                ecommerce={ecommerce}
                password={password}
                passwordConfirmation={passwordConfirmation}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                loading={loading}
              />
            </Grid>
          </Grid>
        </React.Fragment>
    </Provider>
  );
}

export default NewUser;
