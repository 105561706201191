import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { es } from "date-fns/locale";

import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";
import { disableDateFunction } from "../../utils/functions";
import { isSellerOperator } from "../../constants/user_functions";

function RequestWithdrawalDialog(props) {
  const { withdrawalOpen, setWithdrawalOpen, current_user } = props;

  const company = current_user.company;
  const lastWithdrawalHour = company.withdrawal_hours.slice(-1)[0];

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  const [withdrawalAddresses, setWithdrawalAddresses] = useState([]);
  const [withdrawalDate, setWithdrawalDate] = useState(new Date());
  const [withdrawalHour, setWithdrawalHour] = useState();

  const [minDate, setMinDate] = useState(new Date());

  const handleBack = () => {
    setWithdrawalOpen(false);
  };

  const handleConfirm = async () => {
    if (selected !== 0) {
      setLoading(true);
      
      const body = new FormData();
      body.set("withdrawal_address_id", selected);
      body.set("withdrawal_date", withdrawalDate);
      body.set("selected_hour", withdrawalHour);

      await axios
        .post("/api/v1/withdrawals", body, { headers: headers })
        .then((response) => {
          setLoading(false);
          setWithdrawalOpen(false);
          flash_alert(
            "Éxito",
            "Solicitud de retiro ingresada con éxito",
            "success"
          );
        })
        .catch(({ response }) => {
          setLoading(false);
          console.log(response);
          flash_alert(
            "Horario inválido",
            response.data.invalid_hour[0],
            "error"
          );
        });
    } else {
      flash_alert("Error", "Selecciona una dirección de retiro", "danger");
    }
  };

  useEffect(() => {
    const fectchWithdrawalAddresses = async () => {
      const { data } = await axios(`/api/v1/companies/${current_user.company_id}/withdrawal_info`);
      setWithdrawalAddresses(data.addresses);
    }

    fectchWithdrawalAddresses();
  }, []);

  useEffect(() => {
    setSelected(0);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (disableDateFunction(today, lastWithdrawalHour)) {
      setWithdrawalDate(tomorrow);
      setMinDate(tomorrow);
    } 
    else {
      setWithdrawalDate(today);
      setMinDate(today);
    }
  }, [withdrawalOpen]);

  const handleUpdateSelected = (event) => {
    setSelected(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={withdrawalOpen}
        onClose={() => setWithdrawalOpen(false)}
      >
        <h5>Solicita tu retiro</h5>
        <Grid container>
          <Grid item xs={12} className="flex-left mg-t-30">
            <p>Selecciona tu dirección de retiro</p>
          </Grid>
          <FormControl className="withdrawal-addresses-container">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={
                withdrawalAddresses && withdrawalAddresses.length > 0
                  ? withdrawalAddresses[0].id
                  : 1
              }
              value={selected}
              name="radio-buttons-group"
              onChange={handleUpdateSelected}
            >
              {withdrawalAddresses && withdrawalAddresses.map((address, index) => (
                <FormControlLabel
                  value={address.id}
                  label={`${address.street} #${address.street_number}, ${address.commune}, ${address.region}`}
                  control={<Radio />}
                  key={`address-${index}`}
                  className={
                    parseInt(selected, 10) === address.id
                      ? "withdrawal-address-selected"
                      : ""
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Grid item xs={12} className="flex-left mg-t-30">
            <p>Escoge fecha para el retiro</p>
          </Grid>
          <Grid item xs={12}>
            <div className="withdrawal-date-container">
              <Grid container className="input-date-filter">
                <Grid item xs="12" sm="6">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={es}
                  >
                    {window.innerWidth > 768 ? (
                      <DatePicker
                        label="Fecha de retiro"
                        value={withdrawalDate}
                        onChange={(newValue) => {
                          setWithdrawalDate(newValue);
                        }}
                        minDate={minDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                      <MobileDatePicker
                        label="Fecha de retiro"
                        value={withdrawalDate}
                        className="custom-mobile-datepicker"
                        okText="Aceptar"
                        cancelText="Cancelar"
                        onChange={(newValue) => {
                          setWithdrawalDate(newValue);
                        }}
                        minDate={minDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs="12" sm="6">
                  <FormControl
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel>
                      Seleccionar horario
                    </InputLabel>

                    <Select
                      label="Seleccionar horario"
                      labelId="dropdown-update-status-label"
                      value={withdrawalHour}
                      onChange={(e) => {
                        setWithdrawalHour(e.target.value);
                      }}
                      IconComponent={() => (
                        <i className="material-icons-outlined">
                          expand_more
                        </i>
                      )}
                    >
                    {
                      company.withdrawal_hours.map((option, index) => (
                        <MenuItem
                          className="dropdown-update-status-item"
                          key={index}
                          value={option}
                        >
                          {option}
                        </MenuItem>

                      ))
                    }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <DialogActions>
          <Grid item xs={12} className="flex-left">
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => handleBack()}
              className="mg-r-10"
            >
              {"Volver atrás"}
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={() => handleConfirm()}
              className="mg-l-10"
            >
              {loading ? (
                <CircularProgress className="loading-btn" />
              ) : (
                "Confirmar retiro"
              )}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(RequestWithdrawalDialog)
);
