import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { TranslatorProvider, useTranslate } from "react-translate";

const breadcrumbNameMap = {
  // Users
  "/users/active": "Usuarios activos",
  "/users/profile": "Perfil",
  "/users/pending": "Clientes por aprobar",
  "/users/admin_new": "Nuevo Usuario",
  "/users/edit/:id/seller": "Editar seller",
  "/users/edit/:id/goex": "Editar goex",
  // Requests
  "/requests/pending": "Solicitudes pendientes",
  "/requests/new/type": "Nueva solicitud",
  "/requests/new/file_kind": "Nueva solicitud",
  "/requests/new/success": "Carga exitosa",
};

let translations = {
  locale: "es",
  BreadCrumb: {
    edit: "Editar",
    admin_new: "Nuevo usuario",
    seller: "Editar seller",
    goex: "Editar goex",
    requests: "Solicitudes",
    new: "Nueva",
    type: "Carga de solicitudes",
    file_kind: "Carga de solicitudes",
    success: "Carga exitosa"
  },
};

function BreadCrumbT(text) {
  let t = useTranslate("BreadCrumb");
  return t(text.text);
}

function SiteBreadCrumbs(props) {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory();
  useEffect(() => {
    fetchData();
    history.listen((location, action) => {
      fetchData();
    });
  }, [history.location.pathname]);

  async function fetchData() {
    let newBreadcrumbs = [];
    if (breadcrumbNameMap) {
      const pathnames = location.pathname.split("/").filter((x) => x);
      {
        pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          if (last && pathnames.length > 2) {
            newBreadcrumbs.push(
              <Typography className="mdl-navigation__link capitalize" key={to}>
                <BreadCrumbT text={pathnames[pathnames.length - 1]} />
              </Typography>
            );
          } else {
            if (breadcrumbNameMap[to]) {
              newBreadcrumbs.push(
                <Link className="mdl-navigation__link" to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </Link>
              );
            }
          }
        });
      }
    }
    setBreadcrumbs(newBreadcrumbs);
  }

  return (
    <div className="breadcrums-main-container">
      <TranslatorProvider translations={translations}>
        <Breadcrumbs
          className="custom-breadcrums"
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className="mdl-navigation__link" to="/home">
            Inicio
          </Link>
          {breadcrumbs}
        </Breadcrumbs>
      </TranslatorProvider>
    </div>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(SiteBreadCrumbs);
