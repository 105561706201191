import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { isIOS, isAndroid, isMobile, isBrowser } from "react-device-detect";

function Meliflex() {
  useEffect(() => {
    setTimeout(() => {
      if (isIOS && isMobile) {
        window.location.replace(
          "intent://mercadoenvios.com/#Intent;package=com.mercadoenvios.driver;scheme=https;end"
        );
      }
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <div className="flex-center">
        {isBrowser && (
          <h1>Función no habilitada para navegadores de escritorio</h1>
        )}
        {isIOS && <h1>Redirigiendo a App meliflex...</h1>}
        {isAndroid && (
          <a href="intent://mercadoenvios.com/#Intent;package=com.mercadoenvios.driver;scheme=https;end">
            <Button variant="contained">
              Presiona aquí para abrir App Meliflex
            </Button>
          </a>
        )}
      </div>
    </React.Fragment>
  );
}

export default Meliflex;
