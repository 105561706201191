import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
} from "@mui/material";

function CustomCircularProgress(props) {
  const [progress, setProgress] = useState(props.progress);
  useEffect(() => {
    setProgress(props.progress);
  }, [props.progress]);
  return (
    <Grid container className={`circular-container`}>
      <Grid item>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            sx={{
              color: "#ddd",
            }}
            variant="determinate"
            value={100}
            size={100}
            thickness={5}
          />
          <CircularProgress
            className={`custom-circular-progress ${props.color}`}
            variant="determinate"
            value={progress}
            size={100}
            thickness={5}
            sx={{
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
          />

          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <h3 className="fw-medium">{props.number}%</h3>
          </Box>
        </Box>
      </Grid>
      <Grid item className="circular-text">
        <h6>{props.title}</h6>
        <p className="small-text">{props.text}</p>
      </Grid>
    </Grid>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(CustomCircularProgress)
);
