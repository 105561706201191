import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { es } from "date-fns/locale";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import CustomTable from "../table/CustomTable";
import { strDateFormat } from "../../utils/functions";

import ExportToRouter from "components/requests/ExportToRouter";

const ForRoutingRequests = (props) => {
  const [requests, setRequests] = useState([]);
  const [requestsFiltered, setRequestsFiltered] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [canRoute, setCanRoute] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);

  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    business_name: [],
    order_number: [],
    recipient: [],
    address: [],
    translated_kind: [],
  });
  const [loadingFilters, setLoadingFilters] = useState(false);

  const fetchRequests = () => {
    return axios
      .get("/api/v1/requests/router_requests", {}, { headers: headers })
      .then(({ data }) => {
        setSelected([]);
        setRequests(data.requests);
        setRequestsFiltered(data.requests);

        setFilters(data.filters);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const headerTable = [
    { columnName: "Nombre Seller", dbName: "business_name", enabled: true },
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    { columnName: "Destinatario", dbName: "recipient", enabled: true },
    { columnName: "Dirección", dbName: "address", enabled: true },
    { columnName: "Comuna", dbName: "comuna_name", enabled: true },
    { columnName: "Tipo de solicitud", dbName: "translated_kind", enabled: true },
    { columnName: "Fecha de retiro asignado", dbName: "withdrawal_date", enabled: true },
    { columnName: "Zona de Envío", dbName: "delivery_zone", enabled: true },
  ];

  const handleFilterChange = (filter, values) => {
    setSelectedFilters({
      ...selectedFilters, 
      [filter]: values,
    });
  }

  const applyFilters = () => {
    let filteredRequests = requests;
    Object.entries(selectedFilters).forEach(([field, values]) => {
      if (values.length > 0) {
        filteredRequests = filteredRequests.filter((request) => values.includes(request[field]));
      }
    });

    setRequestsFiltered(filteredRequests);
  }

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    setCanRoute(selected.length > 0);
  }, [selected]);

  useEffect(() => {
    applyFilters();
  }, [selectedFilters]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Solicitudes por rutear</h1>
      </Grid>
      <Grid item xs={12} sm className="filters-container">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          {window.innerWidth > 768 ? (
            <DatePicker
              label="Fecha"
              value={dateFilter}
              onChange={(newValue) => {
                setDateFilter(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : (
            <MobileDatePicker
              label="Fecha"
              value={dateFilter}
              className="custom-mobile-datepicker"
              okText="Aceptar"
              cancelText="Cancelar"
              onChange={(newValue) => {
                setDateFilter(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </LocalizationProvider>

        <div className="router-buttons">
          <ExportToRouter
            disabled={selected.length === 0}
            selectedRequests={selected}
            routerName={"Simpli Route"}
          />

          <ExportToRouter
            disabled={selected.length === 0}
            selectedRequests={selected}
            routerName={"Recíbelo"}
          />
        </div>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="filters-container">
          {
            Object.entries(filters).map(([filter, values])=> (
              <Autocomplete
                multiple
                id={`tag-${filter}`}
                options={values}
                filterSelectedOptions
                onChange={(event, newValue) => handleFilterChange(filter, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      headerTable.find(header => header.dbName === filter)
                                 .columnName
                    }
                  />
                )}
              />
            ))
          }
        </Grid>
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={requestsFiltered}
            headerTable={headerTable}
            fetchRequests={fetchRequests}
            selected={selected}
            setSelected={setSelected}
            openCollapseTooltip={"Ver más"}
            openCollapseIcon={
              <i className="material-icons-outlined">add_circle_outline</i>
            }
            hasPagination={true}
            collapseType={"for-routing-table"}
            dateFilter={dateFilter}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(ForRoutingRequests)
);
