import React, { useState } from 'react';
import axios from "axios";

import {
  Button,
  CircularProgress,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";

const ChangeRequestKind = ({ disabled, servicesTypes, requestsToUpdate }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState('');

  const [loading, setLoading] = useState(false);

  const handleUpdateServiceType = () => {
    setLoading(true);

    const body = new FormData();

    body.set("request_ids", requestsToUpdate.map(request => request.id));
    body.set('new_kind', selectedServiceType);

    return axios.put(`/api/v1/requests/update_service_type`, 
                     body, 
                     {'headers': headers})
                .then(response => {
                  flash_alert("Solicitudes actualizadas", "Las solicitudes han sido actualizadas con éxito", "success");
                  setShowForm(false);
                  setLoading(false);
                  location.reload();
                })
                .catch(({response}) => {
                  setLoading(false);
                  if(response.data){
                    console.log(response.data);
                    flash_alert('', 'Ocurrió un problema al ejecutar la acción', 'danger');
                  }
                });
  }

  return (
    <React.Fragment>
    {
      showForm ?
      <div className="update-service-type-form">
        <FormControl>
          <InputLabel id="service-type-select-label">Nuevo tipo de servicio</InputLabel>
          <Select
            labelId="service-type-select-label"
            id="service-type-select"
            value={selectedServiceType}
            label="Nuevo tipo de servicio"
            onChange={(e) => setSelectedServiceType(e.target.value)}
          >
            {
              Object.entries(servicesTypes).map(([kind, label], index) => (
                <MenuItem key={index} value={kind}>
                  {label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleUpdateServiceType}
          disabled={selectedServiceType === ''}
          className={`${selectedServiceType === '' && "button-disabled"}`}
        >
          Actualizar
        </Button>
      </div>
      :
      <Button
        disabled={disabled}
        variant="contained"
        className={`${disabled && "button-disabled"}`}
        onClick={() => setShowForm(true)}
      >
        {
          loading ?
          <CircularProgress className="loading-btn" />
          :
          "Cambiar tipo de servicio"
        }
      </Button>
    }
    </React.Fragment>
  );
}

export default ChangeRequestKind;