import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import CustomCircularProgress from "../graphs/CustomCircularProgress";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  isSuperAdmin,
  isAdmin,
  isRouter,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";

import Mailings from "./Mailings";
import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";

function MailingConfig(props) {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(
    props.mailingEdit["subject"] === null ? "" : props.mailingEdit["subject"]
  );
  const [replyTo, setReplyTo] = useState(
    props.mailingEdit["reply_to"] === null ? "" : props.mailingEdit["reply_to"]
  );
  const [message, setMessage] = useState(
    props.mailingEdit["message"] === null ? "" : props.mailingEdit["message"]
  );

  const customizationOptions = props.mailingEdit.request_data;
  const isForRequest = props.mailingEdit.is_for_request;

  const [redirectBack, setRedirectBack] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const body = new FormData();
    body.set("subject", subject);
    body.set("message", message);
    if (isSeller(props.current_user)) {
      body.set("reply_to", replyTo);
    }
    await axios
      .put(`/api/v1/mailings/${props.mailingEdit["id"]}`, body, {
        headers: headers,
      })
      .then(() => {
        setLoading(false);
        flash_alert("Éxito", "Mailing actualizado correctamente", "success");
        setRedirectBack(true);
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al obtener los mailings", "danger");
      });
  };

  const appendAttributeToSubject = (attribute_name) => {
    setSubject(`${subject} {${attribute_name}}`);
  }

  const appendAttributeToMessage = (attribute_name) => {
    setMessage(`${message} {${attribute_name}}`);
  }

  let redirect_check = [];
  if (props.mailingEdit["status"] === undefined) {
    redirect_check.push(
      <Redirect key="redirect-to-home" to="/home">
        <MailingConfig />
      </Redirect>
    );
  }

  if (redirectBack) {
    redirect_check.push(
      <Redirect key="redirect-to-mailings" to="/mailings">
        <Mailings />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <Grid container className="mailing-container">
        <Grid item xs={6} sm={6} className="flex-left">
          <h1>Configuración mailing</h1>
        </Grid>
        <Grid item xs={12} className="mg-b-30">
          <h6 className="subtitle">
            Notificaciones al cliente final
          </h6>
          <div className="mailing-description-data">
            <p><strong>Estado</strong>: {props.mailingEdit["status"]}</p>
            <p><strong>Tipo de orden</strong>: {props.mailingEdit["translated_kind"]}</p>
          </div>
        </Grid>
        <Grid item xs={12} className="flex-center">
          <TextField
            variant="outlined"
            label="Asunto"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="mailing-textfield-input"
          />
        </Grid>
        
        {
          isForRequest &&
          <Grid item xs={12} className="flex-center customization-options-container">
            <label>Inserta información personalizada al asunto</label>
            <div>
              {
                Object.entries(customizationOptions).map(([attribute_name, label]) => (
                  <Button
                    key={ attribute_name }
                    variant="outlined"
                    onClick={() => appendAttributeToSubject(attribute_name)}
                  >
                    { label }
                  </Button>
                ))
              }
            </div>
          </Grid>
        }

        {
          isSeller(props.current_user) && 
          <Grid item xs={12} className="flex-center reply-to-container">
            <TextField
              variant="outlined"
              label="Email respuesta"
              name="replyTo"
              value={replyTo}
              onChange={(e) => setReplyTo(e.target.value)}
              className="mailing-textfield-input"
            />
            <Tooltip
              arrow
              title={
                "Configura el correo al que quieres que tus clientes respondan"
              }
              placement="top"
            >
              <i className="material-icons-outlined">info</i>
            </Tooltip>
          </Grid>
        }

        <Grid item xs={12} className="flex-center">
          <TextField
            multiline
            variant="outlined"
            label="Mensaje"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mailing-textfield-input multiline-textfield"
          />
        </Grid>

        {
          isForRequest &&
          <Grid item xs={12} className="flex-center customization-options-container">
            <label>Inserta información personalizada al mensaje</label>
            <div>
              {
                Object.entries(customizationOptions).map(([attribute_name, label]) => (
                  <Button
                    key={ attribute_name }
                    variant="outlined"
                    onClick={() => appendAttributeToMessage(attribute_name)}
                  >
                    { label }
                  </Button>
                ))
              }
            </div>
          </Grid>
        }

        <Grid item xs={12} className="flex-center mg-t-20">
          <Grid container className="mailing-delete-container">
            <Grid item xs={12} className="flex-left">
              <IconButton
                color="primary"
                onClick={() => {
                  setMessage("");
                }}
              >
                <i className="material-icons-outlined">cancel</i>
                <p className="mg-0 pd-l-10">Borrar email</p>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="flex-center mg-t-50">
          <Button
            className="mg-r-15"
            disabled={loading}
            variant="outlined"
            onClick={() => setRedirectBack(true)}
          >
            Volver atrás
          </Button>
          <Button
            className="mg-l-15"
            disabled={loading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {
              loading ? 
              <CircularProgress className="loading-btn" />
              : 
              "Guardar email"
            }
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  mailingEdit: (state) => state.mailingEdit,
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(MailingConfig)
);
