import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";

import { strDateFormat } from "../../utils/functions";
import axios from "axios";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput
} from "@mui/material";
import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";

import AssignRequestsToWithdrawal from "components/requests/AssignRequestsToWithdrawal";
import ChangeRequestKind from "components/requests/ChangeRequestKind";

import CustomTable from "../table/CustomTable";

import { formattedDateForDB } from "../../utils/timeFormat";
import { saveAs } from "file-saver";
import { validRequest } from "../../utils/functions";

function PendingRequests(props) {
  const [open, setOpen] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDesassignRequest, setLoadingDesassignRequest] = useState(false);
  const [loadingSendRequest, setLoadingSendRequest] = useState(false);
  const [loadingUpdateServiceType, setLoadingUpdateServiceType] = useState(false);
  const [loadingDownloadLabels, setLoadingDownloadLabels] = useState(false);

  const [requests, setRequests] = useState([]);
  const [labels, setLabels] = useState(null);
  const [manifiest, setManifiest] = useState(null);
  const [selected, setSelected] = useState([]);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [shipmentsCount, setShipmentsCount] = useState(0);
  const [flashCount, setFlashCount] = useState(0);
  const [meliflexCount, setMeliflexCount] = useState(0);
  const [returnsCount, setReturnsCount] = useState(0);
  const [canSend, setCanSend] = useState(false);
  const [search, setSearch] = useState("");
  const [serviceFilter, setServiceFilter] = useState("");
  const [requestsFiltered, setRequestsFiltered] = useState([]);
  const [comunas, setComunas] = useState([]);

  const [servicesTypes, setServicesTypes] = useState([]);

  const fetchRequests = async () => {
    await axios
      .get("/api/v1/requests/pending_requests", {}, { headers: headers })
      .then(({ data }) => {
        setRequests(data.requests);
        setRequestsFiltered(data.requests);
        setServicesTypes(data.request_kinds);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    setPendingRequestCount(requests.length);
    let shipments = 0;
    let flash = 0;
    let meliflex = 0;
    let returns = 0;
    requests.map((request) => {
      if (request.kind === "same_day") {
        shipments += 1;
      } 
      else if (request.kind === "site_to_store") {
        flash += 1;
      } 
      else if (request.kind === "meli_flex") {
        meliflex += 1;
      } 
      else if (request.kind === "returns") {
        returns += 1;
      }
    });
    setShipmentsCount(shipments);
    setFlashCount(flash);
    setMeliflexCount(meliflex);
    setReturnsCount(returns);
  }, [requests]);

  useEffect(() => {
    if (selected.length > 0) {
      let flag = true;
      selected.map((request) => {
        if (!validRequest(request)) {
          flag = false;
        }
      });
      setCanSend(flag);
    }
  }, [selected]);

  let headerTable = [
    { columnName: "Destinatario", dbName: "recipient", enabled: true },
    { columnName: "Dirección", dbName: "full_address", enabled: true },
    { columnName: "ID Referencia", dbName: "reference_id", enabled: true },
    { columnName: "Fecha planificada", dbName: "planned_date", enabled: true },
    { columnName: "Tipo de solicitud", dbName: "translated_kind", enabled: true },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  const downloadLabels = () => {
    setLoadingDownloadLabels(true);
    const body = new FormData();
    body.set("request_ids", selected.map((req) => req.id));

    return axios.post("/api/v1/requests/download_unique_document_with_labels", 
                     body, 
                     { headers: headers, responseType: "arraybuffer", })
                .then((response) => {
                  const fileName = `Etiquetas_${formattedDateForDB(new Date())}.pdf`;
                  const file = new File([response.data], 
                                        fileName, 
                                        { type: "text/json;charset=utf-8" }
                                       );
                  saveAs(file, fileName);
                  setLoadingDownloadLabels(false);
                })
                .catch((e) => {
                  console.log("error: ", e);
                  setLoadingDownloadLabels(false);
                  flash_alert(
                    "Error",
                    "Hubo un error al descargar las etiquetas, intenta de nuevo",
                    "danger"
                  );
                });
  }

  const downloadManifiest = () => {
    let file = new File([manifiest], "manifiesto.pdf", {
      type: "text/json;charset=utf-8",
    });
    let fileName = "manifiesto.pdf";
    saveAs(file, fileName);
  };

  const handleClickDeleteRequests = () => {
    setLoading(true);

    const body = new FormData();
    body.set("request_ids", selected.map((req) => req.id));

    return axios.post("/api/v1/requests/batch_delete", body, { headers: headers })
      .then((response) => {
        setLoading(false);
        setOpenDialogDelete(false);
        fetchRequests();
        flash_alert(
          "Éxito",
          selected.length === 1 ? 
          "Se ha eliminado correctamente la solicitud"
          : 
          "Se han eliminado correctamente las solicitudes",
          "success"
        );
        setSelected([]);
      })
      .catch((e) => {
        setLoading(false);
        setOpenDialogDelete(false);
        flash_alert(
          "Error",
          "Hubo un error al eliminar las solicitudes, intenta de nuevo",
          "danger"
        );
      });
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setOpen(false);
  }

  const desassignRequest = () => {
    setLoading(true);
    setLoadingDesassignRequest(true);
    const body = new FormData();
    body.set(
      "request_ids",
      selected.map((req) => req.id)
    );
    return axios.put("/api/v1/requests/desassign_from_withdrawal", body, { headers: headers })
      .then((response) => {
        fetchRequests();
        setLoading(false);
        setLoadingDesassignRequest(false);
        flash_alert(
          "",
          "Solicitudes desasignadas con éxito",
          "success"
        );
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  useEffect(() => {
    setRequestsFiltered(
      requests.filter(function (a) {
        return (
          (serviceFilter !== "" ? a.kind === serviceFilter : true)
        );
      })
    );
  }, [serviceFilter]);

  useEffect(() => {
    const searchText = search.trim().toLowerCase();
    setRequestsFiltered(
      requests.filter(function (a) {
        return (
          a.order_number?.trim().toLowerCase().includes(searchText) ||
          a.reference_id?.trim().toLowerCase().includes(searchText) ||
          a.recipient?.trim().toLowerCase().includes(searchText) ||
          a.address?.trim().toLowerCase().includes(searchText) ||
          a.translated_state?.trim().toLowerCase().includes(searchText)
        );
      })
    );
  }, [search]);

  useEffect(() => {
    const fetchComunas = async () => {
      await axios.get("/api/v1/comunas")
                 .then(({ data }) => {
                   setComunas(data);
                 })
    }

    fetchComunas();
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Solicitudes pendientes</h1>
      </Grid>
      <Dialog
        maxWidth={"sm"}
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
      >
        {selected.length === 1 ? (
          <p>
            Estás a punto de eliminar la solicitud{" "}
            <span className="fw-medium purple-text">
              {selected[0].reference_id}
            </span>
            , <span className="fw-medium">¿deseas continuar?</span>
          </p>
        ) : (
          <p>
            Estás a punto de eliminar varias solicitudes,{" "}
            <span className="fw-medium">¿deseas continuar?</span>
          </p>
        )}
        <DialogActions>
          <Button
            disabled={loading}
            variant="outlined"
            onClick={() => setOpenDialogDelete(false)}
            className="mg-r-10"
          >
            Volver atrás
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => handleClickDeleteRequests()}
            className="mg-l-10"
          >
            {
              loading ? 
              <CircularProgress className="loading-btn" />
              : 
              "Continuar"
            }
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleCloseDialog}
        className="withdrawal-assignment-modal"
      >
        <AssignRequestsToWithdrawal
          requests={selected}
          currentUser={props.current_user}
          setOpenDialog={setOpen}
          fetchRequests={fetchRequests}
          setSelected={setSelected}
        />
      </Dialog>

      <Grid container>
        <Grid item xs={12} className="chip-btn-container">
          <Grid container className="flex-between">
            <Grid item>
              <Chip
                label={`${pendingRequestCount} Solicitudes pendientes`}
                variant="outlined"
              />
              <Chip label={`${shipmentsCount} Envíos Sameday`} variant="outlined" />
              <Chip label={`${flashCount} Site to Store`} variant="outlined" />
              <Chip
                label={`${meliflexCount} Envíos MeliFlex`}
                variant="outlined"
              />
              <Chip label={`${returnsCount} Devoluciones`} variant="outlined" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="flex-left filters-container-pending">
          <div className="row-container request-search-container">
            <Grid container>
              <Grid item xs={6} sm="auto" className="input-service-filter">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="select-service-filte">
                    Tipo de servicio
                  </InputLabel>
                  <Select
                    value={serviceFilter}
                    onChange={(e) => {
                      setServiceFilter(e.target.value);
                      setSearch("");
                    }}
                    input={<OutlinedInput id="select-service-filter" />}
                    IconComponent={() => (
                      <i className="material-icons-outlined">expand_more</i>
                    )}
                  >
                    {
                      Object.entries(servicesTypes).map(([kind, label], index) => (
                        <MenuItem key={index} value={kind}>
                          {label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm="auto">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar"
                  name="search"
                  value={search}
                  onChange={(e) => {
                    setServiceFilter("");
                    setSearch(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} className="mg-t-40 flex-left buttons-container">
          <Button
            disabled={selected.length === 0 || loading}
            variant="outlined"
            className={`delete-requests-button ${selected.length === 0 ? "button-disabled" : null
              }`}
            onClick={() => {
              setOpenDialogDelete(true);
            }}
          >
            Eliminar solicitudes
          </Button>

          <Button
            disabled={selected.length === 0 || loading}
            variant="outlined"
            className={`${selected.length === 0 ? "button-disabled" : ""}`}
            onClick={desassignRequest}
          >
            {
              loadingDesassignRequest ?
                <CircularProgress className="loading-btn" />
                :
                "Desasignar solicitudes"
            }
          </Button>

          <Button
            disabled={!canSend || selected.length === 0 || loading}
            variant="contained"
            className={
              !canSend || selected.length === 0 ? "button-disabled" : null
            }
            onClick={() => {
              setOpen(true);
            }}
          >
            {
              loadingSendRequest ? 
              <CircularProgress className="loading-btn" />
              : 
              "Enviar solicitudes"
            }
          </Button>

          <ChangeRequestKind
            disabled={selected.length === 0 || loading}
            servicesTypes={servicesTypes}
            requestsToUpdate={selected}
          />

          <Button
            disabled={selected.length === 0 || loading}
            variant="contained"
            className={ selected.length === 0 ? "button-disabled" : null }
            onClick={downloadLabels}
          >
            {
              loadingDownloadLabels ? 
              <CircularProgress className="loading-btn" />
              : 
              "Descargar Etiquetas"
            }
          </Button>
        </Grid>
      </Grid>

      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={requestsFiltered}
            search={search}
            filterByDate={serviceFilter !== ""}
            headerTable={headerTable}
            fetchRequests={fetchRequests}
            selected={selected}
            setSelected={setSelected}
            openCollapseTooltip={"Editar datos"}
            openCollapseIcon={<i className="material-icons">edit</i>}
            deleteRequestTooltip={"Eliminar solicitud"}
            deleteRequestIcon={
              <i className="material-icons-outlined">cancel</i>
            }
            collapseType={"request-edit"}
            hasPagination={true}
            comunas={comunas}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(PendingRequests)
);
