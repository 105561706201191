import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import CustomCircularProgress from "../graphs/CustomCircularProgress";
import {
  Box,
  Grid,
  OutlinedInput,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  isSuperAdmin,
  isAdmin,
  isRouter,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";

import { headers } from "../../constants/csrf";

function Home(props) {
  const [KPIs, setKPIs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loadingBlogs, setLoadingBlogs] = useState(false);
  const [filter, setFilter] = useState('');
  const [averageKPI, setAverageKPI] = useState(0);
  const [otifKPI, setOtifKPI] = useState(0); // On time in full = OTIF
  const [efectivityKPI, setEfectivityKPI] = useState(0);

  const [kpiFilters, setKpiFilters] = useState([]);

  const fetchKPI = async () => {
    await axios
      .get(
        `/api/v1/helpers/kpi/${props.current_user.company_id}`,
        {},
        { headers: headers }
      )
      .then(({ data }) => {
        setKPIs(data.kpis);
        setAverageKPI(data.kpis.all.average);
        setOtifKPI(data.kpis.all.otif);
        setEfectivityKPI(data.kpis.all.efectivity);
        setFilter('all');

        setKpiFilters(data.kinds);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const fetchBlogs = async () => {
    setLoadingBlogs(true);
    await axios
      .get("/api/v1/blogs", {}, { headers: headers })
      .then((response) => {
        setLoadingBlogs(false);

        setBlogs(response.data);
      })
      .catch((e) => {
        setLoadingBlogs(false);

        flash_alert("Error", "Hubo un error al obtener los blogs", "danger");
      });
  };

  const handleSetFilter = (filter) => {
    setAverageKPI(KPIs[filter].average);
    setOtifKPI(KPIs[filter].otif);
    setEfectivityKPI(KPIs[filter].efectivity);
  };

  useEffect(() => {
    if (props.current_user.company_id) {
      fetchKPI();
    }
    fetchBlogs();
  }, []);

  return (
    <React.Fragment>
      <Grid container className="home-container">
        <Grid item xs={6} sm={6} className="flex-left">
          <h1>Hola {props.current_user.fullname} 👋</h1>
        </Grid>
        {(isSeller(props.current_user) ||
          isSellerOperator(props.current_user)) && (
          <>
            <Grid item xs={12} sm={12} className="flex-left mg-t-30">
              <h5 className="fw-medium">Estos son tus números de este mes</h5>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              className="flex-left mg-t-30 filter-container"
            >
              <h6>Filtrar por</h6>
              <Select
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  handleSetFilter(e.target.value);
                }}
                input={<OutlinedInput id="select-service-filter" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
                className="select-filter"
              >
                {
                  Object.entries(kpiFilters).map(([kind, label], index) => (
                    <MenuItem key={index} value={kind}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} sm="auto" className="mg-t-30">
                    <div className="container-average-kpi">
                      <h6>Promedio de envíos diarios</h6>
                      <h1 className="mg-0">{averageKPI}</h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm="auto" className="mg-t-30">
                    <CustomCircularProgress
                      color={"purple"}
                      progress={otifKPI}
                      number={otifKPI}
                      title={"On time in full"}
                      text={
                        "Puntos visitados con respecto a los que se rutearon."
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm="auto" className="mg-t-30">
                    <CustomCircularProgress
                      color={"green"}
                      progress={efectivityKPI}
                      number={efectivityKPI}
                      title={"Efectividad"}
                      text={
                        "Solicitudes completadas respecto a los que se rutearon."
                      }
                    />
                  </Grid>

                  <Grid item xs={6} sm="auto" className="mg-t-30">
                    <Link to={"/requests/new/type"}>
                      <Box className="upload-requests">
                        <h6>Carga tus solicitudes</h6>
                        <i className="material-icons-outlined">arrow_forward</i>
                      </Box>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className="flex-left mg-t-30">
              <h5 className="fw-medium">Novedades en Go-Ex</h5>
            </Grid>

            {blogs.length === 0 || loadingBlogs ? (
              <CircularProgress className="loading-counter" />
            ) : (
              <>
                {blogs.slice(0, 2).map((blog, index) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      className={`mg-t-30 ${
                        index === 0 ? "pd-r-15" : "pd-l-15"
                      }`}
                      key={`blog-first-${index}`}
                    >
                      {blog["aasm_state"] === "enabled" && (
                        <a href={blog["url"]} target="_blank">
                          <div className="home-blog-container">
                            <img
                              className="home-blog-first-container"
                              src={blog["files_urls"][0]}
                            />
                            <h4 className="home-blog-title">{blog["title"]}</h4>
                          </div>
                        </a>
                      )}
                    </Grid>
                  );
                })}
                {blogs.slice(2, 5).map((blog, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      className={`mg-t-30 ${
                        index === 0
                          ? "pd-r-20"
                          : index === 1
                          ? "pd-l-10 pd-r-10"
                          : "pd-l-20"
                      }`}
                      key={`blog-second-${index}`}
                    >
                      {blog["aasm_state"] === "enabled" && (
                        <a href={blog["url"]} target="_blank">
                          <div className="home-blog-container">
                            <img
                              className="home-blog-second-container"
                              src={blog["files_urls"][0]}
                            />
                            <h4 className="home-blog-title">{blog["title"]}</h4>
                          </div>
                        </a>
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Home)
);
