export function isSuperAdmin(current_user) {
    return current_user && current_user.roles_names == "Super administrador";
}

export function isAdmin(current_user) {
    return current_user && current_user.roles_names == "Administrador";
}

export function isRouter(current_user) {
    return current_user && current_user.roles_names == "Ruteador";
}

export function isSeller(current_user) {
    return current_user && current_user.roles_names == "Seller";
}

export function isSellerOperator(current_user) {
    return current_user && current_user.roles_names == "Seller operador";
}