import moment from 'moment-timezone';

export const formattedDateDayAndMonth = (string_date) => {
  if (string_date == null) return '';
  const date = moment(string_date).format("DD/MM");
  return date !== 'Invalid date' ? date : 'Fecha inválida';
}

export const formattedDateDDMMYYYY = (date_object) => {
  if (date_object == null) return '';
  const date = moment(date_object).format("DD/MM/YYYY");
  return date !== 'Invalid date' ? date : 'Fecha inválida';
}

export const formattedDateForDB = (date_object) => {
  if (date_object == null) return '';
  const date = moment(date_object).format("YYYY-MM-DD");
  return date !== 'Invalid date' ? date : 'Fecha inválida';
}

export const showTime24HourFormat = (time_object) => {
  if (time_object == null) return '';
  const time = moment(time_object).format("kk:mm");
  return time !== 'Invalid time' ? time : 'Fecha inválida';
}