import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import CustomCircularProgress from "../graphs/CustomCircularProgress";
import { 
  Box, 
  FormControl,
  Grid, 
  InputLabel,
  OutlinedInput, 
  MenuItem, 
  Select 
} from "@mui/material";

import { styled } from "@mui/material/styles";
import {
  isSuperAdmin,
  isAdmin,
  isRouter,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";

import CustomTable from "../table/CustomTable";
import { headers } from "../../constants/csrf";
import { flash_alert } from "components/App";

function Mailings(props) {
  const [mailings, setMailings] = useState([]);
  const [kinds, setKinds] = useState([]);

  const [kindFilter, setKindFilter] = useState("");
  const [filteredMailings, setFilteredMailings] = useState([]);

  const fetchMailings = async () => {
    await axios
      .get("/api/v1/mailings", {}, { headers: headers })
      .then(({ data }) => {
        setMailings(data.mailings);
        setFilteredMailings(data.mailings);
        setKinds(data.kinds);
        setKindFilter(Object.keys(data.kinds)[0]);
      })
      .catch((e) => {
        flash_alert(
          "Error",
          "Hubo un error al obtener los mailings",
          "danger"
        );
      });
  };

  useEffect(() => {
    fetchMailings();
  }, []);

  useEffect(() => {
    setFilteredMailings(mailings.filter((mailing) => mailing.kind === kindFilter));
  }, [kindFilter]);

  let headerTable = [
    { columnName: "Estado", dbName: "status", enabled: true },
    { columnName: "Activar", dbName: "enabled", enabled: true },
    { columnName: "Tipo", dbName: "translated_kind", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid container className="blog-container">
        <Grid item xs={6} sm={6} className="flex-left">
          <h1>Configuración mailing</h1>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h6 className="subtitle">
                Notificaciones al cliente final
              </h6>
            </Grid>

            <Grid item xs={12} sm={4} className="input-service-filter">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-service-filte">
                  Tipo de orden
                </InputLabel>
                <Select
                  value={kindFilter}
                  onChange={(e) => {
                    setKindFilter(e.target.value);
                  }}
                  input={<OutlinedInput id="select-service-filter" />}
                  IconComponent={() => (
                    <i className="material-icons-outlined">expand_more</i>
                  )}
                >
                  {
                    Object.entries(kinds).map(([kind, label], index) => (
                      <MenuItem key={index} value={kind}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={filteredMailings}
            headerTable={headerTable}
            fetchMailings={fetchMailings}
            selected={"none"}
            setSelected={"none"}
            openCollapseButton={false}
            openCollapseTooltip={"Editar entrada"}
            openCollapseIcon={
              <i className="material-icons small-text">edit</i>
            }
            collapseType={"mailing-detail"}
            hasPagination={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Mailings)
);
