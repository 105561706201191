import React, { useState } from "react";
import { headers } from "../../constants/csrf";
import axios from "axios";

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";

function DevolutionRow(props) {
  const {
    devolution,
    columns,
    color,
    completeButton,
    completeTooltip,
    completeIcon,
    fetchDevolutions,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleComplete = async () => {
    setLoading(true);
    await axios
      .put(
        `/api/v1/devolutions/${devolution["id"]}/complete`,
        {},
        { headers: headers }
      )
      .then((response) => {
        setLoading(false);
        fetchDevolutions();
        flash_alert(
          "Éxito",
          "Se ha actualizado correctamente la devolución",
          "success"
        );
      })
      .catch((e) => {
        setLoading(false);
        flash_alert(
          "Error",
          "Hubo un error al actualizar la devolución",
          "danger"
        );
      });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    {completeButton && (
                      <Tooltip title={completeTooltip} placement="top">
                        <IconButton
                          onClick={() => handleComplete()}
                          className="complete-incidence"
                        >
                          {completeIcon}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                >
                  {column.dbName.split("/").length === 3
                    ? devolution[column.dbName.split("/")[0]][
                        column.dbName.split("/")[1]
                      ][column.dbName.split("/")[2]]
                    : column.dbName.split("/").length === 2
                    ? devolution[column.dbName.split("/")[0]][
                        column.dbName.split("/")[1]
                      ]
                    : devolution[column.dbName]}
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
    </React.Fragment>
  );
}

export default DevolutionRow;
