import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { es } from "date-fns/locale";

import { headers } from "../../constants/csrf";
import CustomTable from "../table/CustomTable";
import { saveAs } from "file-saver";
import { strDateFormat } from "../../utils/functions";

function Withdrawals(props) {
  const [loading, setLoading] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);
  const [withdrawalsFiltered, setWithdrawalsFiltered] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);

  const fetchWithdrawals = async () => {
    await axios
      .get("/api/v1/withdrawals", {}, { headers: headers })
      .then((response) => {
        let date = new Date();
        let dateStr = strDateFormat(date);
        setWithdrawals(response.data);
        setWithdrawalsFiltered(response.data);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const downloadWithdrawals = async () => {
    setLoading(true);
    await axios
      .post(
        "/api/v1/withdrawals/download",
        {},
        { headers: headers, responseType: "arraybuffer" }
      )
      .then((response) => {
        setLoading(false);
        let file = new File([response.data], "withdrawals.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let fileName = "retiros.xlsx";
        saveAs(file, fileName);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error: ", e);
      });
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  let headerTable = [
    {
      columnName: "Seller",
      dbName: "withdrawal_address/company/business_name",
      enabled: true,
    },
    {
      columnName: "Dirección seller",
      dbName: "withdrawal_address/full_address",
      enabled: true,
    },
    {
      columnName: "Hora de retiro",
      dbName: "selected_hour",
      enabled: true,
    },
    { columnName: "Estado de retiro", dbName: "aasm_state", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Retiros</h1>
      </Grid>

      <Grid item xs={12} sm className="filters-container">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          {window.innerWidth > 768 ? (
            <DatePicker
              label="Fecha de retiro"
              value={dateFilter}
              onChange={(newValue) => {
                setDateFilter(newValue);
                setWithdrawalsFiltered(
                  newValue === null
                    ? withdrawals
                    : withdrawals.filter(function (a) {
                        return (
                          strDateFormat(new Date(a.withdrawal_date)) ===
                          strDateFormat(newValue)
                        );
                      })
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : (
            <MobileDatePicker
              label="Fecha de retiro"
              value={dateFilter}
              className="custom-mobile-datepicker"
              okText="Aceptar"
              cancelText="Cancelar"
              onChange={(newValue) => {
                setDateFilter(newValue);
                setWithdrawalsFiltered(
                  newValue === null
                    ? withdrawals
                    : withdrawals.filter(function (a) {
                        return (
                          strDateFormat(new Date(a.withdrawal_date)) ===
                          strDateFormat(newValue)
                        );
                      })
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </LocalizationProvider>
        <Button
          variant="outlined"
          onClick={() => {
            downloadWithdrawals();
          }}
        >
          {loading ? (
            <CircularProgress className="loading-btn" />
          ) : (
            <>
              <i className="material-icons">download</i>
              Exportar Retiros
            </>
          )}
        </Button>
      </Grid>

      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={withdrawalsFiltered}
            headerTable={headerTable}
            fetchWithdrawals={fetchWithdrawals}
            selected={"none"}
            setSelected={"none"}
            hasPagination={true}
            collapseType={"withdrawals-detail"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Withdrawals)
);
