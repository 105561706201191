import React, { useState, useEffect } from 'react';

import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import WithdrawalAddressForm from 'components/withdrawalAddresses/WithdrawalAddressForm';

import { format } from "rut.js";
import { RegionesYComunas, ecommerceOptions } from "../../constants/data";

const CompanyForm = ({ company, addresses, handleSubmit, changeAttribute, changeAddressAttribute, submitPressed, loading, isEditing }) => {
  const [selectedRegion, setSelectedRegion] = useState([]);

  useEffect(() => {
    if (company.region && company.region !== '') {
      setSelectedRegion(
        RegionesYComunas.filter((region) => region.name == company.region)
      );
    }
  }, [company.region]);

  return (
    <form
        className=""
        onSubmit={handleSubmit}
        autoComplete="off"
      >

        <Grid container className="custom-form-container">
          <Grid item xs={12} className="">
            <h4 className="fw-medium">Datos de la empresa</h4>
            <p className="red-text small-text">*Campos obligatorios</p>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && company.business_name == ""}
              variant="outlined"
              label="Razón social*"
              name="business_name"
              value={company.business_name}
              onChange={(e) => changeAttribute('business_name', e.target.value)}
              helperText={
                submitPressed && company.business_name == ""
                  ? "Campo obligatorio"
                  : ""
              }
              className="first-row-left"
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              error={submitPressed && company.rut == ""}
              variant="outlined"
              label="RUT*"
              name="rut"
              value={company.rut}
              onChange={(e) => changeAttribute('rut', format(e.target.value))}
              inputProps={{ maxLength: 12 }}
              helperText={
                submitPressed && company.rut == ""
                  ? "Campo obligatorio"
                  : ""
              }
              className="first-row-right"
              disabled={isEditing}
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Dirección de facturación</h6>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10 flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && company.region == ""}
            >
              <InputLabel htmlFor="select-region">
                Selecciona tu región*
              </InputLabel>
              <Select
                value={company.region}
                onChange={(e) => {
                  changeAttribute('commune', '');
                  changeAttribute('region', e.target.value);
                }}
                input={<OutlinedInput id="select-region" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {RegionesYComunas.map((region, index) => (
                  <MenuItem key={index} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              {
                submitPressed && 
                company.region == "" && 
                <FormHelperText>Campo obligatorio</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10 flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              disabled={company.region == ""}
              error={submitPressed && company.commune == ""}
            >
              <InputLabel htmlFor="select-commune">
                Selecciona tu comuna*
              </InputLabel>
              <Select
                value={company.commune}
                onChange={(e) => changeAttribute('commune', e.target.value)}
                input={<OutlinedInput id="select-commune" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {
                  selectedRegion.length == 0 ? 
                  <MenuItem value={"Santiago"}>{"Santiago"}</MenuItem>
                  : 
                  (
                    selectedRegion[0].communes.map((commune, index) => (
                      <MenuItem key={index} value={commune}>
                        {commune}
                      </MenuItem>
                    ))
                  )
                }
              </Select>
              {submitPressed && company.commune == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && company.street == ""}
              variant="outlined"
              label="Calle*"
              name="calle"
              value={company.street}
              onChange={(e) => changeAttribute('street', e.target.value)}
              helperText={
                submitPressed && company.street == "" ? 
                "Campo obligatorio"
                : 
                ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-l-10 pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && company.street_number == ""}
              variant="outlined"
              type="number"
              label="Número*"
              name="street_number"
              value={company.street_number}
              onChange={(e) => changeAttribute('street_number', e.target.value)}
              helperText={
                submitPressed && company.street_number == ""
                  ? "Campo obligatorio"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-l-10">
            <TextField
              fullWidth
              variant="outlined"
              label="Depto/Casa"
              name="streetOther"
              value={company.street_other}
              onChange={(e) => changeAttribute('street_other', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Selecciona tu plataforma e-commerce</h6>
          </Grid>

          <Grid item xs={12} sm={6} className="flex-left">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && company.ecommerce == ""}
            >
              <InputLabel htmlFor="select-ecommerce">
                Selecciona tu plataforma*
              </InputLabel>
              <Select
                value={company.ecommerce}
                onChange={(e) => changeAttribute('ecommerce', e.target.value)}
                input={<OutlinedInput id="select-ecommerce" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {

                  Object.entries(ecommerceOptions).map(([ecommerce, label]) => (
                    <MenuItem key={ecommerce} value={ecommerce}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                submitPressed && 
                company.ecommerce == "" && 
                <FormHelperText>Campo obligatorio</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Divider />

          <React.Fragment>
            {
              addresses.map((address, index) => (
                <WithdrawalAddressForm
                  key={index}
                  address={address}
                  changeAttribute={changeAddressAttribute}
                  submitPressed={submitPressed}
                  formTitle={`Dirección de retiro ${index + 1}`}
                  addressIndex={index}
                  isMandatory={index === 0}
                />

              ))
            }
          </React.Fragment>

          <Divider />

          <Grid item xs={12} sm={12}>
            <h6 className="fw-medium">Horarios de retiro</h6>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              id="time"
              label="Horario de retiro"
              type="time"
              value={company.withdrawal_hour}
              onChange={(e) => changeAttribute('withdrawal_hour', e.target.value)}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              id="time2"
              label="Horario de retiro 2"
              type="time"
              value={company.withdrawal_hour_2}
              onChange={(e) => changeAttribute('withdrawal_hour_2', e.target.value)}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>

          <Grid item xs={12} className="flex-left direction-column">
            <h6 className="fw-medium">Configuración mailing</h6>
            <Switch
              className="mg-t-30"
              checked={company.can_manage_mailing}
              disabled={loading}
              onChange={(e) => changeAttribute('can_manage_mailing', e.target.value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>          

          <Grid item xs={12} className="flex-left">
            <Button
              disabled={loading}
              id="user-save"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              {
                loading ? 
                <CircularProgress className="loading-btn" /> 
                :
                isEditing ? "Actualizar" : "Crear compañía"
              }
            </Button>
          </Grid>
        </Grid>
      </form>
  );
}

export default CompanyForm;