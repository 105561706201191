import React, { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { fetchFileNames } from "../../utils/functions";
import CustomDropzone from "../site/CustomDropzone";
import * as EmailValidator from "email-validator";
import InputMask from "react-input-mask";

import { requestStates } from "../../constants/data";

const EditRequestForm = ({ request, handleUpdate, handleCancel, loading, comunas}) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [files, setFiles] = useState([]);
  const [submitPressed, setSubmitedPressed] = useState(false);
  const [requestToEdit, setRequestToEdit] = useState({ ...request})

  const onDrop = useCallback((inputFiles) => {
    inputFiles.map((file) => {
      setFiles((files) =>
        !fetchFileNames(files).includes(file.name) ? [...files, file] : files
      );
    });
  }, []);

  const handleDelete = (index) => {
    setFiles(files.filter((_file, fileIndex) => fileIndex !== index));
  };

  const changeAttribute = (attribute, newValue) => {
    setRequestToEdit({...requestToEdit, [attribute]: newValue});
  }

  const buildRequestData = () => {
    const requestData = new FormData();
    requestData.append('reference_id', requestToEdit.reference_id);
    requestData.append('recipient', requestToEdit.recipient);
    requestData.append('address', requestToEdit.address);
    requestData.append('comuna_name', requestToEdit.comuna.name);
    requestData.append('notes', requestToEdit.notes);
    requestData.append('contact_name', requestToEdit.contact_name);
    requestData.append('contact_phone', requestToEdit.contact_phone);
    requestData.append('packages', requestToEdit.packages);
    requestData.append('weight', requestToEdit.weight);
    requestData.append('measures', requestToEdit.measures);

    return requestData;
  }

  const handleSubmit = () => {
    if (requestToEdit.reference_id?.trim() &&
        requestToEdit.recipient?.trim() &&
        requestToEdit.address?.trim() &&
        requestToEdit.comuna.name?.trim() &&
        requestToEdit.notes?.trim() &&
        requestToEdit.contact_name?.trim() &&
        requestToEdit.contact_phone?.trim() &&
        requestToEdit.packages?.trim() &&
        requestToEdit.weight?.trim() &&
        requestToEdit.measures?.trim()) {

      handleUpdate(buildRequestData(), files, selectedStatus);
    }
    else {
      setSubmitedPressed(true);
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={6} sm={6} className="">
        <FormControl
          fullWidth
          variant="outlined"
          className="dropdown-update-status"
        >
          <InputLabel id="dropdown-update-status-label">
            Seleccionar estado
          </InputLabel>
          <Select
            label="Actualiza el estado"
            labelId="dropdown-update-status-label"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            IconComponent={ExpandMoreIcon}
          >
            {
              requestStates.filter((state) => state.dbName !== request["aasm_state"])
                           .map((state, index) => (
                <MenuItem
                  className="dropdown-update-status-item"
                  key={index}
                  value={state.dbName}
                >
                  {state.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        
        <div>
          <p className="mg-0 flex-left super-small-text">
            Puedes cargar archivos jpg, png o pdf
          </p>
          <CustomDropzone
            onDrop={onDrop}
            accept={"image/png, image/jpeg, application/pdf"}
          />
          <Grid item className="custom-stack-container">
            <Stack
              direction="row"
              spacing={1}
              className="custom-stack"
            >
              {
                files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.path}
                    onDelete={() => handleDelete(index)}
                  />
                ))
              }
            </Stack>
          </Grid>
        </div>
        <div>
          <p className="fw-medium super-small-text">
            Archivos cargados
          </p>
          <Grid container className="preview-images">
            <Grid item xs={12}>
              {
                files.map((file, index) => {
                  const objectUrl = URL.createObjectURL(file);
                  return file.path.includes(".pdf") ? (
                    <i
                      key={`pdf-${index}`}
                      className="material-icons"
                    >
                      picture_as_pdf
                    </i>
                  ) : (
                    <img
                      className="preview-item"
                      src={objectUrl}
                      alt={file.path}
                      key={`image-${index}`}
                    />
                  );
                })
              }
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Grid container className="edit-request-form-container">
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.reference_id?.trim()}
              variant="outlined"
              label="Id Referencia*"
              name="reference_id"
              value={requestToEdit.reference_id}
              onChange={(e) => changeAttribute('reference_id', e.target.value)}
              helperText={submitPressed && !requestToEdit.reference_id?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.recipient?.trim()}
              variant="outlined"
              label="Destinatario*"
              name="recipient"
              value={requestToEdit.recipient}
              onChange={(e) => changeAttribute('recipient', e.target.value)}
              helperText={submitPressed && !requestToEdit.recipient?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.address?.trim()}
              variant="outlined"
              label="Dirección*"
              name="address"
              value={requestToEdit.address}
              onChange={(e) => changeAttribute('address', e.target.value)}
              helperText={submitPressed && !requestToEdit.address?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <Autocomplete
              fullWidth
              id="comunas-select"
              options={comunas}
              getOptionLabel={(comuna) => comuna.name}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Comuna" 
                  helperText={submitPressed && !requestToEdit.comuna && "Campo obligatorio"}
                  error={submitPressed && !requestToEdit.comuna}
                />
              }
              value={requestToEdit.comuna}
              onChange={(event, newValue) => changeAttribute('comuna', newValue)}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.notes?.trim()}
              variant="outlined"
              label="Notas*"
              name="notes"
              value={requestToEdit.notes}
              onChange={(e) => changeAttribute('notes', e.target.value)}
              helperText={submitPressed && !requestToEdit.notes?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.contact_name?.trim()}
              variant="outlined"
              label="Nombre de contacto*"
              name="contactPhone"
              value={requestToEdit.contact_name}
              onChange={(e) => changeAttribute('contact_name', e.target.value)}
              helperText={submitPressed && !requestToEdit.contact_name?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <InputMask
              mask="(+56) 9 9999 9999"
              value={requestToEdit.contact_phone}
              onChange={(e) => changeAttribute('contact_phone', e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  error={submitPressed && !requestToEdit.contact_phone?.trim()}
                  variant="outlined"
                  label="Número de contacto*"
                  name="contactPhone"
                  helperText={submitPressed && !requestToEdit.contact_phone?.trim() && "Campo obligatorio"}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={
                (submitPressed && requestToEdit.contact_email == "") ||
                (requestToEdit.contact_email != "" &&
                  !EmailValidator.validate(requestToEdit.contact_email))
              }
              variant="outlined"
              label="Correo de contacto*"
              name="contactEmail"
              value={requestToEdit.contact_email}
              onChange={(e) => changeAttribute('contact_email', e.target.value)}
              helperText={
                submitPressed && requestToEdit.contact_email == ""
                  ? "Campo obligatorio"
                  : requestToEdit.contact_email != "" &&
                    !EmailValidator.validate(requestToEdit.contact_email)
                  ? "Formato incorrecto"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.packages?.trim()}
              variant="outlined"
              label="Bultos*"
              name="packages"
              value={requestToEdit.packages}
              onChange={(e) => changeAttribute('packages', e.target.value)}
              helperText={submitPressed && !requestToEdit.packages?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.weight?.trim()}
              variant="outlined"
              label="Peso*"
              name="weight"
              value={requestToEdit.weight}
              onChange={(e) => changeAttribute('weight', e.target.value)}
              helperText={submitPressed && !requestToEdit.weight?.trim() && "Campo obligatorio"}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              fullWidth
              error={submitPressed && !requestToEdit.measures?.trim()}
              variant="outlined"
              label="Medidas*"
              name="measures"
              value={requestToEdit.measures}
              onChange={(e) => changeAttribute('measures', e.target.value)}
              helperText={submitPressed && !requestToEdit.measures?.trim() && "Campo obligatorio"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flex-left">
        <Button
          disabled={loading}
          variant="outlined"
          onClick={ handleCancel }
        >
          Cancelar edición
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          {
            loading ? 
            <CircularProgress className="loading-btn" />
            : 
            "Actualizar datos"
          }
        </Button>
      </Grid>
    </React.Fragment>
  )
}

export default EditRequestForm;