import React from "react";
import moment from 'moment-timezone';

import { Checkbox, Chip, TableCell, TableRow } from "@mui/material";

import Indication from '../../components/requests/Indication';

function ForRoutingRow(props) {
  const { request, columns, color, isSelected, selected, dateFilter } = props;

  const isItemSelected = isSelected(request);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        <TableCell padding="checkbox" className="row-checkbox error-padding">
          <div className="container-error-checkbox">
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => props.handleClick(event, request)}
            />
          </div>
        </TableCell>

        {columns.map((column, index) => {
          return (
            <TableCell
              component="th"
              scope="row"
              key={`${column.columnName}-${index}`}
            >
              {
                column.dbName.split("/").length === 2 ? 
                  request[column.dbName.split("/")[0]][column.dbName.split("/")[1]]
                  : 
                  column.dbName === 'delivery_zone' ? 
                    request.delivery_zone &&
                    <span
                      className={`delivery-zone-chip ${request.delivery_zone.colour}`}
                    >
                      { request.delivery_zone.name }
                    </span>
                    : 
                    column.dbName === 'withdrawal_date' ? 
                      moment(request.withdrawal_date).format("DD/MM/YYYY")
                      :
                      request[column.dbName] == null ? 
                        ""
                        :
                        request[column.dbName]
              }
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
}

export default ForRoutingRow;
