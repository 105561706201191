import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { Button, Grid } from "@mui/material";

import { headers } from "../../constants/csrf";
import CustomTable from "../table/CustomTable";

import {
  isSuperAdmin,
  isAdmin,
  isSeller,
  isSellerOperator,
} from "../../constants/user_functions";
import { updatePendingCases } from "../../stores/functions";
import { saveAs } from "file-saver";

function Cases(props) {
  const dispatch = useDispatch();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCases = async () => {
    await axios
      .get("/api/v1/customer_supports", {}, { headers: headers })
      .then((response) => {
        if (isSuperAdmin(props.current_user) || isAdmin(props.current_user)) {
          dispatch(
            updatePendingCases(
              response.data.filter(function (item) {
                return item.aasm_state !== "completed";
              })
            )
          );
        }
        setCases(response.data);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const downloadHistory = async () => {
    setLoading(true);
    await axios
      .post(
        "/api/v1/customer_supports/download_history",
        {},
        { headers: headers, responseType: "arraybuffer" }
      )
      .then((response) => {
        setLoading(false);
        let file = new File([response.data], "historial.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let fileName = "historial.xlsx";
        saveAs(file, fileName);
      })
      .catch((e) => {
        setLoading(false);
        flash_alert(
          "Error",
          "Hubo un error al descargar el historial de casos",
          "danger"
        );
      });
  };

  useEffect(() => {
    fetchCases();
  }, []);

  let headerTableActive = [
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    {
      columnName: "Destinatario",
      dbName: "recipient",
      enabled:
        isSeller(props.current_user) || isSellerOperator(props.current_user),
    },
    {
      columnName: "Cliente",
      dbName: "company/business_name",
      enabled: isSuperAdmin(props.current_user) || isAdmin(props.current_user),
    },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Motivo", dbName: "reason", enabled: true },
    { columnName: "Fecha de creación", dbName: "created_at", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  let headerTableCompleted = [
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    {
      columnName: "Cliente",
      dbName: "company/business_name",
      enabled: true,
    },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Motivo", dbName: "reason", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Atención al cliente</h1>
      </Grid>
      <Grid item xs={12}>
        <h6 className="subtitle">
          {isSeller(props.current_user) || isSellerOperator(props.current_user)
            ? "Historial de casos"
            : "Casos por gestionar"}
        </h6>
      </Grid>

      {(isSeller(props.current_user) ||
        isSellerOperator(props.current_user)) && (
        <Grid item xs={12} className="flex-right">
          <Link to={"/customer_supports/new_case"} id={"link-to-new-case"}>
            <Button variant="contained">Nuevo caso</Button>
          </Link>
        </Grid>
      )}

      <Grid container className="custom-table-container">
        {(isSeller(props.current_user) ||
          isSellerOperator(props.current_user)) && (
          <Grid item xs={12} className="flex-left">
            <Button
              onClick={() => downloadHistory()}
              id="create-new-user"
              variant="text"
              size="small"
            >
              <i className="material-icons">download</i>Descargar historial
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={`flex-center ${
            (isSeller(props.current_user) ||
              isSellerOperator(props.current_user)) &&
            "mg-t-0"
          }`}
        >
          <CustomTable
            current_user={props.current_user}
            data={cases.filter(function (item) {
              return item.aasm_state !== "completed";
            })}
            headerTable={headerTableActive}
            fetchCases={fetchCases}
            selected={"none"}
            setSelected={"none"}
            openCollapseButton={true}
            openCollapseTooltip={"Ver más"}
            openCollapseIcon={
              <i className="material-icons-outlined">add_circle_outline</i>
            }
            canUpdateState={true}
            deleteButton={true}
            deleteTooltip={"Eliminar caso"}
            deleteIcon={<i className="material-icons-outlined">cancel</i>}
            collapseType={"case-detail"}
            rowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
            rowsPerPage={10}
            hasPagination={true}
          />
        </Grid>
      </Grid>

      {(isSuperAdmin(props.current_user) || isAdmin(props.current_user)) && (
        <>
          <Grid item xs={12}>
            <h6 className="subtitle">Casos gestionados</h6>
          </Grid>

          <Grid item xs={12} className="flex-left mg-t-40">
            <Button
              onClick={() => downloadHistory()}
              id="create-new-user"
              variant="text"
              size="small"
            >
              <i className="material-icons">download</i>Descargar historial
            </Button>
          </Grid>
        </>
      )}

      {(isSuperAdmin(props.current_user) || isAdmin(props.current_user)) && (
        <Grid container className="custom-table-container">
          <Grid item xs={12} className="flex-center mg-t-0">
            <CustomTable
              current_user={props.current_user}
              data={cases.filter(function (item) {
                return item.aasm_state === "completed";
              })}
              headerTable={headerTableCompleted}
              fetchCases={fetchCases}
              selected={"none"}
              setSelected={"none"}
              openCollapseButton={true}
              openCollapseTooltip={"Ver detalles"}
              openCollapseIcon={
                <i className="material-icons-outlined">add_circle_outline</i>
              }
              reOpenButton={true}
              reOpenTooltip={"Reabrir caso"}
              reOpenIcon={
                <i className="material-icons-outlined">move_up</i>
              }
              canUpdateState={false}
              collapseType={"case-detail"}
              hasPagination={true}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Cases)
);
