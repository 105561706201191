import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { es } from "date-fns/locale";

import { headers } from "../../constants/csrf";
import { strDateFormat, plannedDateToDate, mapStatus } from "../../utils/functions";
import CustomTable from "../table/CustomTable";
import { saveAs } from "file-saver";
import { servicesTypes } from "../../constants/data";
import { formattedDateForDB } from "../../utils/timeFormat";

import { flash_alert } from "components/App";

import LoadOptionalSkillsDialog from '../controlPanel/LoadOptionalSkillsDialog';
import TableLoader from '../site/TableLoader';

function Requests(props) {
  const [requests, setRequests] = useState([]);
  const [requestsFiltered, setRequestsFiltered] = useState();
  const [loading, setLoading] = useState(false);

  const [serviceFilter, setServiceFilter] = useState("");
  const [search, setSearch] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [counters, setCounters] = useState({});
  const [counterLabels, setCounterLabels] = useState();

  const [servicesTypes, setServicesTypes] = useState([]);

  const [openLoadSkillsDialog, setOpenLoadSkillsDialog] = useState(false);

  const [errorOnRetrieveData, setErrorOnRetrieveData] = useState();
  const [comunas, setComunas] = useState([]);

  const searchParams = () => {
    return {
      start_date: formattedDateForDB(startDate), 
      end_date: formattedDateForDB(endDate), 
      search_query: search,
      kind: serviceFilter
    }
  }

  const fetchRequests = async () => {
    setRequestsFiltered();
    await axios
      .get("/api/v1/requests/active_requests", 
          {
            params: searchParams()
          }, 
          { headers: headers })
      .then(({ data }) => {
        setRequests(data.requests);
        setRequestsFiltered(data.requests);
        setServicesTypes(data.request_kinds);
        setCounters(data.counters);
        setCounterLabels(data.statuses_to_count);
        setErrorOnRetrieveData();
      })
      .catch(({ response }) => {
        console.log("error: ", response);
        setRequestsFiltered([]);
        setErrorOnRetrieveData('Ocurrió un error al obtener las órdenes');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportRequests = () => {
    setLoading(true);
    const body = new FormData();
    body.set("start_date", startDate);
    body.set("end_date", endDate);
    return axios({
      url: '/api/v1/requests/download_history.xlsx',
      method: 'POST',
      responseType: 'blob',
      data: searchParams(),
      headers: headers
    })
    .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `Historial Órdenes - ${formattedDateForDB(new Date())}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setCustomerRequests([]);
        setLoading(false);
    })
    .catch(({ response }) => {
        setLoading(false);
        console.log("error: ", response);
        flash_alert(
          "Error",
          "Ocurrió un error al descargar el historial",
          "danger"
        );
    });
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [startDate, endDate, serviceFilter]);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      fetchRequests();
    }, 2000);

    return () => clearTimeout(delaySearch);
  }, [search]);

  useEffect(() => {
    const fetchComunas = async () => {
      await axios.get("/api/v1/comunas")
                 .then(({ data }) => {
                   setComunas(data);
                 })
    }

    fetchComunas();
  }, []);

  let headerTable = [
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    { columnName: "Cliente", dbName: "business_name", enabled: true },
    { columnName: "Destinatario", dbName: "recipient", enabled: true },
    { columnName: "Dirección", dbName: "address", enabled: true },
    { columnName: "Comuna", dbName: "comuna_name", enabled: true },
    { columnName: "Fecha de retiro", dbName: "withdrawal_date", enabled: true },
    { columnName: "Tipo de solicitud", dbName: "translated_kind", enabled: true },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Conductor", dbName: "driver_name", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <LoadOptionalSkillsDialog
        open={ openLoadSkillsDialog }
        setOpen={ setOpenLoadSkillsDialog }
      />

      <Grid item xs={12}>
        <h1>Panel de control</h1>
      </Grid>

      <Grid item xs={12} sm className="flex-right mg-t-30">
        <Button
          variant="outlined"
          onClick={() => setOpenLoadSkillsDialog(true)}
        >
          Cargar habilidades
        </Button>
      </Grid>

      <Grid container>
        <Grid item xs={12} className="chip-btn-container">
          <Grid container className="flex-between">
            <Grid item>
              <Chip
                label={`${requestsFiltered ? requestsFiltered.length : 0} Solicitudes en total`}
                variant="contained"
              />
              {
                counterLabels && counterLabels.map((label) => (
                  <Chip
                    label={`${counters[label] || 0} ${mapStatus(label)}`}
                    variant="contained"
                    className={label}
                    key={label}
                  />
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm className="flex-left filters-container">
        <div className="row-container">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            {window.innerWidth > 768 ? (
              <DatePicker
                label="Fecha de inicio"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  if (endDate <= newValue) {
                    setEndDate(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField className="mg-r-10" {...params} />
                )}
                disabled={requestsFiltered === undefined}
              />
            ) : (
              <MobileDatePicker
                label="Fecha de inicio"
                value={startDate}
                className="custom-mobile-datepicker"
                okText="Aceptar"
                cancelText="Cancelar"
                onChange={(newValue) => {
                  setStartDate(newValue);
                  if (endDate <= newValue) {
                    setEndDate(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField className="mg-r-10" {...params} />
                )}
                disabled={requestsFiltered === undefined}
              />
            )}
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            {window.innerWidth > 768 ? (
              <DatePicker
                label="Fin"
                value={endDate}
                disabled={startDate === null || requestsFiltered === undefined}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                shouldDisableDate={(date) =>
                  startDate !== null
                    ? date < new Date(startDate.getTime() - 3600 * 1000 * 24)
                    : date < new Date()
                }
                renderInput={(params) => (
                  <TextField className="mg-l-10 mg-r-10" {...params} />
                )}
              />
            ) : (
              <MobileDatePicker
                label="Fin"
                value={endDate}
                disabled={startDate === null || requestsFiltered === undefined}
                className="custom-mobile-datepicker"
                okText="Aceptar"
                cancelText="Cancelar"
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField className="mg-l-10 mg-r-10" {...params} />
                )}
              />
            )}
          </LocalizationProvider>

          <Grid item xs={6} sm="auto" className="input-service-filter">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="select-service-filte">
                Tipo de servicio
              </InputLabel>
              <Select
                value={serviceFilter}
                onChange={(e) => {
                  setServiceFilter(e.target.value);
                  setSearch("");
                }}
                input={<OutlinedInput id="select-service-filter" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
                disabled={requestsFiltered === undefined}
              >
                {
                  Object.entries(servicesTypes).map(([kind, label], index) => (
                    <MenuItem key={index} value={kind}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <TextField
            variant="outlined"
            label="Buscar"
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="mg-l-10"
            disabled={requestsFiltered === undefined}
          />
        </div>
      </Grid>

      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-left mg-t-40">
          <Button
            onClick={() => exportRequests()}
            id="create-new-user"
            variant="text"
            size="small"
          >
          {
            loading ?
            <CircularProgress className="loading-btn" />
            :
            <React.Fragment>
              <i className="material-icons">download</i> Descargar historial
            </React.Fragment>
          }
          </Button>
        </Grid>
        <Grid item xs={12} className="flex-center mg-t-0">
          {
            requestsFiltered ?
            <React.Fragment>
            {
              errorOnRetrieveData ? 
              <Alert severity="error">{errorOnRetrieveData}</Alert>
              :
              <CustomTable
                data={requestsFiltered}
                search={search}
                filterByDate={startDate !== null && endDate !== null}
                headerTable={headerTable}
                fetchRequests={fetchRequests}
                selected={"none"}
                setSelected={"none"}
                openCollapseButton={true}
                openCollapseTooltip={"Ver más"}
                openCollapseIcon={
                  <i className="material-icons-outlined icon-actions-panel-test">add_circle_outline</i>
                }
                collapseType={"control-panel-detail"}
                hasPagination={true}
                editable={true}
                comunas={comunas}
              />
            }
            </React.Fragment>
            :
            <TableLoader />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(Requests)
);
