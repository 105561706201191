import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Chip,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers, headersFiles } from "../../constants/csrf";
import CustomDropzone from "../site/CustomDropzone";
import { checkImageDimensions } from "../../utils/functions";

function BlogRow(props) {
  const {
    current_user,
    blog,
    columns,
    color,
    openCollapseButton,
    openCollapseTooltip,
    openCollapseIcon,
    fetchBlogs,
  } = props;
  // Edit case
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(blog["title"]);
  const [url, setUrl] = useState(blog["url"]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUpdateBlog = async () => {
    let requests = [];
    setLoading(true);

    const body = new FormData();
    body.set("title", title);
    body.set("url", url);

    requests.push(
      axios.put(`/api/v1/blogs/${blog["id"]}`, body, { headers: headers })
    );

    if (files.length === 1) {
      const body2 = new FormData();
      body2.append("file", files[0]);
      requests.push(
        axios.post(`/api/v1/blogs/${blog["id"]}/update_image`, body2, {
          headers: headersFiles,
        })
      );
    }

    await axios
      .all(requests)
      .then(() => {
        setLoading(false);
        setOpen(false);
        fetchBlogs();
        flash_alert("Éxito", "Blog actualizado con éxito", "success");
      })
      .catch((e) => {
        setLoading(false);
        flash_alert("Error", "Hubo un error al obtener los blogs", "danger");
      });
  };

  const onDrop = useCallback((inputFiles) => {
    let validator = false;
    if ([1, 2].includes(blog["ubication"])) {
      checkImageDimensions(inputFiles[0], 650, 330).then(function (validator) {
        if (validator) {
          if (inputFiles.length > 1) {
            flash_alert("Error", "Solo puedes cargar un archivo", "danger");
            setFiles([inputFiles[0]]);
          } else {
            setFiles(inputFiles);
          }
        } else {
          flash_alert(
            "Error",
            "Las dimensiones de la imagen deben ser 650x330",
            "danger"
          );
        }
      });
    } else {
      checkImageDimensions(inputFiles[0], 450, 270).then(function (validator) {
        if (validator) {
          if (inputFiles.length > 1) {
            flash_alert("Error", "Solo puedes cargar un archivo", "danger");
            setFiles([inputFiles[0]]);
          } else {
            setFiles(inputFiles);
          }
        } else {
          flash_alert(
            "Error",
            "Las dimensiones de la imagen deben ser 450x270",
            "danger"
          );
        }
      });
    }
  }, []);

  const handleDelete = (index) => {
    setFiles(files.filter((_file, fileIndex) => fileIndex !== index));
  };

  const handleCancelEdit = () => {
    setTitle(blog["title"]);
    setUrl(blog["url"]);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    {openCollapseButton && (
                      <Tooltip title={openCollapseTooltip} placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          {openCollapseIcon}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                  className={
                    column.dbName === "ubication" ? "blog-ubication-icon" : ""
                  }
                >
                  {column.dbName === "image" ? (
                    <img
                      className="blog-image"
                      src={blog["files_urls"][0]}
                      alt="imagen goex"
                    />
                  ) : column.dbName === "url" ? (
                    <a className="blog-link" href={blog[column.dbName]}>
                      {blog[column.dbName]}
                    </a>
                  ) : column.dbName === "ubication" ? (
                    <Tooltip
                      arrow
                      placement="top"
                      classes={{
                        tooltip: "blog-ubication-tooltip",
                        arrow: "blog-ubication-arrow",
                      }}
                      title={
                        <React.Fragment>
                          <Grid container className="blog-ubication-container">
                            <Grid item xs={6} className="mg-t-30">
                              <Box
                                className={
                                  blog[column.dbName] === 1 ? "selected" : ""
                                }
                                sx={{
                                  width: "auto",
                                  height: 42,
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} className="mg-t-30">
                              <Box
                                className={
                                  blog[column.dbName] === 2 ? "selected" : ""
                                }
                                sx={{
                                  width: "auto",
                                  height: 42,
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} className="mg-t-30">
                              <Box
                                className={
                                  blog[column.dbName] === 3 ? "selected" : ""
                                }
                                sx={{
                                  width: "auto",
                                  height: 32,
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} className="mg-t-30">
                              <Box
                                className={
                                  blog[column.dbName] === 4 ? "selected" : ""
                                }
                                sx={{
                                  width: "auto",
                                  height: 32,
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} className="mg-t-30">
                              <Box
                                className={
                                  blog[column.dbName] === 5 ? "selected" : ""
                                }
                                sx={{
                                  width: "auto",
                                  height: 32,
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    >
                      <i className="material-icons-outlined">computer</i>
                    </Tooltip>
                  ) : blog[column.dbName] == null ? (
                    ""
                  ) : (
                    blog[column.dbName]
                  )}
                </TableCell>
              );
            }
          }
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="my-request-collapse"
          >
            <Box>
              <Grid container className="direction-row">
                <Grid item xs={6} sm={6} className="pd-r-10">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Título"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={6} className="pd-l-10">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="URL asociada"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={6} className="blog-edit-container">
                  <p className="mg-t-30 flex-left super-small-text">
                    {`Puedes cargar un archivo jpg o png - Tamaño sugerido ${
                      [1, 2].includes(blog["ubication"]) ? "650x330" : "450x270"
                    }`}
                  </p>
                  <CustomDropzone
                    onDrop={onDrop}
                    hasRestriction={true}
                    restrictionType={[1, 2].includes(blog["ubication"]) ? 1 : 2}
                    accept={"image/png, image/jpeg"}
                  />
                  <Grid item className="custom-stack-container">
                    <Stack direction="row" spacing={1} className="custom-stack">
                      {files.map((file, index) => {
                        return (
                          <Chip
                            key={index}
                            label={file.path}
                            onDelete={() => handleDelete(index)}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  className="blog-edit-container pd-l-50"
                >
                  <p className="mg-t-30 fw-medium super-small-text">
                    Archivo cargado
                  </p>
                  <Grid container className="preview-images">
                    <Grid item xs={12}>
                      {files.map((file, index) => {
                        const objectUrl = URL.createObjectURL(file);
                        return (
                          <img
                            className="preview-item"
                            src={objectUrl}
                            alt={file.path}
                            key={`image-${index}`}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="flex-left mg-b-20 mg-t-30">
                  <Button
                    disabled={loading}
                    variant="outlined"
                    onClick={() => handleCancelEdit()}
                  >
                    {"Cancelar"}
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    onClick={() => handleUpdateBlog()}
                  >
                    {loading ? (
                      <CircularProgress className="loading-btn" />
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default BlogRow;
