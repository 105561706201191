import React, { useState, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import axios from "axios";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Checkbox,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { mapStatus, validRequest } from "../../utils/functions";
import InputMask from "react-input-mask";
import { OpenStreetMapProvider } from "leaflet-geosearch";

function PendingRequestRow(props) {
  const {
    request,
    columns,
    color,
    fetchRequests,
    openCollapseTooltip,
    openCollapseIcon,
    selected,
    deleteRequestTooltip,
    deleteRequestIcon,
    comunas
  } = props;

  const isItemSelected = props.isSelected(request);
  const comunasNames = comunas.map((comuna) => comuna.name);

  // Edit pending request
  const [referenceId, setReferenceId] = useState("");
  const [recipient, setRecipient] = useState("");
  const [address, setAddress] = useState("");
  const [comuna, setComuna] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [notes, setNotes] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [packages, setPackages] = useState("");
  const [weight, setWeight] = useState("");
  const [measures, setMeasures] = useState("");
  const [open, setOpen] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);

  const {
    ready,
    suggestions: { loading, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ["CL"],
      },
    },
    debounce: 300,
  });

  let typingTimer;
  let doneTypingInterval = 500;

  const osmProvider = new OpenStreetMapProvider({
    params: {
      "accept-language": "cl",
      countrycodes: "cl",
    },
  });

  const searchAddress = async () => {
    setLoadingResults(true);
    if (
      process.env.NODE_ENV !== undefined &&
      process.env.NODE_ENV === "production" &&
      window.location.hostname === "app.go-ex.io"
    ) {
      setValue(address);
    } else {
      let results = await osmProvider.search({ query: address });
      setSearchResults(
        results.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.label === value.label)
        )
      );
      setLoadingResults(false);
    }
  };

  useEffect(() => {
    setSearchResults(data);
    setLoadingResults(false);
  }, [data]);

  const handlePressEdit = (request) => {
    setRecipient(request.recipient === null ? "" : request.recipient);
    setAddress(request.address === null ? "" : request.address);
    setComuna(request.comuna_name === null ? "" : request.comuna_name);
    setReferenceId(request.reference_id === null ? "" : request.reference_id);
    setNotes(request.notes === null ? "" : request.notes);
    setContactName(request.contact_name === null ? "" : request.contact_name);
    setContactPhone(
      request.contact_phone === null ? "" : request.contact_phone
    );
    setContactEmail(
      request.contact_email === null ? "" : request.contact_email
    );
    setPackages(request.packages === null ? "" : request.packages);
    setWeight(request.weight === null ? "" : request.weight);
    setMeasures(request.measures === null ? "" : request.measures);
  };

  const handleCancelEdit = () => {
    setOpen(false);
    setReferenceId("");
    setRecipient("");
    setAddress("");
    setNotes;
    setContactName("");
    setContactPhone("");
    setContactEmail("");
    setPackages("");
    setWeight("");
    setMeasures("");
  };

  const handleUpdateRequest = (id) => {
    setSubmitPressed(true);
    if (
      recipient !== "" &&
      address !== "" &&
      comuna !== "" &&
      referenceId !== ""
    ) {
      setLoadingApi(true);
      const body = new FormData();
      body.set("reference_id", referenceId);
      body.set("recipient", recipient);
      body.set("address", address);
      body.set("comuna_name", comuna);
      body.set("notes", notes);
      body.set("contact_name", contactName);
      body.set("contact_phone", contactPhone);
      body.set("contact_email", contactEmail);
      body.set("packages", packages);
      body.set("weight", weight);
      body.set("measures", measures);
      return axios
        .put(`/api/v1/requests/${id}`, body, { headers: headers })
        .then((response) => {
          setLoadingApi(false);
          setOpen(false);
          fetchRequests();
          flash_alert(
            "Éxito",
            "Se ha actualizado correctamente la solicitud",
            "success"
          );
        })
        .catch((e) => {
          setLoadingApi(false);
          setOpen(false);
          flash_alert(
            "Error",
            "Hubo un error al actualizar la solicitud, intenta de nuevo",
            "danger"
          );
        });
    } else {
      setOpen(false);
      flash_alert(
        "Error",
        "No se puede actualizar la solicitud. Campos incompletos o con error",
        "danger"
      );
    }
  };

  const handleConfirmDelete = async () => {
    setLoadingApi(true);
    await axios
      .post(
        `/api/v1/requests/${request.id}/delete_request`,
        {},
        { headers: headers }
      )
      .then((response) => {
        setLoadingApi(false);
        setOpen(false);
        setOpenDialogDelete(false);
        fetchRequests();
        flash_alert(
          "Éxito",
          "Se ha eliminado correctamente la solicitud",
          "success"
        );
      })
      .catch((e) => {
        setLoadingApi(false);
        setOpen(false);
        setOpenDialogDelete(false);
        flash_alert(
          "Error",
          "Hubo un error al eliminar la solicitud, intenta de nuevo",
          "danger"
        );
      });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth={"sm"}
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
      >
        <p>
          Estás a punto de eliminar la solicitud{" "}
          <span className="fw-medium purple-text">{request.reference_id}</span>,{" "}
          <span className="fw-medium">¿deseas continuar?</span>
        </p>
        <DialogActions>
          <Button
            disabled={loadingApi}
            variant="outlined"
            onClick={() => setOpenDialogDelete(false)}
            className="mg-r-10"
          >
            {"Volver atrás"}
          </Button>
          <Button
            disabled={loadingApi}
            variant="contained"
            onClick={() => handleConfirmDelete()}
            className="mg-l-10"
          >
            {loadingApi ? (
              <CircularProgress className="loading-btn" />
            ) : (
              "Continuar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {isItemSelected !== null && (
          <TableCell
            padding="checkbox"
            className={
              !validRequest(request)
                ? "row-checkbox"
                : "row-checkbox error-padding"
            }
          >
            <div className="container-error-checkbox">
              <>
                {!validRequest(request) && (
                  <Tooltip
                    title="Esta solicitud posee campos incompletos o erróneos"
                    placement="top"
                  >
                    <i className="material-icons-outlined red-text request-with-error">error</i>
                  </Tooltip>
                )}
                <Checkbox
                  checked={isItemSelected}
                  onClick={(event) => props.handleClick(event, request)}
                  className="select-request"
                />
              </>
            </div>
          </TableCell>
        )}
        {columns.map((column, index) => {
          if (column.columnName == "Acciones") {
            return (
              <TableCell key={`action-${index}`} className="actions-cell">
                <div className="actions-container">
                  <Tooltip title={openCollapseTooltip} placement="top">
                    <IconButton
                      color="primary"
                      aria-label="edit request"
                      onClick={() => {
                        if (!open) {
                          setSubmitPressed(false);
                          handlePressEdit(request);
                        }
                        setOpen(!open);
                      }}
                    >
                      {openCollapseIcon}
                    </IconButton>
                  </Tooltip>
                  {selected.length === 0 && (
                    <Tooltip title={deleteRequestTooltip} placement="top">
                      <IconButton
                        aria-label="delete request"
                        onClick={() => setOpenDialogDelete(true)}
                        className="delete-request"
                      >
                        {deleteRequestIcon}
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            );
          } 
          else {
            return (
              <TableCell
                component="th"
                scope="row"
                key={`${column.columnName}-${index}`}
              >
                {
                  column.dbName.split("/").length === 2 ? 
                  request[column.dbName.split("/")[0]][column.dbName.split("/")[1]]
                  : 
                  request[column.dbName] == null ? 
                  ""
                  : 
                  column.columnName === "Estado" ? 
                  <p>{mapStatus(request[column.dbName])}</p>
                  : 
                  request[column.dbName]}
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Grid container className="edit-request-container">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Destinatario*"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                        error={submitPressed && recipient === ""}
                        helperText={
                          submitPressed && recipient == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Dirección*"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        error={submitPressed && address === ""}
                        helperText={submitPressed && address == "" && "Campo obligatorio"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        fullWidth
                        id="comunas-select"
                        options={comunasNames}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            label="Comuna" 
                            helperText={submitPressed && comuna == "" && "Campo obligatorio"}
                            error={submitPressed && comuna == ""}
                          />
                        }
                        value={comuna}
                        onChange={(event, newValue) => setComuna(newValue)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Id Referencia*"
                        value={referenceId}
                        onChange={(e) => setReferenceId(e.target.value)}
                        error={submitPressed && referenceId === ""}
                        helperText={
                          submitPressed && referenceId == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Notas*"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Nombre de contacto*"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        mask="(+56) 9 9999 9999"
                        value={contactPhone}
                        onChange={(e) => setContactPhone(e.target.value)}
                        disabled={false}
                        maskChar=" "
                      >
                        {() => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Teléfono de contacto*"
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Correo de contacto*"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Bultos*"
                        value={packages}
                        onChange={(e) => setPackages(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Peso*"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Medidas*"
                        value={measures}
                        onChange={(e) => setMeasures(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} className="flex-left">
                      <Button
                        disabled={loadingApi}
                        variant="outlined"
                        onClick={() => handleCancelEdit()}
                      >
                        {"Cancelar edición"}
                      </Button>
                      <Button
                        disabled={loadingApi}
                        variant="contained"
                        onClick={() => handleUpdateRequest(request.id)}
                      >
                        {loadingApi ? (
                          <CircularProgress className="loading-btn" />
                        ) : (
                          "Actualizar datos"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default PendingRequestRow;
