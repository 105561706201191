import React from 'react';

import { Paper } from "@mui/material";

const RequestTypeOption = ({ requestKind, updateSelection, isSelected }) => {

  return (
    <Paper
      elevation={0}
      className={`select-option ${ isSelected && 'selected'}`}
      onClick={() => updateSelection(requestKind)}
    >
      <img src={requestKind.icon} />
      <p>{ requestKind.name }</p>
    </Paper>
  );
}

export default RequestTypeOption;