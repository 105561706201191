const UPDATE_REQUEST_TYPE = "UPDATE_REQUEST_TYPE";
const UPDATE_ECOMMERCE = "UPDATE_ECOMMERCE";
const UPDATE_PENDING_INCIDENCES = "UPDATE_PENDING_INCIDENCES";
const ADD_OPEN_INCIDENCE = "ADD_OPEN_INCIDENCE";
const REMOVE_OPEN_INCIDENCE = "REMOVE_OPEN_INCIDENCE";
const UPDATE_PENDING_CASES = "UPDATE_PENDING_CASES";
const UPDATE_MAILING_EDIT = "UPDATE_MAILING_EDIT";

export const updateRequestType = (type) => {
  return (dispatch) => {
    dispatch(updateRequestTypeRequest(type));
  };
};

export const updateRequestTypeRequest = (data) => {
  return {
    type: UPDATE_REQUEST_TYPE,
    payload: data,
  };
};

export const updateEcommerce = (ecommerce) => {
  return (dispatch) => {
    dispatch(updateEcommerceRequest(ecommerce));
  };
};

export const updateEcommerceRequest = (data) => {
  return {
    type: UPDATE_ECOMMERCE,
    payload: data,
  };
};

export const updatePendingIncidences = (incidences) => {
  return (dispatch) => {
    dispatch(updatePendingIncidencesRequest(incidences));
  };
};

export const updatePendingIncidencesRequest = (data) => {
  return {
    type: UPDATE_PENDING_INCIDENCES,
    payload: data.length,
  };
};

export const addOpenIncidence = (orderNumber) => {
  return (dispatch) => {
    dispatch(addOpenIncidenceRequest(orderNumber));
  };
};

export const addOpenIncidenceRequest = (orderNumber) => {
  return {
    type: ADD_OPEN_INCIDENCE,
    payload: orderNumber,
  };
};

export const removeOpenIncidence = (orderNumber) => {
  return (dispatch) => {
    dispatch(removeOpenIncidenceRequest(orderNumber));
  };
};

export const removeOpenIncidenceRequest = (orderNumber) => {
  return {
    type: REMOVE_OPEN_INCIDENCE,
    payload: orderNumber,
  };
};

export const updatePendingCases = (cases) => {
  return (dispatch) => {
    dispatch(updatePendingCasesRequest(cases));
  };
};

export const updatePendingCasesRequest = (data) => {
  return {
    type: UPDATE_PENDING_CASES,
    payload: data.length,
  };
};

export const updateMailingEdit = (cases) => {
  return (dispatch) => {
    dispatch(updateMailingEditRequest(cases));
  };
};

export const updateMailingEditRequest = (mailing) => {
  return {
    type: UPDATE_MAILING_EDIT,
    payload: mailing,
  };
};