import React, {useCallback, useState} from 'react';
import axios from "axios";

import {
  Alert,
  Button,
  Grid,
  Paper,
  Chip,
  Stack,
  TextField,
} from "@mui/material";

import CustomDropzone from "../site/CustomDropzone";

import { headers, headersFiles } from "../../constants/csrf";
import { fetchFileNames } from "../../utils/functions";
import { flash_alert } from "components/App";

const LoadKindPrices = (props) => {
  const companyId = props.match.params.id;

  const [files, setFiles] = useState([]);
  const [successLoaded, setSuccessLoaded] = useState();

  const handleDelete = (path) => {
    setFiles(files.filter((file) => file.path !== path));
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setFiles((files) =>
        !fetchFileNames(files).includes(file.name) ? [...files, file] : files
      );
    });
  }, []);

  const handleSubmit = () => {
    files.map((file, index) => {
      const body = new FormData();
      body.append("file", file);

      return axios.post(`/api/v1/companies/${companyId}/load_kind_and_delivery_zone_prices`, 
                        body, 
                        {headers: headersFiles})
                  .then(({ data }) => {
                    setSuccessLoaded(data.success_loaded);
                    data.errors.map((error) => (
                      flash_alert(
                        "Error",
                        error,
                        "error"
                      )
                    ));
                  })
                  .catch(({ response }) => {
                    response.data.map((error) => (
                      flash_alert(
                        "Error",
                        error,
                        "error"
                      )
                    ));
                  })
    })
  }

  return (
    <Grid container className="load-kind-prices-container">
      <Grid item xs={12}>
        <h1>Carga los precios según la Compañía, el Tipo de orden y la Zona de Envío</h1>
      </Grid>

      <Grid item xs={12} className="flex-center">
        <CustomDropzone
          accept={
            "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          width={"550px"}
          marginTop={"41px"}
          onDrop={onDrop}
        />
      </Grid>
      <Grid item xs={12} className="flex-center">
        <Grid item className="custom-stack-container">
          <Stack direction="row" spacing={1} className="custom-stack">
            {files.map((file, index) => {
              return (
                <Chip
                  key={index}
                  label={file.path}
                  onDelete={() => handleDelete(file.path)}
                />
              );
            })}
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} className="flex-center">
        {
          successLoaded  && 
          successLoaded > 0 &&
          <Alert severity="success">Se cargaron {successLoaded} precios con éxito</Alert>
        }
      </Grid>

      <Grid item xs={12} sm={12} className="flex-center">
        <Button
          variant="contained"
          onClick={ handleSubmit }
          disabled={files.lenght === 0}
        >
          Cargar archivo
        </Button>
      </Grid>
    </Grid>
  );
}

export default LoadKindPrices;