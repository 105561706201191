import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

import NewDeliveryZone from "../configuration/NewDeliveryZone";
import CustomTable from "../table/CustomTable";

const DeliveryZones = () => {
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [showNewDeliveryZoneForm, setShowNewDeliveryZoneForm] = useState(false);

  const [redirectToComunas, setRedirectToComunas] = useState(false);

  useEffect(() => {

    fetchDeliveryZones();
  }, []);

  const fetchDeliveryZones = () => {
    return axios.get("/api/v1/delivery_zones")
                .then(({ data }) => {
                  setDeliveryZones(data);
                })
                .catch((e) => {
                  console.log("error: ", e);
                });
  }

  const newZoneHandler = () => {
    setShowNewDeliveryZoneForm(true);
  }

  const headerTable = [
    { columnName: "Zona", dbName: "number", enabled: true },
    { columnName: "Nombre", dbName: "name", enabled: true },
    { columnName: "Color", dbName: "colour", enabled: true },
    { columnName: "Costo", dbName: "price", enabled: true },
  ];

  return (
    <Grid container >
      <Grid item xs={12}>
        <h1>Configuración Zonas</h1>

        <Button
          variant="contained"
          onClick={newZoneHandler}
        >
          Crear Zona
        </Button>
      </Grid>

      {
        showNewDeliveryZoneForm &&
        <NewDeliveryZone
          closeModalForm={() => setShowNewDeliveryZoneForm(false)}
          fetchDeliveryZones={fetchDeliveryZones}
        />
      }

      <Grid item xs={12}>
        <Link 
          to={"/delivery_zones/comunas"} 
          id={"link-to-delivery-zones-comunas"}
          className="form-float"
        >
          <Button 
            variant="outlined"
          >
            Comunas
            <i className="material-icons-outlined">chevron_right</i>
          </Button>
        </Link>

        <CustomTable
          data={deliveryZones}
          headerTable={headerTable}
          selected={"none"}
          setSelected={"none"}
          openCollapseButton={false}
          openCollapseTooltip={"Ver más"}
          openCollapseIcon={
            <i className="material-icons-outlined">add_circle_outline</i>
          }
          hasPagination={true}
          collapseType={"delivery-zones-table"}
        />
      </Grid>
    </Grid>
  );
}

export default DeliveryZones;