import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import configureStore from "../../stores/configureStore";

import { Button, Grid } from "@mui/material";

import logo from "../../../assets/images/logo-goex.svg";

function RegistrationSuccess(props) {
  const store_r = configureStore(props.current_user);

  function backToLogin() {
    window.location = "/";
  }

  return (
    <Provider store={store_r}>
      <React.Fragment>
        <Grid container className="success-main-container">
          <Grid item xs={12} className="succes-logo-container">
            <div className="success-logo">
              <img src={logo} alt="Logo Go-EX" />
            </div>
          </Grid>
          <Grid item xs={12} className="flex-center">
              <Grid container className="success-form-container">
                <span className="confeti-icon">🎉</span>
                <Grid item xs={12} sm={12}>
                  <h4 className="fw-medium">Registro completado</h4>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <p>
                    ¡Muy bien! Ya estás un paso más cerca de ser parte de Go-Ex.
                    Estamos validando tus datos, te avisaremos cuando tu cuenta
                    esté activada.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={() => backToLogin()}>
                    Volver al home
                  </Button>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </Provider>
  );
}

export default RegistrationSuccess;
