import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Chip,
  Stack,
  TextField,
} from "@mui/material";
import { flash_alert } from "components/App";
import SuccessUpload from "components/requests/SuccessUpload";
import SelectRequestType from "./SelectRequestType";
import CustomDropzone from "../site/CustomDropzone";
import InputMask from "react-input-mask";

import * as EmailValidator from "email-validator";
import { updateEcommerce } from "../../stores/functions";
import sendSVG from "../../../assets/images/envio.svg";
import prestashopSVG from "../../../assets/images/prestashop.svg";
import woocommerceSVG from "../../../assets/images/woocommerce.svg";
import jumpsellerSVG from "../../../assets/images/jumpseller.svg";
import shopifySVG from "../../../assets/images/shopify.svg";
import goexSVG from "../../../assets/images/carga-goex.svg";
import manualSVG from "../../../assets/images/carga-manual.svg";
import { goexFormat } from "../../constants/excel_formats";
import { headers, headersFiles } from "../../constants/csrf";
import { fetchFileNames } from "../../utils/functions";

function SelectRequestEcommerce(props) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [files, setFiles] = useState([]);
  const [redirectBack, setRedirectBack] = useState(false);
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const  ecommerce = props.current_user.ecommerce;

  // Manual form
  const [referenceId, setReferenceId] = useState("");
  const [recipient, setRecipient] = useState("");
  const [address, setAddress] = useState("");
  const [comuna, setComuna] = useState("");
  const [notes, setNotes] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [packages, setPackages] = useState("");
  const [weight, setWeight] = useState("");
  const [measures, setMeasures] = useState("");
  const [submitPressed, setSubmitPressed] = useState(false);

  const [comunas, setComunas] = useState([]);

  async function handleSubmit() {
    setSubmitPressed(true);
    let creation_obj =
      selectedOption === "Carga Go-Ex"
        ? "/create_goex"
        : selectedOption === "Prestashop"
        ? "/create_prestashop"
        : selectedOption === "Woocommerce"
        ? "/create_woocommerce"
        : selectedOption === "Carga manual"
        ? "/create_manual"
        : "/create_shopify";
    if (
      creation_obj === "/create_manual" &&
      referenceId !== "" &&
      recipient !== "" &&
      address !== "" &&
      comuna !== "" &&
      notes !== "" &&
      contactName !== "" &&
      contactPhone !== "" &&
      contactEmail !== "" &&
      EmailValidator.validate(contactEmail) &&
      packages !== "" &&
      weight !== "" &&
      measures !== ""
    ) {
      setLoading(true);
      const body = new FormData();
      body.set("reference_id", referenceId);
      body.set("recipient", recipient);
      body.set("address", address);
      body.set("comuna_name", comuna.name);
      body.set("notes", notes);
      body.set("contact_name", contactName);
      body.set("contact_phone", contactPhone);
      body.set("contact_email", contactEmail);
      body.set("packages", packages);
      body.set("weight", weight);
      body.set("measures", measures);
      body.set("kind", props.requestType.kind);
      body.set("file_kind", selectedOption);
      return axios
        .post(`/api/v1/requests${creation_obj}`, body, {
          headers: headers,
        })
        .then((response) => {
          setLoading(false);
          setRedirectSuccess(true);
          flash_alert(
            "Subida con éxito",
            "La carga manual ha sido cargada con éxito",
            "success"
          );
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      if (files.length > 0) {
        let uploadSuccess = false;
        let filesProccessed = 0;
        files.map((file, index) => {
          setLoading(true);
          const body = new FormData();
          body.append("file", file);
          axios
            .post(`/api/v1/requests${creation_obj}`, body, {
              headers: headersFiles,
            })
            .then((response) => {
              filesProccessed += 1;
              if (response.data.code === "NO_DATA") {
                setLoading(false);
                flash_alert(
                  "Error",
                  selectedOption === "Carga Go-Ex"
                    ? "Completa la planilla descargada para cargar solicitudes"
                    : `El archivo ${file.path} no posee datos`,
                  "error"
                );
                if (uploadSuccess && filesProccessed === files.length) {
                  setRedirectSuccess(true);
                }
              } else {
                uploadSuccess = true;
                let axiosRequests = [];
                let body = new FormData();
                body.set("kind", props.requestType.kind);
                body.set("file_kind", selectedOption);
                response.data.map((request) => {
                  axiosRequests.push(
                    axios.put(`/api/v1/requests/${request.id}`, body, {
                      headers: headers,
                    })
                  );
                });
                axios
                  .all(axiosRequests)
                  .then(() => {
                    setLoading(false);
                    flash_alert(
                      "Subida con éxito",
                      `Las solicitudes del archivo ${file.path} han sido cargadas con éxito`,
                      "success"
                    );
                    if (uploadSuccess && filesProccessed === files.length) {
                      setRedirectSuccess(true);
                    }
                  })
                  .catch((e) => {
                    setLoading(false);
                    flash_alert(
                      "Error",
                      "Error al subir las solicitudes, intenta denuevo",
                      "danger"
                    );
                  });
              }
            })
            .catch((e) => {
              flash_alert(
                "Error",
                "Error al subir las solicitudes, intenta denuevo",
                "danger"
              );
              if (uploadSuccess && filesProccessed === files.length) {
                setRedirectSuccess(true);
              }
            });
        });
      }
    }
  }

  const handleDelete = (path) => {
    setFiles(files.filter((file) => file.path !== path));
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setFiles((files) =>
        !fetchFileNames(files).includes(file.name) ? [...files, file] : files
      );
    });
  }, []);

  useEffect(() => {
    const fetchComunas = async () => {
      await axios.get("/api/v1/comunas")
                 .then(({ data }) => {
                   setComunas(data);
                 })
    }

    fetchComunas();
  }, []);

  useEffect(() => {
    if (props.requestType === undefined || props.requestType.kind === "") {
      setRedirectBack(true);
    }
  }, [props.requestType]);

  const clearData = () => {
    setFiles([]);
    setReferenceId("");
    setRecipient("");
    setAddress("");
    setComuna("");
    setNotes("");
    setContactName("");
    setContactPhone("");
    setContactEmail("");
    setPackages("");
    setWeight("");
    setMeasures("");
    setSubmitPressed(false);
  };

  let redirect_check = [];
  if (redirectBack) {
    redirect_check.push(
      <Redirect key="redirect-to-select-type" to="/requests/new/type">
        <SelectRequestType />
      </Redirect>
    );
  }
  if (redirectSuccess) {
    redirect_check.push(
      <Redirect key="redirect-to-success-upload" to="/requests/new/success">
        <SuccessUpload />
      </Redirect>
    );
  }

  return (
    <React.Fragment>
      {redirect_check}
      <Grid item xs={12}>
        <h1>Carga de solicitudes</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/requests/new/type" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <Grid container className="select-ecommerce-container">
        <Grid item xs={12} sm={12} className="flex-center">
          <h5>Selecciona el tipo de archivo a cargar</h5>
        </Grid>
        <Grid item xs={12} sm={12} className="flex-center">
          <p className="subtitle">
            Esto es para indicarte el tipo de archivo que debes cargar
          </p>
        </Grid>
        <Grid sx={{ flexGrow: 1 }} container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item className="option first-row">
                <Paper
                  elevation={0}
                  className={`select-option ${
                    selectedOption === ecommerce ? "selected" : ""
                  }`}
                  onClick={() => {
                    dispatch(updateEcommerce(ecommerce));
                    clearData();
                    setSelectedOption(ecommerce);
                  }}
                >
                  <img
                    src={
                      ecommerce == "Prestashop"
                        ? prestashopSVG
                        : ecommerce == "Woocommerce"
                        ? woocommerceSVG
                        : ecommerce == "Jumpseller"
                        ? jumpsellerSVG
                        : shopifySVG
                    }
                  />
                  <p>{ecommerce}</p>
                </Paper>
              </Grid>
              <Grid item className="option first-row">
                <Paper
                  elevation={0}
                  className={`select-option ${
                    selectedOption === "Carga Go-Ex" ? "selected" : ""
                  }`}
                  onClick={() => {
                    dispatch(updateEcommerce("Carga Go-Ex"));
                    clearData();
                    setSelectedOption("Carga Go-Ex");
                  }}
                >
                  <img src={goexSVG} />
                  <p>Carga Go-Ex</p>
                </Paper>
              </Grid>
              <Grid item className="option second-row">
                <Paper
                  elevation={0}
                  className={`select-option ${
                    selectedOption === "Carga manual" ? "selected" : ""
                  }`}
                  onClick={() => {
                    dispatch(updateEcommerce("Carga manual"));
                    clearData();
                    setSelectedOption("Carga manual");
                  }}
                >
                  <img src={manualSVG} />
                  <p>Carga manual</p>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {selectedOption !== "" && (
          <Grid container className="container-upload-files">
            <Grid item xs={12} className="flex-center">
              <h5>Carga tu archivo</h5>
            </Grid>
            <Grid item xs={12} className="flex-center">
              <p className="mg-0">
                {"Seleccionaste cargar un archivo de "}
                <span className="fw-medium">{props.requestType.name}</span>
                {" tipo "}
                <span className="fw-medium">{selectedOption}</span>
              </p>
            </Grid>
            {selectedOption === "Carga Go-Ex" && (
              <Grid item xs={12} className="flex-center download-container">
                <p>{"Si no tienes a mano el archivo, puedes "}</p>
                <Link
                  to="/templates/carga_planilla_goex.xlsx"
                  target="_blank"
                  download
                >
                  <p>{" descargar la plantilla"}</p>
                </Link>
              </Grid>
            )}

            {selectedOption === "Carga manual" ? (
              <Grid container>
                <Grid item xs={12} className="flex-center">
                  <Grid container className="manual-upload-container">
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <TextField
                        fullWidth
                        error={submitPressed && referenceId == ""}
                        variant="outlined"
                        label="Id Referencia*"
                        name="reference_id"
                        value={referenceId}
                        onChange={(e) => setReferenceId(e.target.value)}
                        helperText={
                          submitPressed && referenceId == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-l-10">
                      <TextField
                        fullWidth
                        error={submitPressed && recipient == ""}
                        variant="outlined"
                        label="Destinatario*"
                        name="recipient"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                        helperText={
                          submitPressed && recipient == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <TextField
                        fullWidth
                        error={submitPressed && address == ""}
                        variant="outlined"
                        label="Dirección*"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        helperText={
                          submitPressed && address == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-l-10">
                      <Autocomplete
                        fullWidth
                        id="comunas-select"
                        options={comunas}
                        getOptionLabel={(comuna) => comuna.name}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            label="Comuna" 
                            helperText={submitPressed && comuna == "" && "Campo obligatorio"}
                            error={submitPressed && comuna == ""}
                          />
                        }
                        value={comuna}
                        onChange={(event, newValue) => setComuna(newValue)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <TextField
                        fullWidth
                        error={submitPressed && notes == ""}
                        variant="outlined"
                        label="Notas*"
                        name="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        helperText={
                          submitPressed && notes == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-l-10">
                      <TextField
                        fullWidth
                        error={submitPressed && contactName == ""}
                        variant="outlined"
                        label="Nombre de contacto*"
                        name="contactPhone"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                        helperText={
                          submitPressed && contactName == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <InputMask
                        mask="(+56) 9 9999 9999"
                        value={contactPhone}
                        onChange={(e) => setContactPhone(e.target.value)}
                        disabled={false}
                        maskChar=" "
                      >
                        {() => (
                          <TextField
                            fullWidth
                            error={submitPressed && contactPhone == ""}
                            variant="outlined"
                            label="Número de contacto*"
                            name="contactPhone"
                            helperText={
                              submitPressed && contactPhone == ""
                                ? "Campo obligatorio"
                                : ""
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-l-10">
                      <TextField
                        fullWidth
                        error={
                          (submitPressed && contactEmail == "") ||
                          (contactEmail != "" &&
                            !EmailValidator.validate(contactEmail))
                        }
                        variant="outlined"
                        label="Correo de contacto*"
                        name="contactEmail"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                        helperText={
                          submitPressed && contactEmail == ""
                            ? "Campo obligatorio"
                            : contactEmail != "" &&
                              !EmailValidator.validate(contactEmail)
                            ? "Formato incorrecto"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <TextField
                        fullWidth
                        error={submitPressed && packages == ""}
                        variant="outlined"
                        label="Bultos*"
                        name="packages"
                        value={packages}
                        onChange={(e) => setPackages(e.target.value)}
                        helperText={
                          submitPressed && packages == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-l-10">
                      <TextField
                        fullWidth
                        error={submitPressed && weight == ""}
                        variant="outlined"
                        label="Peso*"
                        name="weight"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        helperText={
                          submitPressed && weight == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="pd-r-10">
                      <TextField
                        fullWidth
                        error={submitPressed && measures == ""}
                        variant="outlined"
                        label="Medidas*"
                        name="measures"
                        value={measures}
                        onChange={(e) => setMeasures(e.target.value)}
                        helperText={
                          submitPressed && measures == ""
                            ? "Campo obligatorio"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} className="flex-center">
                  <CustomDropzone
                    accept={
                      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    width={"550px"}
                    marginTop={"41px"}
                    onDrop={onDrop}
                  />
                </Grid>
                <Grid item xs={12} className="flex-center">
                  <Grid item className="custom-stack-container">
                    <Stack direction="row" spacing={1} className="custom-stack">
                      {files.map((file, index) => {
                        return (
                          <Chip
                            key={index}
                            label={file.path}
                            onDelete={() => handleDelete(file.path)}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} className="flex-center">
              <Button
                type="submit"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <CircularProgress className="loading-btn" />
                ) : (
                  "Cargar archivo"
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
  requestType: (state) => state.requestType,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(SelectRequestEcommerce)
);
