import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CircularProgress } from "@mui/material";

function Counter({ current_user }) {
  const [hoursLeft, setHoursLeft] = useState("");
  const [minutesLeft, setMinutesLeft] = useState("");
  const [isSameDay, setIsSameDay] = useState(false);

  const calculateTimeLeft = () => {
    let wh_array = current_user.company.withdrawal_hours[0].split(":")

    // Substract 15 minutes based on https://oferus.atlassian.net/browse/GE-276
    let countDownDate = new Date().setHours(
      parseInt(wh_array[0], 10),
      parseInt(wh_array[1], 10) - 15,
      0
    );
    let now = new Date().getTime();
    let distance = countDownDate - now;
    let hoursLeftInt = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutesLeftInt = Math.floor(
      (distance % (1000 * 60 * 60)) / (1000 * 60)
    );
    setHoursLeft(hoursLeftInt.toString());
    setMinutesLeft(minutesLeftInt.toString());
    setIsSameDay(hoursLeftInt * 60 + minutesLeftInt > 0); // Minutos restantes es mayor a cero
  };

  useEffect(() => {
    setInterval(() => {
      calculateTimeLeft();
    }, 1000);
  }, []);

  return (
    <div className="counter-container">
      {hoursLeft === "" ? (
        <CircularProgress className="loading-counter" />
      ) : isSameDay ? (
        <p className="mg-0 small-text">
          ⏳ Tienes
          <span className="fw-medium">
            {" " + hoursLeft + "hr " + minutesLeft + "min "}
          </span>
          para que tus envíos sean <span className="fw-medium">Same Day</span>
        </p>
      ) : (
        <p className="mg-0 small-text">
          📦 Carga tus solicitudes ahora y los entregamos mañana
        </p>
      )}
    </div>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(Counter);
