import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { headers, headersFiles } from "../../constants/csrf";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { mapStatus } from "../../utils/functions";

import { formattedDateDDMMYYYY } from '../../utils/timeFormat';

import EditRequestForm from '../requests/EditRequestForm';

const ControlPanelRow = ({ editable, request, columns, color, openCollapseButton, 
                           openCollapseTooltip, openCollapseIcon, fetchRequests, comunas }) => {
  // Edit case
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitPressed, setSubmitPressed] = useState(false);

  const lastSrResponse =
    request["sr_responses"] && request["sr_responses"].length > 0
      ? JSON.parse(request["sr_responses"][request["sr_responses"].length - 1])
      : null;

  const handleUpdateRequest = (requestData, files, selectedStatus) => {
    //setSubmitPressed(true);
    setLoading(true);
    let requests = [];

    requests.push(axios.put(
      `/api/v1/requests/${request.id}`, 
      requestData, 
      { headers: headersFiles })
    );

    files.map((file) => {
      let body = new FormData();
      body.append("file", file);
      requests.push(
        axios.post(
          `/api/v1/requests/${request["order_number"]}/upload_file`,
          body,
          {headers: headersFiles}
        )
      );
    });
    if (selectedStatus !== "") {
      requests.push(
        axios.put(
          `/api/v1/requests/${request["order_number"]}/${selectedStatus}/admin_update_state`,
          {},
          { headers: headers }
        )
      );
    }
    return axios
      .all(requests)
      .then(() => {
        setLoading(false);
        setIsEdit(false);
        fetchRequests();
        flash_alert("Éxito", "Solicitud actualizada con éxito", "success");
      })
      .catch((e) => {
        setLoading(false);
        setIsEdit(false);
        flash_alert(
          "Error",
          "Error al actualizar la solicitud, intenta denuevo",
          "danger"
        );
      });
  };

  const handlePressEdit = () => {
    setIsEdit(true);
    setFiles([]);
    setSelectedStatus("");
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    acceptedFiles.map((file) => {
      setFiles((files) => [...files, file]);
    });
  }, [acceptedFiles]);

  const prettyFormatDate = (status_detail)=>{
    // Se recibe el status_detail con formato de timestamps
    // por lo que se ajusta a formato fecha dd/mm/yyyy
    const status_detail_array = status_detail.split("-");
    if(status_detail_array.length > 3){ // Condicion de mal formato fecha
      const date = status_detail.split(" ")[0].split("-")  
      const new_date_format = date[2] + "/" + date[1] + "/" + date[0]
      return (new_date_format + " -" + status_detail_array[3] + "-" + status_detail_array[4])
    }
    return status_detail
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    {openCollapseButton && (
                      <Tooltip title={openCollapseTooltip} placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          {openCollapseIcon}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              );
            } 
            else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                  className={
                    column.columnName === "Estado"
                      ? `status-${request[column.dbName]}`
                      : ""
                  }
                >
                  {
                    column.dbName.split("/").length === 2 ? 
                    request[column.dbName.split("/")[0]]?.[column.dbName.split("/")[1]]
                    : 
                    request[column.dbName] == null ? 
                    ""
                    : column.columnName === "Estado" ? 
                    <p>{mapStatus(request["aasm_state"])}</p>
                    : 
                    column.columnName === "Fecha de retiro" ? 
                    formattedDateDDMMYYYY(request[column.dbName])
                    : 
                    request[column.dbName]
                  }
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="my-request-collapse"
          >
            <Box>
              <Grid container className="my-request-container flex-start">
                <Grid
                  item
                  xs={12}
                  className="flex-right incidence-edit-button"
                >                
                  <a 
                    href={`/api/v1/requests/${request.id}.pdf`}
                    target="_blank"
                  >
                    <i className="material-icons">download</i>
                    Descargar etiqueta
                  </a>
                  {
                    editable &&
                    <a onClick={() => handlePressEdit()}>
                      <i className="material-icons">edit</i>
                      Editar solicitud
                    </a>
                  }
                </Grid>
                {
                  isEdit ? 
                  <EditRequestForm
                    request={ request }
                    handleUpdate={ handleUpdateRequest }
                    handleCancel={ handleCancelEdit }
                    loading={ loading }
                    comunas={comunas}
                  />
                  :
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="sr-detail-element pd-r-10"
                    >
                      <p className="fw-medium super-small-text">
                        Id referencia
                      </p>
                      <p>{request["reference_id"]}</p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="sr-detail-element pd-l-10"
                    >
                      <p className="fw-medium super-small-text">
                        Historial de estados
                      </p>
                      {
                        request["sr_status_detail"].map((status_detail, index) => {
                          return (
                            <p key={`status_detail-${index}`}>
                              {prettyFormatDate(status_detail)}
                            </p>
                          );
                        })
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm
                      className="sr-detail-element sr-photos-container pd-l-10"
                    >
                      <Grid container>
                        <Grid item xs={12} className="sr-photos-header">
                          <p className="fw-medium super-small-text">
                            Archivos cargados
                          </p>
                        </Grid>
                        <Grid item xs={12} className="sr-photos-content">
                          <Grid container>
                            {
                              request['sr_photos'] &&
                              request['sr_photos'].map((file, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm
                                    key={`sr-file-${request["order_number"]}-${index}`}
                                  >
                                    <img src={file} alt="Logo Go-EX" />
                                  </Grid>
                                );
                              })
                            }
                            {
                              request["files_urls"] &&
                              request["files_urls"].map((file, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm
                                    key={`goex-file-${request["order_number"]}-${index}`}
                                  >
                                    <img src={file} alt="Logo Go-EX" />
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm className="sr-detail-element pd-r-10">
                      <p className="fw-medium super-small-text">
                        Comentarios adicionales
                      </p>
                      <p>
                        {
                          lastSrResponse?.checkout_comment || 
                          lastSrResponse?.no_completed_detail ||
                          lastSrResponse?.completed_receiver_rut
                        }
                      </p>
                    </Grid>
                    <Grid item xs={12} sm className="sr-detail-element pd-l-10">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className="sr-detail-element"
                        >
                          <p className="fw-medium super-small-text">
                            Observaciones
                          </p>
                          <p>
                            {
                              lastSrResponse?.checkout_observation || 
                              lastSrResponse?.no_completed_type ||
                              lastSrResponse?.completed_receiver_name 
                            }
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>{" "}
                  </>
                }
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ControlPanelRow;
