import React from "react";

import {
  TableCell,
  TableRow,
} from "@mui/material";

import { mapStatus } from "../../utils/functions"

function NormalRow(props) {
  const {
    request,
    columns,
    color,
  } = props;

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          return (
            <TableCell
              component="th"
              scope="row"
              key={`${column.columnName}-${index}`}
            >
              {column.dbName.split("/").length === 2
                ? request[column.dbName.split("/")[0]][
                    column.dbName.split("/")[1]
                  ]
                : request[column.dbName] == null
                ? ""
                : column.columnName === 'Estado' ? mapStatus(request[column.dbName]) : request[column.dbName]}
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
}

export default(NormalRow)