import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import NewUser from "components/users/NewUser";
import { Button } from "@mui/material";
class UsersLinks extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Link className="mdl-navigation__link" id="users-new" to="/users/new">
          <Button variant="contained" color="primary">
            <i className="material-icons-outlined">add</i> New User
          </Button>
        </Link>
      </React.Fragment>
    );
  }
}

export default UsersLinks;
