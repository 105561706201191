import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { flash_alert } from "components/App";

import UsersActive from "components/users/UsersActive";
import UserForm from "components/users/UserForm";
import WithdrawalAddressForm from 'components/withdrawalAddresses/WithdrawalAddressForm';

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
  LinearProgress,
  Pagination,
} from "@mui/material";

import {
  DataGrid,
  GridOverlay,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import InputMask from "react-input-mask";
import { isSuperAdmin } from "../../constants/user_functions";
import { validate, format } from "rut.js";
import * as EmailValidator from "email-validator";
import { ecommerces, RegionesYComunas } from "../../constants/data";
import { headers } from "../../constants/csrf";
import { buildCompanyFormData, getWithdrawalAddressesArray } from "helpers/requestHelpers";

function Profile(props) {
  let current_user = props.current_user;
  const [socialReason, _setSocialReason] = useState('');
  const [rut, _setRut] = useState('');
  const [role, _setRole] = useState(current_user.roles_names);
  const [region, setRegion] = useState(current_user.region);
  const [commune, setCommune] = useState(current_user.commune);
  const [street, setStreet] = useState(current_user.street);
  const [streetNumber, setStreetNumber] = useState(current_user.street_number);
  const [streetOther, setStreetOther] = useState(current_user.street_other);
  const [fullname, setFullname] = useState(current_user.fullname);
  const [charge, setCharge] = useState(current_user.charge);
  const [email, setEmail] = useState(current_user.email);
  const [phoneNumber, setPhoneNumber] = useState(current_user.phone_number);
  const [ecommerce, _setEcommerce] = useState(current_user.ecommerce);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAddresses, setLoadingAddresses] = useState(false);

  const [addresses, setAddresses] = useState([]);

  async function handleCancel(){
    cleanData();
    fetchAddresses();
  };

  const cleanData = () => {
    setFullname(current_user.fullname);
    setCharge(current_user.charge);
    setEmail(current_user.email);
    setPhoneNumber(current_user.phone_number);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitPressed(true);
    if (
      region !== "" &&
      commune !== "" &&
      street !== "" &&
      streetNumber !== "" &&
      fullname !== "" &&
      charge !== "" &&
      email !== "" &&
      EmailValidator.validate(email) &&
      phoneNumber !== "" &&
      addresses[0].region !== "" &&
      addresses[0].commune !== "" &&
      addresses[0].street !== "" &&
      addresses[0].street_number !== ""
    ) {
      setLoading(true);

      const company = {
        region: region,
        commune: commune,
        street: street,
        street_number: streetNumber,
        street_other: streetOther,
        ecommerce: ecommerce,
        // withdrawal_hour
        // withdrawal_hour_2
        // can_manage_mailing
      }

      const user = {
        id: current_user.id,
        fullname: fullname,
        charge: charge,
        email: email,
        phoneNumber: phoneNumber,
      }
      
      const companyData = buildCompanyFormData(company, addresses, user);

      await axios.put(`/api/v1/companies/${current_user.company_id}`, companyData, {headers: headers})
                 .then(() => {
                   setLoading(false);
                   window.location.reload();
                   flash_alert(
                     "Actualizado!",
                     "El usuario se ha actualizado correctamente",
                     "success"
                   );
                 })
                 .catch((e) => {
                   setLoading(false);
                   flash_alert("Error", "Error al actualizar el usuario", "danger");
                 });

      let requests = [];
    }
  }

  const fetchAddresses = async () => {
    setLoadingAddresses(true);
    await axios
      .get(`/api/v1/companies/${props.current_user.company_id}/withdrawal_info`)
      .then(({ data }) => {
        setLoadingAddresses(false);

        _setSocialReason(data.company.business_name);
        _setRut(data.company.rut);

        setRegion(data.company.region);
        setCommune(data.company.commune);
        setStreet(data.company.street);
        setStreetNumber(data.company.street_number);
        setStreetOther(data.company.street_other);

        setAddresses(getWithdrawalAddressesArray(data.addresses));
      })
      .catch((e) => {
        setLoadingAddresses(false);
        flash_alert(
          "Error",
          "Error al obtener las direcciones de retiro",
          "danger"
        );
      });
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const selectedRegion = RegionesYComunas.filter((reg) => reg.name == region);

  const changeAddressAttribute = (attribute, newValue, addressIndex) => {
    const editedAddress = {...addresses[addressIndex], [attribute]: newValue}
    switch(addressIndex) {
      case 0:
        setAddresses([editedAddress, addresses[1], addresses[2]]);
        break;
      case 1:
        setAddresses([addresses[0], editedAddress, addresses[2]]);
        break;
      case 2:
        setAddresses([addresses[0], addresses[1], editedAddress]);
        break;
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Mi perfil</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/home" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <form className="flex-center my-profile-form" onSubmit={handleSubmit} autoComplete="off">
        <Grid container className="custom-form-container">
          <Grid item xs={12} sm={12} className="flex-left">
            <h5 className="fw-medium">Datos de contacto</h5>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && fullname == ""}
              variant="outlined"
              label="Nombre completo*"
              name="fullname"
              value={fullname}
              id='input-fullname'
              onChange={(e) => setFullname(e.target.value)}
              helperText={
                submitPressed && fullname == "" ? "Campo obligatorio" : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              error={submitPressed && charge == ""}
              variant="outlined"
              label="Cargo*"
              name="charge"
              value={charge}
              id='input-charge'
              onChange={(e) => setCharge(e.target.value)}
              helperText={
                submitPressed && charge == "" ? "Campo obligatorio" : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={
                (submitPressed && email == "") ||
                (email != "" && !EmailValidator.validate(email))
              }
              variant="outlined"
              label="Email*"
              name="email"
              value={email}
              id='input-email'
              onChange={(e) => setEmail(e.target.value)}
              helperText={
                submitPressed && email == ""
                  ? "Campo obligatorio"
                  : email != "" && !EmailValidator.validate(email)
                  ? "Formato incorrecto"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className="pd-l-10">
            <InputMask
              mask="(+56) 9 9999 9999"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  error={submitPressed && phoneNumber == ""}
                  variant="outlined"
                  label="Teléfono*"
                  name="phoneNumber"
                  id='input-phone-number'
                  helperText={
                    submitPressed && phoneNumber == ""
                      ? "Campo obligatorio"
                      : ""
                  }
                />
              )}
            </InputMask>
          </Grid>

          <Divider />

          <Grid item xs={12} sm={12} className="flex-left">
            <h5 className="fw-medium">Datos de la compañía</h5>
          </Grid>

          <Grid item xs={12} sm={12}>
            <p className="red-text small-text flex-right mg-0">
              *Campos obligatorios
            </p>
          </Grid>
          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              disabled={true}
              variant="outlined"
              label="Razón social*"
              name="socialReason"
              value={socialReason}
              className="first-row-left"
            />
          </Grid>
          <Grid item xs={12} sm={6} className="pd-l-10">
            <TextField
              fullWidth
              disabled={true}
              variant="outlined"
              label="RUT Empresa*"
              name="rut"
              value={rut}
              className="first-row-right"
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex-left">
            <h6 className="fw-medium">Dirección de facturación</h6>
          </Grid>
          <Grid item xs={12} sm={6} className="flex-left pd-r-10">
            <FormControl
              fullWidth
              variant="outlined"
              error={submitPressed && region == ""}
            >
              <InputLabel htmlFor="select-region">
                Selecciona tu región*
              </InputLabel>
              <Select
                value={region}
                onChange={(e) => {
                  setCommune("");
                  setRegion(e.target.value);
                }}
                id='input-region'
                input={<OutlinedInput id="select-region" aria-hidden="false" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {RegionesYComunas.map((region, index) => (
                  <MenuItem key={index} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              {submitPressed && region == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="flex-left pd-l-10">
            <FormControl
              fullWidth
              variant="outlined"
              disabled={region == ""}
              error={submitPressed && commune == ""}
            >
              <InputLabel htmlFor="select-commune">
                Selecciona tu comuna*
              </InputLabel>
              <Select
                value={commune}
                onChange={(e) => setCommune(e.target.value)}
                id='input-commune'
                input={<OutlinedInput id="select-commune" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {selectedRegion.length == 0 ? (
                  <MenuItem value={"Santiago"}>{"Santiago"}</MenuItem>
                ) : (
                  selectedRegion[0].communes.map((commune, index) => (
                    <MenuItem key={index} value={commune}>
                      {commune}
                    </MenuItem>
                  ))
                )}
              </Select>
              {submitPressed && commune == "" && (
                <FormHelperText>Campo obligatorio</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && street == ""}
              variant="outlined"
              label="Calle*"
              name="calle"
              value={street}
              id='input-street'
              onChange={(e) => setStreet(e.target.value)}
              helperText={
                submitPressed && street == "" ? "Campo obligatorio" : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-r-10 pd-l-10 pd-r-10">
            <TextField
              fullWidth
              error={submitPressed && streetNumber == ""}
              variant="outlined"
              type="number"
              label="Número*"
              name="streetNumber"
              value={streetNumber}
              id='input-street-number'
              onChange={(e) => setStreetNumber(e.target.value)}
              helperText={
                submitPressed && streetNumber == "" ? "Campo obligatorio" : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={3} className="pd-l-10">
            <TextField
              fullWidth
              variant="outlined"
              label="Depto/Casa"
              name="streetOther"
              value={streetOther}
              id='input-street-other'
              onChange={(e) => setStreetOther(e.target.value)}
            />
          </Grid>

          <Divider />

          {
            loadingAddresses ? 
            <CircularProgress />
            : 
            <React.Fragment>
              {
                addresses.map((address, index) => (
                  <WithdrawalAddressForm
                    key={index}
                    address={address}
                    changeAttribute={changeAddressAttribute}
                    submitPressed={submitPressed}
                    formTitle={`Dirección de retiro ${index + 1}`}
                    addressIndex={index}
                    isMandatory={index === 0}
                  />

                ))
              }
            </React.Fragment>
          }

          <Divider />

          <Grid item xs={12} sm={6} className="flex-left">
            <FormControl
              fullWidth
              disabled={true}
              variant="outlined"
              error={submitPressed && ecommerce == ""}
            >
              <InputLabel htmlFor="select-ecommerce">
                Plataforma e-commerce
              </InputLabel>
              <Select
                value={ecommerce}
                id='input-ecommerce'
                input={<OutlinedInput id="select-ecommerce" />}
                IconComponent={() => (
                  <i className="material-icons-outlined">expand_more</i>
                )}
              >
                {ecommerces.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className="bottom-action-container">
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => handleCancel()}
              className="flex-left"
            >
              {loading ? <CircularProgress className="loading-btn" /> : "Cancelar"}
            </Button>
            <Button
              disabled={loading}
              id="user-save"
              type="submit"
              variant="contained"
              onClick={() => handleSubmit}
              className="flex-left"
            >
              {loading ? <CircularProgress className="loading-btn" /> : "Actualizar datos"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  curent_user: (state) => state.curent_user,
});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(Profile);
