import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { flash_alert } from "components/App";
import { headers } from "../../constants/csrf";
import { withdrawalStates } from "../../constants/data";
import { mapStatusWithdrawal, getWidrawalHour } from "../../utils/functions";

import { showTime24HourFormat } from '../../utils/timeFormat';

function WithdrawalRow(props) {
  const { current_user, withdrawal, columns, color, fetchWithdrawals } = props;

  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpdateState = async (state) => {
    setSelectedStatus("");
    setLoading(true);
    await axios
      .put(
        `/api/v1/withdrawals/${withdrawal["id"]}/update_state/${state}`,
        {},
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchWithdrawals();
        setLoading(false);
        flash_alert(
          "Éxito",
          "Se ha actualizado correctamente el estado del retiro",
          "success"
        );
      })
      .catch((e) => {
        setLoading(false);
        flash_alert(
          "Error",
          "Hubo un error al actualizar el estado del retiro",
          "danger"
        );
      });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: color }}
      >
        {columns.map((column, index) => {
          if (column.enabled) {
            if (column.columnName == "Acciones") {
              return (
                <TableCell key={`action-${index}`} className="actions-cell">
                  <div className="actions-container">
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className="dropdown-update-status"
                    >
                      <InputLabel id="dropdown-update-status-label">
                        Actualiza el estado
                      </InputLabel>
                      <Select
                        label="Actualiza el estado"
                        labelId="dropdown-update-status-label"
                        value={selectedStatus}
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                          handleUpdateState(e.target.value);
                        }}
                        IconComponent={() => (
                          <i className="material-icons-outlined">expand_more</i>
                        )}
                      >
                        {withdrawalStates.map((state, index) => (
                          <MenuItem
                            className="dropdown-update-status-item"
                            key={index}
                            value={state}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  component="th"
                  scope="row"
                  key={`${column.columnName}-${index}`}
                >
                  {
                    column.dbName.split("/").length === 3 ? 
                      withdrawal[column.dbName.split("/")[0]][column.dbName.split("/")[1]][column.dbName.split("/")[2]]
                      : 
                      column.dbName.split("/").length === 2 ? 
                        withdrawal[column.dbName.split("/")[0]][column.dbName.split("/")[1]]
                        : 
                        column.columnName === "Estado de retiro" ? 
                          mapStatusWithdrawal(withdrawal[column.dbName])
                          : 
                          column.columnName === "Hora de retiro" ?
                            showTime24HourFormat(withdrawal.selected_hour)
                            :
                            withdrawal[column.dbName]
                  }
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
    </React.Fragment>
  );
}

export default WithdrawalRow;
