export const csrf =
  document.querySelector("meta[name='csrf-token']") == undefined
    ? ""
    : document.querySelector("meta[name='csrf-token']").getAttribute("content");
export const headers = {
  "Content-Type": "application/json",
  "X-CSRF-Token": csrf,
};

export const headersFiles = {
  'Content-Type': 'multipart/form-data; boundary=--------------------------a string of numbers that is never the same',
	'Accept': 'application/json, text/plain, */*',
  "X-CSRF-Token": csrf,
};
