import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, FormControl, Grid, Paper } from "@mui/material";

import SelectRequestEcommerce from "./SelectRequestEcommerce";

import { updateRequestType } from "../../stores/functions";
import sendSVG from "../../../assets/images/envio.svg";
import siteToStoreSVG from "../../../assets/images/site-to-store.svg";
import meliflexSVG from "../../../assets/images/envio-meliflex.svg";
import returnSVG from "../../../assets/images/devolucion.svg";

import RequestTypeOption from 'components/requests/RequestTypeOption';

const SelectRequestType = (props) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [redirect, setRedirect] = useState(false);

  const requestTypeOptions = [
    {name: 'Envíos Sameday', kind: 'same_day', icon: sendSVG},
    {name: 'Devoluciones', kind: 'returns', icon: returnSVG},
    {name: 'Envíos MeLi Flex', kind: 'meli_flex', icon: meliflexSVG},
    {name: 'Site to Store', kind: 'site_to_store', icon: siteToStoreSVG},
    {name: 'Envío a región', kind: 'to_region', icon: sendSVG},
    {name: 'Pick-up', kind: 'pickup', icon: sendSVG},
    {name: 'Envío Next Day', kind: 'next_day', icon: sendSVG},
    {name: 'Cambios', kind: 'changes', icon: returnSVG},
  ];

  const handleSubmit = () => {
    dispatch(updateRequestType(selectedOption));
    setRedirect(true);
  };

  let redirect_check = [];
  if (redirect) {
    redirect_check.push(
      <Redirect key="redirect-to-select-ecommerce" to="/requests/new/file_kind">
        <SelectRequestEcommerce />
      </Redirect>
    );
  }
  return (
    <React.Fragment>
      {redirect_check}
      <Grid item xs={12}>
        <h1>Carga de solicitudes</h1>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Link to="/home" className="back-login-btn">
            <i className="material-icons-outlined forgotten-back-arrow">
              arrow_back
            </i>
            <p>Volver atrás</p>
          </Link>
        </FormControl>
      </Grid>
      <Grid container className="select-type-container">
        <Grid item xs={12} sm={12} className="flex-center">
          <h5>¿Qué necesitas cargar?</h5>
        </Grid>
        <Grid item xs={12} sm={12} className="flex-center">
          <p className="subtitle">
            Selecciona el servicio que necesitas solicitar
          </p>
        </Grid>

        {
          requestTypeOptions.map((option) => (
            <Grid item xs={6} sm={3} className="option first-row">
              <RequestTypeOption
                requestKind={ option }
                updateSelection={ setSelectedOption }
                isSelected={ selectedOption.kind === option.kind }
              />
            </Grid>
          ))
        }

        <Grid item xs={12} sm={12} className="flex-center">
          <Button
            disabled={selectedOption === ""}
            className={selectedOption === "" ? "button-disabled" : ""}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            <p>Cargar archivo</p>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(SelectRequestType)
);
