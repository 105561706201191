import React, { useState, useEffect } from "react";
import { Provider, connect } from "react-redux";
import configureStore from "../../stores/configureStore";
import axios from "axios";
import { flash_alert } from "components/App";

import {
  Grid,
  FormControl,
  Button,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

import * as EmailValidator from "email-validator";
import logo from "../../../assets/images/logo-goex.svg";
import { headers } from "../../constants/csrf";

function NewPassword(props) {
  const store_r = configureStore(props.current_user);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [equalPassword, setEqualPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);

  async function handleSubmit() {
    setSubmitPressed(true);
    if (equalPassword) {
      setLoading(true);
      var body = new FormData();
      body.set("email", email);
      body.set("reset_password_token", token);
      body.set("password", password);
      body.set("password_confirmation", passwordConfirmation);
      return axios
        .post("/api/v1/users/change_password", body, { headers: headers })
        .then((response) => {
          setLoading(false);

          flash_alert(
            "Actualizado!",
            "Se ha actualizado correctamente la contraseña, serás redirigido al login",
            "success"
          );
          setTimeout(() => {
            window.location = "/";
          }, 3000);
        })
        .catch((e) => {
          setLoading(false);
          flash_alert(
            "Error",
            "Hubo un error al actualizar la contraseña",
            "danger"
          );
        });
    }
  }

  useEffect(() => {
    let params = window.location.href.split("?auth=");
    if (params.length === 2) {
      let params_array = params[1].split("?email=");
      setEmail(params_array[1]);
      setToken(params_array[0]);
    }
  }, []);

  return (
    <Provider store={store_r}>
      <React.Fragment>
        <Grid container item xs={12} className="succes-logo-container">
          <div className="success-logo">
            <img src={logo} alt="Logo Go-EX" />
          </div>
        </Grid>
        <div className="flex-center">
          <Grid container className="password-container">
            <Grid item xs={12} sm={12}>
              <h4>Nueva contraseña</h4>
            </Grid>
            <Grid item xs={12} sm={12} className="flex-center mg-t-20">
              <FormControl
                fullWidth
                variant="outlined"
                error={submitPressed && (!equalPassword || password == "")}
              >
                <InputLabel htmlFor="outlined-password">
                  Nueva contraseña
                </InputLabel>
                <OutlinedInput
                  id="outlined-password"
                  type={"password"}
                  value={password}
                  onChange={(e) => {
                    setEqualPassword(e.target.value == passwordConfirmation);
                    setPassword(e.target.value);
                  }}
                  name="password"
                />
                {submitPressed && password == "" && (
                  <FormHelperText>Campo obligatorio</FormHelperText>
                )}
                {submitPressed && !equalPassword && (
                  <FormHelperText>
                    Las contraseñas deben ser iguales
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} className="flex-center mg-t-20">
              <FormControl
                fullWidth
                variant="outlined"
                error={submitPressed && passwordConfirmation == ""}
              >
                <InputLabel htmlFor="password-confirmation">
                  Repite nueva contraseña
                </InputLabel>
                <OutlinedInput
                  id="password-confirmation"
                  type={"password"}
                  value={passwordConfirmation}
                  onChange={(e) => {
                    setEqualPassword(password == e.target.value);
                    setPasswordConfirmation(e.target.value);
                  }}
                  name="passwordConfirmation"
                />
                {submitPressed && passwordConfirmation == "" && (
                  <FormHelperText>Campo obligatorio</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} className="mg-t-20">
              <Button
                variant="contained"
                onClick={() => handleSubmit()}
                className={"forgotten-submit-btn"}
              >
                {loading ? (
                  <CircularProgress className="loading-btn" />
                ) : (
                  "Guardar"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    </Provider>
  );
}

export default NewPassword;
