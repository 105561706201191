import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../routes/Index";
import { Provider } from "react-redux";
import configureStore from "../stores/configureStore";
//./bin/webpack-dev-server
// FRONT END
import CustomLayout from "./layouts/CustomLayout";

// Notifications
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";

import 'animate.css';

function App(props) {
  const store_r = configureStore(props.current_user);

  return (
    <Provider store={store_r}>
      <ReactNotification />
      <Router>
        <CustomLayout current_user={props.current_user}>
          <Routes current_user={props.current_user} />
        </CustomLayout>
      </Router>
    </Provider>
  );
}

export default App;
export const flash_alert = function (title, message, type) {
  let alert_type = type;
  switch (type) {
    case "alert":
      alert_type = "danger";
      break;
    case "error":
      alert_type = "danger";
      break;
    case "danger":
      alert_type = "danger";
      break;
    case "notice":
      alert_type = "info";
      break;
    case "success":
      alert_type = "success";
      break;
    default:
      alert_type = "default";
      break;
  }
  store.addNotification({
    title: title,
    message: message,
    type: alert_type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeInRight"],
    animationOut: ["animate__animated", "animate__fadeOutRight"],
    dismiss: {
      duration: 5000,
      showIcon: true,
      pauseOnHover: true,
    },
  });
};
