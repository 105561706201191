import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { Button, CircularProgress, Chip, Grid, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { es } from "date-fns/locale";

import { headers } from "../../constants/csrf";
import { strDateFormat } from "../../utils/functions";
import CustomTable from "../table/CustomTable";
import { saveAs } from "file-saver";
import { updatePendingIncidences } from "../../stores/functions";

function IncidenceManagement(props) {
  const dispatch = useDispatch();
  const [incidences, setIncidences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date().setHours(0, 0, 0, 0));
  const [routedCount, setRoutedCount] = useState(0);
  const [forRoutingCount, setForRoutingCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);

  const fetchIncidences = async () => {
    setLoading(true);
    await axios
      .get("/api/v1/incidences", {}, { headers: headers })
      .then((response) => {
        setLoading(false);
        setIncidences(response.data);
        dispatch(
          updatePendingIncidences(
            response.data.filter(function (incidence) {
              return (
                incidence.aasm_state === "failed" ||
                incidence.aasm_state === "pending"
              );
            })
          )
        );
      })
      .catch((e) => {
        setLoading(false);
        console.log("error: ", e);
      });
  };

  useEffect(() => {
    fetchIncidences();
  }, []);

  let headerTable = [
    { columnName: "Nº Orden", dbName: "order_number", enabled: true },
    { columnName: "Destinatario", dbName: "recipient", enabled: true },
    { columnName: "Dirección", dbName: "full_address", enabled: true },
    { columnName: "Estado", dbName: "aasm_state", enabled: true },
    { columnName: "Fecha", dbName: "planned_date", enabled: true },
    { columnName: "Acciones", dbName: null, enabled: true },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h1>Gestión de incidencias</h1>
      </Grid>
      <Grid item xs={12}>
        <h6 className="subtitle">Incidencias por gestionar</h6>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={incidences.filter(function (incidence) {
              return ["failed", "pending"].includes(incidence.aasm_state);
            })}
            headerTable={headerTable}
            fetchIncidences={fetchIncidences}
            selected={"none"}
            setSelected={"none"}
            openCollapseButton={true}
            editable={true}
            openCollapseTooltip={"Ver detalle"}
            openCollapseIcon={
              <i className="material-icons-outlined">add_circle_outline</i>
            }
            completeButton={true}
            completeTooltip={"Incidencia gestionada"}
            completeIcon={
              <i className="material-icons-outlined">check_circle</i>
            }
            archiveButton={false}
            deleteButton={false}
            collapseType={"incidence-detail"}
            completeColor={"success"}
            emptyDataText={"¡Felicidades! Has gestionado todo por hoy 👏"}
            hasPagination={false}
            rowsPerPage={100}
            rowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
            loading={loading}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h6 className="subtitle">Incidencias gestionadas</h6>
      </Grid>
      <Grid container className="custom-table-container">
        <Grid item xs={12} className="flex-center">
          <CustomTable
            data={incidences.filter(function (incidence) {
              return incidence.aasm_state === "completed" && incidence.request.aasm_state != "finished";
            })}
            headerTable={headerTable}
            fetchIncidences={fetchIncidences}
            selected={"none"}
            setSelected={"none"}
            openCollapseButton={true}
            editable={false}
            openCollapseTooltip={"Ver detalle"}
            openCollapseIcon={
              <i className="material-icons-outlined">add_circle_outline</i>
            }
            completeButton={false}
            deleteButton={false}
            // deleteTooltip={"Borrar incidencia"}
            // deleteIcon={<i className="material-icons-outlined">cancel</i>}
            collapseType={"incidence-detail"}
            completeColor={"success"}
            emptyDataText={"¡Felicidades! Has gestionado todo por hoy 👏"}
            rowsPerPage={100}
            hasPagination={false}
            loading={loading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({
  current_user: (state) => state.current_user,
});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(IncidenceManagement)
);
